import React from 'react'
import { useParams } from 'react-router-dom'
import { Card, PageHeader, Empty, Spin } from 'antd'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'

import tableStore from '../../lib/store/user_setting_table'
import UserSettingService from '../../network/services/user_setting'
import { serialize } from '../../network/request'
import UserSettingTable from './UserSettingTable'

const UserSettingPage = () => {
  return (
    <PageHeader title="All User Settings">
      <Card>
        <UserActivityTableWrapper />
      </Card>
    </PageHeader>
  )
}

const UserActivityTableWrapper = () => {
  const { state } = useSnapshot(tableStore)
  const { user_id } = useParams()

  // use this to preload
  const { data: response, error } = useSWR(
    serialize(UserSettingService.getByUserId(user_id!), {
      page: 1,
      limit: state.pageSize,
      ...state.filters
    })
  )

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return <Spin />
  }

  const { total } = UserSettingService.toPaginate(response)

  return (
    <>
      <UserSettingTable total={total} />
      {/* preload next page */}
      {state.currentPage * state.pageSize < total && (
        <div style={{ display: 'none' }}>
          <UserSettingTable total={total} overridePage={state.currentPage + 1} />
        </div>
      )}
    </>
  )
}

export default UserSettingPage
