import { DateTime } from 'luxon'
import client, { IPaginatedResponse } from '../request'
import { IPool } from './pool'

export interface IProfit {
  id: string | number
  profit_rate: number
  profit_value: number
  exchange_rate: number
  multiplier: number
  total_balance: number
  total_subscribe: number
  total_subscribe_12: number
  total_subscribe_24: number
  total_nft_subscribe: number
  total_nft_subscribe_12: number
  total_nft_subscribe_24: number
  total_incentive_subscribe: number
  total_incentive_subscribe_12: number
  total_incentive_subscribe_24: number
  profit_12: number
  profit_24: number
  total_profit: number
  nft_12: number
  nft_24: number
  total_nft: number
  vxt_12: number
  vxt_24: number
  total_incentive: number
  published: boolean
  distributed: boolean
  simulated: boolean
  published_at: DateTime
  distributed_at: DateTime
  token_type: string
  report_url: string
  created_at: DateTime
  updated_at: DateTime
  simulated_at: DateTime
  pool?: IPool
}

export interface IProfitOptional extends Partial<IProfit> {}

const getAll = `/profits`

const get = (id: string | number) => {
  return `/profits/${id}`
}

const create = (data: IProfit) => {
  return client.post('/profits', data)
}

const update = (id: string | number, data: IProfitOptional) => {
  return client.put(`/profits/${id}`, data)
}

const remove = (id: string | number) => {
  return client.delete(`/profits/${id}`)
}

const toRow = (response: IPaginatedResponse<IProfit[]>) => {
  if (response?.data?.length > 0) {
    return response.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (response: IPaginatedResponse<IProfit[]>) => {
  return {
    total: response?.meta?.total ?? 0
  }
}

const getEstimatedRate = (data: any) => {
  return client.post(`/profits/rate`, data)
}

const getVxtPoolProfitValue = (data: any) => {
  return client.post(`/profits/vxt-profit-value`, data)
}

const distributeInterests = (data: any) => {
  return client.post(`/distribute-interests`, data)
}

const simulateInterests = (data: any) => {
  return client.post(`/simulate-interests`, data)
}

const ProfitService = {
  getAll,
  get,
  create,
  update,
  remove,
  toRow,
  toPaginate,
  getEstimatedRate,
  distributeInterests,
  simulateInterests,
  getVxtPoolProfitValue
}

export default ProfitService
