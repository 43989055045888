import React, { useState, useEffect } from 'react'
import { Space, Form, Card, Typography, Input, Button, Row, Col, message } from 'antd'
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
import { useSnapshot } from 'valtio'
import { Navigate, useLocation, Link } from 'react-router-dom'

import authStore, { login } from '../../lib/store/auth'
import AuthService, { ILogin } from '../../network/services/auth'

const SignIn = () => {
  let location = useLocation()
  const { token: isLoggedIn } = useSnapshot(authStore)

  const [form] = Form.useForm<ILogin>()
  const [redirectToReferrer, setRedirectToReferrer] = useState(false)
  const [formError, setFormError] = useState(null)

  let state: any = location.state
  let from = state?.from?.pathname || '/dashboard'

  useEffect(() => {
    if (isLoggedIn) {
      setRedirectToReferrer(true)
    }
  }, [isLoggedIn])

  const handleLogin = async (values: ILogin) => {
    try {
      const { data: result } = await AuthService.login(values)

      if (result.success) {
        const profile = result.data.user
        const jwt = result.data.jwt
        console.log(result)
        login({ profile, token: jwt.token })
      }
    } catch (e) {
      console.error(e)
      if (typeof e === 'object') {
        const errorMessage = JSON.parse(JSON.stringify(e))
        message.error(errorMessage?.message)
      }
    }
  }

  if (redirectToReferrer) {
    return <Navigate to={from} />
  }

  return (
    <Row justify="center" align="middle" style={{ height: '100vh', width: '100%' }}>
      <Col>
        <Space direction="vertical">
          <Link to="/dashboard">
            <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <Typography.Title>PBC NFT</Typography.Title>
            </div>
          </Link>

          <Card style={{ minWidth: '400px' }}>
            <Form onFinish={handleLogin} autoComplete="off" form={form}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please insert your email'
                  }
                ]}
              >
                <Input size="large" placeholder="Email" autoComplete="off" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please insert your password'
                  }
                ]}
                style={{ marginBottom: 4 }}
              >
                <Input.Password
                  size="large"
                  type="password"
                  placeholder="Password"
                  autoComplete="off"
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>

              <p style={{ color: 'red', textTransform: 'capitalize' }}>{formError}</p>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Sign In
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Space>
      </Col>
    </Row>
  )
}

export default SignIn
