import { Key, FC, useEffect, useState } from 'react'
import { Table, Button, Empty, Row, Col, Dropdown, Menu } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../../lib/store/stake_transaction_table'
import TableFilter from '../../../components/TableFilter'
import { serialize } from '../../../network/request'
import StakeTransactionService, { ITransaction } from '../../../network/services/stake-transaction'
// import CSVButton from '../../components/CSVButton'
import { DateTime } from 'luxon'

const StakeTransactionTable = () => {
  const navigate = useNavigate()
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState<Record<string, any>>()
  const { stake_id } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const { data: response } = useSWR(
    serialize(StakeTransactionService.getAll, {
      page: state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters,
      user_id: searchParams.get('user_id'),
      stake_id: stake_id
    })
  )

  useEffect(() => {
    // console.log(Array.from(searchParams).length)
    // console.log(searchParams.get('user_id'))
    // console.log(searchParams.get('wallet_id'))
    // use effect to handle search params
    if (Array.from(searchParams).length > 0) {
      setCurrentFilters({
        ...currentFilters,
        user_id: searchParams.get('user_id'),
        stake_id: stake_id
      })
    } else {
      setCurrentFilters({
        ...currentFilters,
        user_id: null,
        stake_id: null
      })
    }
  }, [searchParams])

  const rows = StakeTransactionService.toRow(response)

  const columns = [
    {
      title: 'User',
      dataIndex: 'user_id',
      key: 'user_id',
      sorter: true,
      render: (value: any, row: ITransaction) => {
        return `${row?.user?.email}`
      }
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: true
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: true
    },
    {
      title: 'Txn Type',
      dataIndex: 'txn_type',
      key: 'txn_type',
      sorter: true,
      render: (text: any, record: ITransaction) => {
        return `${_.startCase(text)}`
      }
    },
    // {
    //   title: 'Token Symbol',
    //   dataIndex: 'token_type',
    //   key: 'token_type',
    //   sorter: true
    // },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      sorter: true,
      render: (text: any, record: ITransaction) => {
        return `${text} ${record?.token_type}`
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true
    },
    // {
    //   title: 'Network Type',
    //   dataIndex: 'network_type',
    //   key: 'network_type',
    //   sorter: true
    // },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (text: string) => {
        return text ? DateTime.fromISO(text).toFormat('dd/MM/yyyy HH:mm') : ''
      }
    }
  ]

  const filterColumns = Array.from(searchParams).length > 0 ? [] : [{ key: 'email', name: 'Email' }]

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = {
        ...currentFilters
      }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  // const batchDeleteRows = async () => {
  //   try {
  //     for (const id of selectedRowKeys) {
  //       const { data } = await UserService.remove(id)
  //       if (data?.success === true) {
  //         message.success(`Delete user success`)
  //       } else {
  //         message.error(`Delete user ${id} failed`)
  //       }
  //       tableStore.state.refresh = Math.random()
  //     }

  //     setVisible(false)
  //   } catch (e) {
  //     console.log(e)
  //     // message.error({ content: e.message, className: 'message-error' })
  //   }
  // }

  // const DeleteModal = () => {
  //   return (
  //     <Modal
  //       title={`Remove ${selectedRowKeys.length} users`}
  //       visible={visible}
  //       onOk={() => {
  //         batchDeleteRows()
  //       }}
  //       onCancel={() => {
  //         setVisible(false)
  //       }}
  //       okText="Delete"
  //       cancelText="Cancel"
  //     >
  //       This can't be undone
  //     </Modal>
  //   )
  // }

  const filterType = {
    label: 'Type',
    key: 'txn_type',
    value: [
      { key: '', name: 'All Type' },
      { key: 'deposit', name: 'Deposit' },
      { key: 'stake', name: 'Staking' },
      { key: 'withdraw', name: 'Withdraw' },
      { key: 'interest', name: 'Profit Share' },
      { key: 'terminate', name: 'Terminate' },
      { key: 'nft_bid', name: 'NFT Bid' },
      { key: 'nft_refund', name: 'NFT Refund' },
      { key: 'nft_withdraw', name: 'NFT Withdraw' }
    ],
    default: ''
  }

  const { total } = StakeTransactionService.toPaginate(response)

  return (
    <>
      {/* <DeleteModal /> */}

      <Row>
        <Col span={24}>
          <Row justify="end">
            <TableFilter
              dropdowns={[filterType]}
              columns={filterColumns}
              hasDate={true}
              initial={state.filters}
              setCurrentFilters={setCurrentFilters}
            />

            {/* <CSVButton
              path={serialize(TransactionService.getAll, {
                page: 1,
                limit: total,
                sort: state.sort ?? 'created_at:desc',
                ...state.filters
              })}
              filename="assets.csv"
              toRow={TransactionService.toRow}
              toCsv={(rows) => {
                return rows.map((row) => {
                  return {
                    name: row.profile?.name,
                    email: row.email,
                    plan: row.subscription?.plan?.name,
                    children: row.children[0]?.name,
                    birthdate:
                      row.children[0]?.birthdate &&
                      DateTime.fromISO(row.children[0].birthdate).toFormat('dd/MM/yyyy'),
                    registered:
                      row.created_at && DateTime.fromISO(row.created_at).toFormat('dd/MM/yyyy')
                  }
                })
              }}
            /> */}
          </Row>
        </Col>
      </Row>

      <Table<ITransaction>
        scroll={{ x: true }}
        size="small"
        columns={columns}
        dataSource={rows}
        expandable={{
          rowExpandable: (record) => false
        }}
        onChange={(pagination, filters, sorter) => {
          console.log(pagination, filters, sorter)

          if (_.isArray(sorter)) {
            // TODO
          } else {
            tableStore.state.sort =
              sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null
          }

          tableStore.state.currentPage = pagination.current!
          tableStore.state.pageSize = pagination.pageSize!
        }}
        pagination={{
          total: total,
          pageSize: tableStore.state.pageSize,
          current: tableStore.state.currentPage
        }}
      />
    </>
  )
}

export default StakeTransactionTable
