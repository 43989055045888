import { Key, FC, useEffect, useState } from 'react'
import { Table, Empty, Row, Col, Modal, message } from 'antd'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../lib/store/interest_distribution_table'
import TableFilter from '../../components/TableFilter'
import { serialize } from '../../network/request'
import InterestService, { IInterest } from '../../network/services/interest'
import PoolService from '../../network/services/pool'
// import CSVButton from '../../components/CSVButton'
import { DateTime } from 'luxon'
import { MenuInfo } from 'rc-menu/lib/interface'
import { useParams } from 'react-router-dom'
import { IStake } from 'network/services/stake'

const StakeInterestTable: FC<{ total: number; overridePage?: number }> = ({
  total,
  overridePage
}) => {
  const { stake_id } = useParams()
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState<Record<string, any>>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [visible, setVisible] = useState(false)
  const { ColumnGroup, Column } = Table

  const {
    data: response,
    error,
    mutate
  } = useSWR(
    serialize(InterestService.getByStakeId(stake_id!), {
      page: overridePage ?? state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters
    })
  )
  const rows = InterestService.toRow(response)

  const rowSelection = {
    selectedRowKeys,
    onChange: (keySelected: Key[]) => {
      console.log(keySelected)
      setSelectedRowKeys(keySelected)
    }
  }

  const filterColumns = [{ key: 'email', name: 'Email' }]
  // filterColumns.forEach((item) => {
  //   if (tableStore.state?.filters && Object.keys(tableStore.state.filters).includes(item.key)) {
  //     item.default_value = tableStore.state.filters[item.key]
  //     item.default = true
  //   }
  // })

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const handleMenuClick = (e: MenuInfo) => {
    if (e.key === 'delete') {
      setVisible(true)
    }
  }

  return (
    <>
      {rows.length > 0 && (
        <Row>
          <Col span={6}>
            <Row>
              <div>{`Token Name: ${rows[0]?.stake?.token_name}`}</div>
            </Row>
            <Row>
              <div>{`Token Symbol: ${rows[0]?.stake?.token_type}`}</div>
            </Row>
            <Row>
              <div>{`Pool Type: ${PoolService.getStakeType(rows[0]?.stake?.type ?? '-')}`}</div>
            </Row>
            <Row>
              <div>{`Tenure: ${rows[0]?.stake?.tenure}`}</div>
            </Row>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={6} />
        <Col span={18}>
          <Row justify="end">
            <TableFilter
              dropdowns={[]}
              columns={[]}
              hasDate={true}
              initial={state.filters}
              setCurrentFilters={setCurrentFilters}
            />

            {/* <CSVButton
              path={serialize(InterestService.getAll, {
                page: 1,
                limit: total,
                sort: state.sort ?? 'created_at:desc',
                ...state.filters
              })}
              filename="assets.csv"
              toRow={InterestService.toRow}
              toCsv={(rows) => {
                return rows.map((row) => {
                  return {
                    name: row.profile?.name,
                    email: row.email,
                    plan: row.subscription?.plan?.name,
                    children: row.children[0]?.name,
                    birthdate:
                      row.children[0]?.birthdate &&
                      DateTime.fromISO(row.children[0].birthdate).toFormat('dd/MM/yyyy'),
                    registered:
                      row.created_at && DateTime.fromISO(row.created_at).toFormat('dd/MM/yyyy')
                  }
                })
              }}
            /> */}
          </Row>
        </Col>
      </Row>

      {/* {rows?.length > 0 ? ( */}
      <Table<IInterest>
        scroll={{ x: true }}
        size="small"
        dataSource={rows}
        bordered={true}
        expandable={{
          rowExpandable: (record) => false
        }}
        onChange={(pagination, filters, sorter) => {
          console.log(pagination, filters, sorter)

          if (_.isArray(sorter)) {
            // TODO
          } else {
            tableStore.state.sort =
              sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null
          }

          tableStore.state.currentPage = pagination.current!
          tableStore.state.pageSize = pagination.pageSize!
        }}
        pagination={{
          total,
          pageSize: state.pageSize,
          current: state.currentPage
        }}
      >
        <Column
          title="Type"
          dataIndex="type"
          key="type"
          render={(value: number, record: IInterest) => {
            switch (value) {
              case 0:
                return `Credit to OTC wallet : ${record?.transaction?.txn_ref_no ?? '-'}`
              case 1:
                return 'Resubscribe'
              default:
                return '-'
            }
          }}
        />
        <ColumnGroup title="User">
          <Column title="Full Name" dataIndex={['user', 'full_name']} key="full_name" />
          <Column title="Email" dataIndex={['user', 'email']} key="email" />
        </ColumnGroup>
        {/* <Column title="Pool" dataIndex="stake_rate" key="stake_rate" /> */}
        <Column title="Total Earn" dataIndex="amount" key="amount" />
        <Column title="Token Type" dataIndex="token_type" key="token_type" />
        <Column
          title="Distributed At"
          dataIndex="distributed_at"
          key="distributed_at"
          render={(value: string) => {
            return value ? DateTime.fromISO(value).toFormat('dd/MM/yyyy ttt') : ''
          }}
        />
      </Table>
      {/* ) : (
         <Empty description={'No record found'} />
       )} */}
    </>
  )
}

export default StakeInterestTable
