import { Key, FC, useEffect, useState } from 'react'
import { Table, Button, Empty, Row, Col, Dropdown, Menu, Modal, message, Tag } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../lib/store/pool_table'
import TableFilter from '../../components/TableFilter'
import { serialize } from '../../network/request'
import PoolService, { IPool } from '../../network/services/pool'
// import CSVButton from '../../components/CSVButton'
import { DateTime } from 'luxon'
import { MenuInfo } from 'rc-menu/lib/interface'

const PoolTable = () => {
  const navigate = useNavigate()
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState<Record<string, any>>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [checklist, setChecklist] = useState<Record<string, any>[]>([])
  const [visible, setVisible] = useState(false)

  const {
    data: response,
    error,
    mutate
  } = useSWR(
    serialize(PoolService.getAll, {
      page: state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters
    })
  )
  const rows = PoolService.toRow(response)

  const columns = [
    {
      title: 'Token Name',
      dataIndex: 'token_name',
      key: 'token_name',
      sorter: true
    },
    {
      title: 'Symbol',
      dataIndex: 'token_type',
      key: 'token_type',
      sorter: true
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      render: (text: string) => {
        return PoolService.getStakeType(text)
      }
    },
    {
      title: 'Current Pool Size',
      dataIndex: 'pool_size',
      key: 'pool_size',
      sorter: true,
      render: (value: string, record: IPool) => {
        return `${value} ${record?.token_type}`
      }
    },
    {
      title: 'Max Pool Size',
      dataIndex: 'max_pool_size',
      key: 'max_pool_size',
      sorter: true,
      render: (value: string, record: IPool) => {
        return `${record.type === 'launch' ? `${value} ${record?.token_type}` : '-'}`
      }
    },
    {
      title: 'Hidden',
      dataIndex: 'blocked',
      key: 'blocked',
      sorter: true,
      render: (value: boolean) => {
        return <Tag color={value ? 'success' : 'error'}>{value ? 'Yes' : 'No'}</Tag>
      }
    },
    // {
    //   title: 'Subscription Fee',
    //   dataIndex: 'subscription_fee',
    //   key: 'subscription_fee',
    //   sorter: true,
    //   render: (value: boolean, record: IPool) => {
    //     return `${value} ${record.subscription_fee_type === 1 ? '%' : record?.token_type}`
    //   }
    // },
    // {
    //   title: 'Termination Fee',
    //   dataIndex: 'termination_fee',
    //   key: 'termination_fee',
    //   sorter: true,
    //   render: (value: boolean, record: IPool) => {
    //     return `${value} ${record.termination_fee_type === 1 ? '%' : record?.token_type}`
    //   }
    // }
    // {
    //   title: 'Created At',
    //   dataIndex: 'created_at',
    //   key: 'created_at',
    //   sorter: true,
    //   render: (text: string) => {
    //     return text ? DateTime.fromISO(text).toFormat('dd/MM/yyyy') : ''
    //   }
    // },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right' as 'right',
      render: (value: undefined, record: IPool) => {
        // const action = record.blocked ? 'Unblock' : 'Block'
        return (
          <>
            <Link to={`${record.id}`}>View</Link>
          </>
          // <Button
          //   type="link"
          //   onClick={async (e) => {
          //     e.stopPropagation()
          //     try {
          //       await PoolService.update(record.id!, {
          //         blocked: !record.blocked
          //       })

          //       message.success(
          //         `${action} ${record.token_name} | ${record.token_type} | ${record.type} successfully`
          //       )
          //       mutate()
          //     } catch (error) {
          //       console.log(error)
          //       message.error(`Failed to ${action}`)
          //     }
          //   }}
          // >
          //   {action}
          // </Button>
        )
      }
    }
  ]

  const filterColumns = [{ key: 'token_type', name: 'Symbol' }]

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  return (
    <>
      <Row>
        <Col span={24}>
          <Row justify="end">
            <TableFilter
              // dropdowns={[]}
              columns={filterColumns}
              hasDate={false}
              initial={state.filters}
              setCurrentFilters={setCurrentFilters}
            />

            {/* <CSVButton
              path={serialize(PoolService.getAll, {
                page: 1,
                limit: total,
                sort: state.sort ?? 'created_at:desc',
                ...state.filters
              })}
              filename="users.csv"
              toRow={PoolService.toRow}
              toCsv={(rows) => {
                return rows.map((row) => {
                  return {
                    name: row.profile?.name,
                    email: row.email,
                    plan: row.subscription?.plan?.name,
                    children: row.children[0]?.name,
                    birthdate:
                      row.children[0]?.birthdate &&
                      DateTime.fromISO(row.children[0].birthdate).toFormat('dd/MM/yyyy'),
                    registered:
                      row.created_at && DateTime.fromISO(row.created_at).toFormat('dd/MM/yyyy')
                  }
                })
              }}
            /> */}
          </Row>
        </Col>
      </Row>

      <Table<IPool>
        scroll={{ x: true }}
        size="small"
        columns={columns}
        dataSource={rows}
        expandable={{
          rowExpandable: (record) => false
        }}
        onChange={(pagination, filters, sorter) => {
          console.log(pagination, filters, sorter)

          if (_.isArray(sorter)) {
            // TODO
          } else {
            tableStore.state.sort =
              sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null
          }

          tableStore.state.currentPage = pagination.current!
          tableStore.state.pageSize = pagination.pageSize!
        }}
      />
    </>
  )
}

export default PoolTable
