import { Key, FC, useEffect, useState } from 'react'
import { Table, Button, Empty, Row, Col, Dropdown, Menu, Tag } from 'antd'
import type { TableColumnsType } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { Link, useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../lib/store/ticket_table'
import TableFilter from '../../components/TableFilter'
import { serialize } from '../../network/request'
import TicketService, { ITicket } from '../../network/services/ticket'
// import CSVButton from '../../components/CSVButton'
import { DateTime } from 'luxon'
import { MenuInfo } from 'rc-menu/lib/interface'
import type { ColumnsType } from 'antd/es/table'

const TicketTable = () => {
  const navigate = useNavigate()
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState<Record<string, any>>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [visible, setVisible] = useState(false)

  const { data: response } = useSWR(
    serialize(TicketService.getAll, {
      page: state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters
    })
  )
  const rows = TicketService.toRow(response)

  const columns: ColumnsType<ITicket> = [
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      sorter: true
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      sorter: true
    },
    {
      title: 'Sender',
      dataIndex: 'user_id',
      key: 'user_id',
      sorter: true,
      render: (value: any, row: ITicket) => {
        return `${row?.user?.email}`
      }
    },
    {
      title: 'Submitted Date',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (text: string) => {
        return text ? DateTime.fromISO(text).toFormat('dd/MM/yyyy') : ''
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (value: undefined, record: ITicket) => {
        return (
          <Link
            to={`/dashboard/tickets/${record.id}`}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            View
          </Link>
        )
      }
    }
  ]

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const handleMenuClick = (e: MenuInfo) => {
    if (e.key === 'delete') {
      setVisible(true)
    }
  }

  return (
    <>
      {/* <DeleteModal /> */}

      <Row>
        <Col span={6}>
          {selectedRowKeys?.length > 0 ? (
            <Dropdown
              overlay={
                <Menu onClick={handleMenuClick}>
                  <Menu.Item key="delete" disabled={true}>
                    Delete
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Button type="primary">
                More Actions <CaretDownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <></>
          )}
        </Col>
        <Col span={18}>
          <Row justify="end">
            {/* <TableFilter
              // dropdowns={[]}
              columns={filterColumns}
              hasDate={true}
              initial={state.filters}
              setCurrentFilters={setCurrentFilters}
            /> */}

            {/* <CSVButton
              path={serialize(TicketService.getAll, {
                page: 1,
                limit: total,
                sort: state.sort ?? 'created_at:desc',
                ...state.filters
              })}
              filename="assets.csv"
              toRow={TicketService.toRow}
              toCsv={(rows) => {
                return rows.map((row) => {
                  return {
                    name: row.profile?.name,
                    email: row.email,
                    plan: row.subscription?.plan?.name,
                    children: row.children[0]?.name,
                    birthdate:
                      row.children[0]?.birthdate &&
                      DateTime.fromISO(row.children[0].birthdate).toFormat('dd/MM/yyyy'),
                    registered:
                      row.created_at && DateTime.fromISO(row.created_at).toFormat('dd/MM/yyyy')
                  }
                })
              }}
            /> */}
          </Row>
        </Col>
      </Row>

      <Table<ITicket>
        scroll={{ x: true }}
        size="small"
        columns={columns}
        dataSource={rows}
        onChange={(pagination, filters, sorter) => {
          console.log(pagination, filters, sorter)
          if (_.isArray(sorter)) {
            // TODO
          } else {
            tableStore.state.sort =
              sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null
          }

          tableStore.state.currentPage = pagination.current!
          tableStore.state.pageSize = pagination.pageSize!
        }}
      />
    </>
  )
}

export default TicketTable
