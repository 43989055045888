import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Card,
  Col,
  Form,
  Button,
  Input,
  Row,
  message,
  FormInstance,
  Select,
  InputNumber
} from 'antd'

import Gaps from '../../components/Gaps'
import AssetService, { IAsset } from '../../network/services/asset'
import useSWR, { mutate } from 'swr'
import NetworkService from 'network/services/network'
import CurrencyService from 'network/services/currency'
import { DefaultOptionType } from 'antd/lib/select'

const AssetForm: FC<{
  form: FormInstance<IAsset>
  initialValue?: IAsset
  isLoading: boolean
  setIsLoading: (val: boolean) => void
  setIsFieldsChange: (val: boolean) => void
  refresh: () => void
}> = ({ form, initialValue, isLoading = false, setIsLoading, setIsFieldsChange, refresh }) => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [networkList, setNetworkList] = useState<DefaultOptionType[]>([])
  const [symbolList, setSymbolList] = useState<DefaultOptionType[]>([])
  const { data: networkData } = useSWR(NetworkService.getAll)
  const { data: symbolData } = useSWR(CurrencyService.getAll)

  const handleSubmit = async (values: IAsset) => {
    if (isLoading) return
    setIsLoading(true)

    let result
    if (initialValue?.id) {
      try {
        result = await AssetService.update(initialValue.id!, values)
        message.success('Update successful')
      } catch (error) {
        console.log(error)
        if (typeof error === 'object') {
          const errorMessage = JSON.parse(JSON.stringify(error))
          message.error(errorMessage?.message)
        }
        setIsLoading(false)
      }
    } else {
      message.error('create user is not allowed')
      // try {
      //   result = await AssetService.create(values)
      //   message.success('Create successful')
      // } catch (error) {
      //   console.log(error)
      //   if (typeof error === 'object') {
      //     const errorMessage = JSON.parse(JSON.stringify(error))
      //     message.error(errorMessage?.message)
      //   }
      //   setIsLoading(false)
      // }
    }

    if (result?.data?.success) {
      setIsLoading(false)
      setIsFieldsChange(false)
      refresh()
    }
  }

  const generateNetworkList = () => {
    const tmpList: DefaultOptionType[] = []

    if (networkData) {
      for (let i = 0; i < networkData.data.length; i++) {
        tmpList.push({
          label: networkData?.data[i]?.name,
          value: networkData?.data[i]?.id
        })
      }
      setNetworkList(tmpList)
    }
  }

  const generateSymbolList = () => {
    const tmpList: DefaultOptionType[] = []

    if (symbolData) {
      for (let i = 0; i < symbolData.data.length; i++) {
        tmpList.push({
          label: symbolData?.data[i]?.name + ' - ' + symbolData?.data[i]?.code,
          value: symbolData?.data[i]?.id
        })
      }
      setSymbolList(tmpList)
    }
  }

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (networkData !== null && symbolData !== null) {
      generateNetworkList()
      generateSymbolList()
    }
  }, [initialValue, networkData, symbolData])

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
          <Form
            form={form!}
            name="new-asset"
            onFinish={handleSubmit}
            layout="horizontal"
            initialValues={{
              ...initialValue
            }}
            onFieldsChange={() => setIsFieldsChange(true)}
          >
            <Card bordered={false}>
              <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input
                  readOnly={id === 'new' ? false : true}
                  bordered={id === 'new' ? true : false}
                />
              </Form.Item>

              <Form.Item name="network_type" label="Network Type" rules={[{ required: true }]}>
                {id === 'new' ? (
                  <Select options={networkList} />
                ) : (
                  <Input readOnly={true} bordered={false} />
                )}
              </Form.Item>

              <Form.Item name="symbol" label="Symbol" rules={[{ required: true }]}>
                {id === 'new' ? (
                  <Select options={symbolList} />
                ) : (
                  <Input readOnly={true} bordered={false} />
                )}
              </Form.Item>

              <Form.Item name="type" label="Type" rules={[{ required: true }]}>
                {id === 'new' ? (
                  <Select
                    options={[
                      { label: 'token', value: 'token' },
                      { label: 'coin', value: 'coin' }
                    ]}
                  />
                ) : (
                  <Input readOnly={true} bordered={false} />
                )}
              </Form.Item>

              <Form.Item name="handle" label="Handle" rules={[{ required: true }]} hidden>
                <Input readOnly={true} bordered={false} />
              </Form.Item>

              <Form.Item name="base" label="Base">
                <Input readOnly={true} bordered={false} />
              </Form.Item>

              <Form.Item
                name="contract_address"
                label="Contract Address"
                rules={[{ required: true }]}
              >
                <Input readOnly={true} bordered={false} />
              </Form.Item>

              <Form.Item name="decimal" label="Decimal" rules={[{ required: true }]} hidden>
                <Input readOnly={true} bordered={false} />
              </Form.Item>

              {id === 'new' && (
                <Form.Item name="blocked" label="Blocked" rules={[{ required: true }]}>
                  <Select
                    options={[
                      { label: 'True', value: true },
                      { label: 'False', value: false }
                    ]}
                  />
                </Form.Item>
              )}

              <Form.Item
                name="allow_withdraw"
                label="Allow to Withdraw?"
                rules={[{ required: true }]}
              >
                <Select
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false }
                  ]}
                />
              </Form.Item>

              <Form.Item
                name="allow_deposit"
                label="Allow to Deposit?"
                rules={[{ required: true }]}
                hidden
              >
                <Select
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false }
                  ]}
                />
              </Form.Item>

              <Form.Item name="withdraw_fee" label={`Withdraw Fee (${initialValue?.symbol})`}>
                <InputNumber />
              </Form.Item>

              <Col span={12}>
                <Form.Item name="blocked" label="Hidden?" rules={[{ required: true }]}>
                  <Select
                    options={[
                      { label: 'Yes', value: true },
                      { label: 'No', value: false }
                    ]}
                  />
                </Form.Item>
              </Col>

              {/* <Form.Item name="withdraw_fee_type" label="Withdraw Fee Type">
                <Select
                  options={[
                    { label: 'Value', value: 0 },
                    { label: 'Percentage', value: 1 }
                  ]}
                  bordered={false}
                  disabled
                />
              </Form.Item> */}
            </Card>
          </Form>

          <Gaps />
        </Col>
      </Row>
    </>
  )
}

export default AssetForm
