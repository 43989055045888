import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Empty, Spin, PageHeader, Button, Tooltip } from 'antd'
import useSWR from 'swr'

import { serialize } from '../../network/request'
import CurrencyService from '../../network/services/currency'
import CurrencyForm from './CurrencyForm'
import { ExclamationCircleTwoTone } from '@ant-design/icons'

const CurrencyDetail = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [fieldsChange, setIsFieldsChange] = useState(false)

  const saveAction = (
    <Button
      key={'save'}
      type="primary"
      onClick={() => {
        form.submit()
      }}
      loading={isLoading}
    >
      {id === 'new' ? 'Create' : 'Update'}
    </Button>
  )
  const discardAction = (
    <Button
      key={'discard'}
      type="ghost"
      onClick={() => {
        navigate('/dashboard/networks')
      }}
    >
      Discard
    </Button>
  )

  // use this to preload
  const {
    data: response,
    error,
    mutate
  } = useSWR(id !== 'new' ? serialize(CurrencyService.get(id!)) : null)

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response && id !== 'new') {
    return (
      <PageHeader>
        <Spin />
      </PageHeader>
    )
  }

  const record = response?.data

  const refresh = () => {
    if (id != null) {
      if (id === 'new') {
      } else {
        mutate()
      }
    }
  }

  return (
    <PageHeader
      title={
        id === 'new' ? (
          <>
            New Currency{' '}
            <Tooltip
              title="Currency is not allowed to be deleted once it is created!"
              placement="right"
            >
              <ExclamationCircleTwoTone twoToneColor="#eed202" />
            </Tooltip>
          </>
        ) : (
          'View Currency'
        )
      }
      // extra={[fieldsChange ? discardAction : null, saveAction]}
      onBack={() => navigate('/dashboard/currencies')}
    >
      <CurrencyForm
        form={form}
        initialValue={record}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setIsFieldsChange={setIsFieldsChange}
        refresh={refresh}
      />
    </PageHeader>
  )
}

export default CurrencyDetail
