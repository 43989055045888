import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, PageHeader, Button, Empty, Spin } from 'antd'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'

import tableStore from '../../lib/store/ticket_table'
import TicketService from '../../network/services/ticket'
import { serialize } from '../../network/request'
import TicketTable from './TicketTable'

const TicketPage = () => {
  const navigate = useNavigate()
  const primaryAction = (
    <Button
      key="add"
      type="primary"
      onClick={() => {
        navigate('/dashboard/tickets/new')
      }}
    >
      Create Ticket
    </Button>
  )

  return (
    <PageHeader title="Tickets" extra={[]}>
      <Card>
        <TicketTable />
      </Card>
    </PageHeader>
  )
}

export default TicketPage
