import { DateTime } from 'luxon'
import client, { IPaginatedResponse } from '../request'

export interface IUserSetting {
  id: string | number
  language: string
  push_notification: string
  user_id: string | number
  created_at: DateTime
  updated_at: DateTime
}

const getAll = `/user-settings`

const get = (id: string | number) => {
  return `/user-settings/${id}`
}

const getByUserId = (userId: string | number) => {
  return `/users/${userId}/user-settings`
}

const create = (data: IUserSetting) => {
  return client.post('/user-settings', data)
}

const update = (id: string | number, data: IUserSetting) => {
  return client.put(`/user-settings/${id}`, data)
}

const toRow = (response: IPaginatedResponse<IUserSetting[]>) => {
  if (response?.data?.length > 0) {
    return response.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (response: IPaginatedResponse<IUserSetting[]>) => {
  return {
    total: response?.meta?.total ?? 0
  }
}

const UserSettingService = {
  getAll,
  get,
  getByUserId,
  create,
  update,
  toRow,
  toPaginate
}

export default UserSettingService
