import { DateTime } from 'luxon'
import client, { IPaginatedResponse } from '../request'

export interface IWallet {
  id: string | number
  type: number
  token_type: string
  decimal: number
  blocked: boolean
  user_id: string | number
  created_at: DateTime
  updated_at: DateTime
  wallet_addresses: IWalletWalletAddress[]
}

export interface IWalletWalletAddress {
  id?: string | number
  wallet_id: number
  key: string | number
  address: string
  network_type: string
  user_id?: string | number
  created_at?: DateTime
  updated_at?: DateTime
}

export interface IWalletTransaction {
  id: string | number
  title: string
  description: string
  txn_type: string
  token_type: string
  network_type: string
  status: string
  confirmed: boolean
  amount: number
  amount_in: number
  amount_out: number
  amount_str: number
  asset_id: string | number
  wallet_id: string | number
  user_id: string | number
  stake_id: string | number
  txn_hash: string
  created_at: DateTime
  updated_at: DateTime
}

const getAll = `/wallets`

const getSystemWallets = `/wallets`

const get = (id: string | number) => {
  return `/wallets/${id}`
}

const getWalletTransactionsByWalletId = (wallet_id: string | number) => {
  return `wallets/${wallet_id}/transactions`
}

const getByUserId = (userId: string | number) => {
  return `/users/${userId}/wallets`
}

const create = (data: IWallet) => {
  return client.post('/wallets', data)
}

const update = (id: string | number, data: IWallet) => {
  return client.put(`/wallets/${id}`, data)
}

const toRow = (response: IPaginatedResponse<IWallet[]>) => {
  if (response?.data?.length > 0) {
    return response.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const wtToRow = (response: IPaginatedResponse<IWalletTransaction[]>) => {
  if (response?.data?.length > 0) {
    return response.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (response: IPaginatedResponse<IWallet[]>) => {
  return {
    total: response?.meta?.total ?? 0
  }
}

const wtToPaginate = (response: IPaginatedResponse<IWalletTransaction[]>) => {
  return {
    total: response?.meta?.total ?? 0
  }
}

const WalletService = {
  getAll,
  get,
  getSystemWallets,
  getByUserId,
  getWalletTransactionsByWalletId,
  create,
  update,
  toRow,
  wtToRow,
  toPaginate,
  wtToPaginate
}

export default WalletService
