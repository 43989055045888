import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, PageHeader, Empty, Spin } from 'antd'
import StakeTable from './StakeTable'

const StakePage = () => {
  const navigate = useNavigate()

  return (
    <PageHeader title="All Stakes">
      <Card>
        <StakeTable />
      </Card>
    </PageHeader>
  )
}

export default StakePage
