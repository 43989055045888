import { DateTime } from 'luxon'
import client, { IPaginatedResponse } from '../request'
import { IUser } from './user'
import { IStake } from './stake'
import { INft } from './nft'

export interface INftWithdraw {
  id: string | number
  token_type: string
  status: string
  sender_addr: string
  recipient_addr: string
  contract_address: string
  ref_no: string
  network_fee: number
  nft_id: string | number
  user_id: string | number
  transaction_id: string | number
  reason: string
  audited_at: DateTime
  confirmed_at: DateTime
  updated_at: DateTime
  expired_at: DateTime
  created_at: DateTime
  user?: IUser
  stake?: IStake
  nft?: INft
}

export interface INftOptional extends Partial<INftWithdraw> {}

const getAll = `/nft-withdraws`

const get = (id: string | number) => {
  return `/nft-withdraws/${id}`
}

const create = (data: INftWithdraw) => {
  return client.post('/nft-withdraws', data)
}

const update = (id: string | number, data: INftOptional) => {
  return client.put(`/nft-withdraws/${id}`, data)
}

const remove = (id: string | number) => {
  return client.delete(`/nft-withdraws/${id}`)
}

const toRow = (response: IPaginatedResponse<INftWithdraw[]>) => {
  if (response?.data?.length > 0) {
    return response.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (response: IPaginatedResponse<INftWithdraw[]>) => {
  return {
    total: response?.meta?.total ?? 0
  }
}

const approve = (id: string | number, data: any) => {
  return client.post(`/nft-withdraws/${id}/approve`, data)
}

const reject = (id: string | number, data: any) => {
  return client.post(`/nft-withdraws/${id}/reject`, data)
}

const NftService = {
  getAll,
  get,
  create,
  update,
  remove,
  toRow,
  toPaginate,
  approve,
  reject
}

export default NftService
