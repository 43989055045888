import { DateTime } from 'luxon'
import client, { IPaginatedResponse } from '../request'
import { IUser } from './user'

export interface ITransaction {
  id: string | number
  title: string
  description: string
  txn_type: string
  token_type: string
  network_type: string
  status: string
  confirmed: boolean
  amount: number
  amount_in: number
  amount_out: number
  amount_str: number
  asset_id: string | number
  stake_id: string | number
  user_id: string | number
  txn_hash: string
  created_at: DateTime
  updated_at: DateTime
  user: IUser
}

const getAll = `/stake-transactions`

const get = (id: string | number) => {
  return `/stake-transactions/${id}`
}

const toRow = (response: IPaginatedResponse<ITransaction[]>) => {
  if (response?.data?.length > 0) {
    return response.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (response: IPaginatedResponse<ITransaction[]>) => {
  return {
    total: response?.meta?.total ?? 0
  }
}

const TransactionService = {
  getAll,
  get,
  toRow,
  toPaginate
}

export default TransactionService
