import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Card,
  Col,
  Form,
  Button,
  Input,
  Row,
  message,
  FormInstance,
  Select,
  Upload,
  DatePicker,
  Table
} from 'antd'
import { RcFile, UploadFile } from 'antd/es/upload/interface'
import ImgCrop from 'antd-img-crop'

import Gaps from '../../components/Gaps'
import NftService, { INft } from '../../network/services/nft'
import { PlusOutlined } from '@ant-design/icons'
import FileService from 'network/services/file'
import UserService, { IUser } from 'network/services/user'
import useSWR from 'swr'
import moment from 'moment'
import NftBidTable from './NftBidTable'

const NftForm: FC<{
  form: FormInstance<INft>
  initialValue?: INft
  isLoading: boolean
  setIsLoading: (val: boolean) => void
  setIsFieldsChange: (val: boolean) => void
  refresh: () => void
}> = ({ form, initialValue, isLoading = false, setIsLoading, setIsFieldsChange, refresh }) => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [isUpdated, setIsUpdated] = useState(false)
  const [fileList, setFileList] = useState<any>([])
  const { data: userData } = useSWR(UserService.getAll)
  const [userList, setUserList] = useState<Record<string, any>[]>([])
  const status = Form.useWatch('status', form)
  const userId = Form.useWatch('user_id', form)

  const handleSubmit = async (values: INft) => {
    if (isLoading) return
    setIsLoading(true)

    if (fileList.length > 0) {
      // console.log(fileList)
      const file = fileList[0].url
      console.log(file)
      values.image_url = file
    }

    let result: any
    if (initialValue?.id) {
      try {
        result = await NftService.update(initialValue.id!, values)
        message.success('Update successful')
        refresh()
      } catch (error) {
        console.log(error)
        if (typeof error === 'object') {
          const errorMessage = JSON.parse(JSON.stringify(error))
          message.error(errorMessage?.message)
        }
        setIsLoading(false)
      }
      if (result?.data?.success) {
        setIsLoading(false)
        setIsFieldsChange(false)
        refresh()
      }
    } else {
      try {
        result = await NftService.create(values)
        message.success('Create successful')
      } catch (error) {
        console.log(error)
        if (typeof error === 'object') {
          const errorMessage = JSON.parse(JSON.stringify(error))
          message.error(errorMessage?.message)
        }
        setIsLoading(false)
      }
      if (result?.data?.success) {
        navigate('/dashboard/nfts')
      }
    }
  }

  const handleRemove = async (file: UploadFile<any>) => {
    const index = fileList.indexOf(file)
    const newFileList = fileList.slice()
    newFileList.splice(index, 1)
    setFileList(newFileList)
    setIsUpdated(true)
  }

  const handleUpload = async (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
      return false
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
      return false
    }
    try {
      const upload = await FileService.upload(file)
      console.log(upload)
      if (upload.success == true) {
        setFileList([
          {
            ...file,
            url: upload.data
          }
        ])
        setIsUpdated(true)
      }
    } catch (error: any) {
      message.error(`Upload failed : ${error?.data?.statusText ?? error?.message ?? 'unknown'}`)
    }
  }

  const generateUserList = () => {
    const tmpList: Record<string, any>[] = []
    if (userData?.data) {
      userData.data.forEach((user: IUser) => {
        // if (user.admin === null) {
        tmpList.push({
          label: user.full_name + ' - ' + user.email,
          value: user.id
        })
        // }
      })
    }
    console.log(initialValue)
    tmpList.push({
      label: initialValue?.user?.full_name + ' - ' + initialValue?.user?.email,
      value: initialValue?.user_id
    })
    console.log(tmpList)
    setUserList(tmpList)
  }

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (id !== 'new' && initialValue?.image_url) {
      setFileList([
        {
          uid: '1',
          name: 'default',
          status: 'done',
          url: initialValue?.image_url
        }
      ])
    }

    if (userData !== null) {
      generateUserList()
    }
  }, [initialValue, id, userData])

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
          <Form
            form={form!}
            name="new-network"
            onFinish={handleSubmit}
            layout="horizontal"
            initialValues={{
              ...initialValue,
              expired_at: initialValue?.expired_at != null ? moment(initialValue?.expired_at) : null
            }}
            onFieldsChange={() => setIsFieldsChange(true)}
          >
            <Card bordered={false}>
              <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input
                // readOnly={id === 'new' ? false : true}
                // bordered={id === 'new' ? true : false}
                />
              </Form.Item>

              <Form.Item name="description" label="Description" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item name="type" label="Type" rules={[{ required: false }]}>
                <Select
                  disabled={id === 'new' ? false : true}
                  options={[
                    { label: 'Avatar', value: 'avatar' },
                    { label: 'Potion', value: 'potion' }
                  ]}
                />
              </Form.Item>

              <Form.Item name="status" label="Status" rules={[{ required: true }]}>
                <Select
                  disabled={id === 'new' ? false : true}
                  options={[
                    { label: 'Active', value: 'active' },
                    { label: 'Owned', value: 'owned' },
                    { label: 'Withdrawn', value: 'withdrawn' }
                  ]}
                />
              </Form.Item>

              <Form.Item name="expired_at" label="Expiry Date" rules={[{ required: true }]}>
                <DatePicker showTime disabled={status !== 'active' && id !== 'new'} />
              </Form.Item>

              {/* <Form.Item
                name="contact_address"
                label="Contact Address"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item> */}

              <Form.Item name="price" label="Price" rules={[{ required: true }]}>
                <Input readOnly prefix={initialValue?.token_type} />
              </Form.Item>

              <Form.Item name="withdraw_fee" label="Withdrawal Fee" rules={[{ required: true }]}>
                <Input readOnly prefix={initialValue?.token_type} />
              </Form.Item>

              <Form.Item name="user_id" label="Owned By" required={status !== 'active'}>
                <Select
                  options={userList}
                  listHeight={133}
                  disabled={status !== 'active' && id !== 'new'}
                />
              </Form.Item>

              {id === 'new' && (
                <Form.Item name="blocked" label="Hidden?" rules={[{ required: true }]}>
                  <Select
                    options={[
                      { label: 'True', value: true },
                      { label: 'False', value: false }
                    ]}
                  />
                </Form.Item>
              )}

              <Form.Item name="image_url" label="Image Url">
                <ImgCrop>
                  <Upload
                    listType="picture-card"
                    multiple={false}
                    {...(initialValue?.image_url && {
                      defaultFileList: [
                        {
                          uid: '1',
                          name: 'default',
                          status: 'done',
                          url: initialValue?.image_url
                        }
                      ]
                    })}
                    {...(isUpdated && { fileList: fileList })}
                    beforeUpload={(file) => {
                      handleUpload(file)
                      return false
                    }}
                    onRemove={handleRemove}
                    showUploadList={{ showPreviewIcon: false }}
                  >
                    {fileList.length > 0 ? null : (
                      <div>
                        <PlusOutlined />
                        <div className="ant-upload-text">Upload</div>
                      </div>
                    )}
                  </Upload>
                </ImgCrop>
              </Form.Item>
            </Card>
          </Form>

          <Gaps />
          {/* 
          <Card title="Bids Record">
            <NftBidTable />
          </Card> */}
        </Col>
      </Row>
    </>
  )
}

export default NftForm
