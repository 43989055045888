import { Card, Col, DatePicker, PageHeader, Row, Select, Statistic } from 'antd'
import Gaps from 'components/Gaps'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { RangeValue } from 'rc-picker/lib/interface'
import CurrencyService, { ICurrency } from '../../network/services/currency'
import StatisticService, { IPendingStatistic, IStatistic } from '../../network/services/statistic'
import useSWR from 'swr'
import { serialize } from '../../network/request'
import { Link, useNavigate } from 'react-router-dom'
import { ExportOutlined, LinkOutlined } from '@ant-design/icons'

const PendingStatisticsCard: FC<{
  fromDate?: string
  toDate?: string
  symbol?: string
}> = ({ fromDate, toDate, symbol }) => {
  const {
    data: response,
    error,
    mutate
  } = useSWR(
    serialize(StatisticService.getPendingStatistics, {
      from_date: fromDate,
      to_date: toDate
    })
  )
  const record: IPendingStatistic = response?.data
  const navigate = useNavigate()

  const bodyStyle: React.CSSProperties = { padding: '14px' }

  return (
    <Row gutter={[16, 16]}>
      <Col md={12} xl={6}>
        <Card bodyStyle={bodyStyle}>
          <Statistic
            title={
              <Row justify="space-between">
                <Col>
                  <span>Deposits</span>
                </Col>
              </Row>
            }
            value={record?.deposits}
          />
        </Card>
      </Col>
      <Col md={12} xl={6}>
        <Card bodyStyle={bodyStyle}>
          <Statistic
            title={
              <Row justify="space-between">
                <Col>
                  <span>Withdraws</span>
                </Col>
                <Col>
                  <Link to="/dashboard/withdraws">
                    <LinkOutlined />
                  </Link>
                </Col>
              </Row>
            }
            value={record?.withdraws}
          />
        </Card>
      </Col>
      <Col md={12} xl={6}>
        <Card bodyStyle={bodyStyle}>
          <Statistic
            title={
              <Row justify="space-between">
                <Col>
                  <span>New Tickets</span>
                </Col>
                <Col>
                  <Link to="/dashboard/tickets">
                    <LinkOutlined />
                  </Link>
                </Col>
              </Row>
            }
            value={record?.tickets_new}
          />
        </Card>
      </Col>
      <Col md={12} xl={6}>
        <Card bodyStyle={bodyStyle}>
          <Statistic
            title={
              <Row justify="space-between">
                <Col>
                  <span>New KYC</span>
                </Col>
                <Col>
                  <Link to="/dashboard/verifications">
                    <LinkOutlined />
                  </Link>
                </Col>
              </Row>
            }
            value={record?.verifications}
          />
        </Card>
      </Col>
    </Row>
  )
}

const PendingStatisticsPage = () => {
  const [durationRange, setDurationRange] = useState([
    moment().startOf('day'),
    moment().endOf('day')
  ])

  const onDateChange = (dates: RangeValue<moment.Moment>, dateStrings: [string, string]) => {
    if (dates != null && dates.length === 2) {
      setDurationRange([moment(dates[0]), moment(dates[1])])
    }
    // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1])
  }

  const primaryAction = (
    <>
      <DatePicker.RangePicker
        defaultValue={[moment().startOf('day'), moment().endOf('day')]}
        ranges={{
          Today: [moment(), moment()],
          'Last 7 days': [moment().subtract(7, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment()],
          'Last 30 days': [moment().subtract(30, 'days'), moment()],
          'Last Month': [
            moment().subtract(1, 'months').startOf('month'),
            moment().subtract(1, 'months').endOf('month')
          ],
          'Last 6 Months': [moment().subtract(6, 'months').startOf('month'), moment()],
          'Last 365 days': [moment().subtract(365, 'days'), moment()]
        }}
        onChange={onDateChange}
      />
    </>
  )

  return (
    <Card extra={primaryAction} title={'Pending Actions'}>
      <PendingStatisticsCard
        fromDate={durationRange[0].format('YYYY-MM-DD')}
        toDate={durationRange[1].format('YYYY-MM-DD')}
      />
    </Card>
  )
}

export default PendingStatisticsPage
