import {
  ApartmentOutlined,
  BgColorsOutlined,
  BookOutlined,
  CheckSquareOutlined,
  CiCircleOutlined,
  CloudUploadOutlined,
  CustomerServiceOutlined,
  DeliveredProcedureOutlined,
  DeploymentUnitOutlined,
  DollarCircleOutlined,
  DownloadOutlined,
  FontColorsOutlined,
  FormOutlined,
  MoneyCollectOutlined,
  NodeCollapseOutlined,
  NotificationOutlined,
  UnorderedListOutlined,
  UploadOutlined,
  UserOutlined,
  VerifiedOutlined,
  WalletOutlined
} from '@ant-design/icons'

export const options = [
  {
    key: '/dashboard/users',
    label: 'Users',
    icon: <UserOutlined />
  },
  // {
  //   key: '/dashboard/currencies',
  //   label: 'Currencies',
  //   icon: <MoneyCollectOutlined />
  // },
  {
    key: '/dashboard/assets',
    label: 'Assets',
    icon: <FontColorsOutlined />
  },
  // {
  //   key: '/dashboard/networks',
  //   label: 'Networks',
  //   icon: <ApartmentOutlined />
  // },
  {
    key: '/dashboard/pools',
    label: 'Pools',
    icon: <DeploymentUnitOutlined />
  },
  {
    key: '/dashboard/stakes',
    label: 'Stakes',
    icon: <FormOutlined />
  },
  {
    key: '/dashboard/withdraws',
    label: 'Withdraws',
    icon: <NodeCollapseOutlined />
  },
  {
    key: '/dashboard/transactions',
    label: 'Transactions',
    icon: <WalletOutlined />
  }
  // {
  //   key: '/dashboard/profits',
  //   label: 'Profits',
  //   icon: <DollarCircleOutlined />
  // },
  // {
  //   key: '/dashboard/verifications',
  //   label: 'KYC',
  //   icon: <VerifiedOutlined />
  // },
  // {
  //   key: '/dashboard/announcements',
  //   label: 'Announcements',
  //   icon: <NotificationOutlined />
  // },
  // {
  //   key: '/dashboard/tickets',
  //   label: 'Help Centre',
  //   icon: <CustomerServiceOutlined />
  // }
]

export const finances = [
  // {
  //   key: '/dashboard/finance/wallets',
  //   label: 'Wallets',
  //   icon: <BookOutlined />
  // },
  // {
  //   key: '/dashboard/finance/profits',
  //   label: 'Profits',
  //   icon: <BookOutlined />
  // }
]

export const nfts = [
  {
    key: '/dashboard/nfts',
    label: 'Listings',
    icon: <UnorderedListOutlined />
  },
  // {
  //   key: '/dashboard/nft-deposits',
  //   label: 'Deposits',
  //   icon: <CloudUploadOutlined />
  // },
  {
    key: '/dashboard/nft-withdraws',
    label: 'Withdraws',
    icon: <DeliveredProcedureOutlined />
  }
]
