import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, Col, Form, Input, Row, message, FormInstance, Select, DatePicker } from 'antd'
import Gaps from '../../components/Gaps'
import NftDepositService, { INftDeposit } from '../../network/services/nft-deposit'
import moment from 'moment'

const NftForm: FC<{
  form: FormInstance<INftDeposit>
  initialValue?: INftDeposit
  isLoading: boolean
  setIsLoading: (val: boolean) => void
  setIsFieldsChange: (val: boolean) => void
  refresh: () => void
}> = ({ form, initialValue, isLoading = false, setIsLoading, setIsFieldsChange, refresh }) => {
  const navigate = useNavigate()
  const { id } = useParams()

  const handleSubmit = async (values: INftDeposit) => {
    if (isLoading) return
    setIsLoading(true)

    let result: any
    if (initialValue?.id) {
      try {
        result = await NftDepositService.update(initialValue.id!, values)
        message.success('Update successful')
        refresh()
      } catch (error) {
        console.log(error)
        if (typeof error === 'object') {
          const errorMessage = JSON.parse(JSON.stringify(error))
          message.error(errorMessage?.message)
        }
        setIsLoading(false)
      }
      if (result?.data?.success) {
        setIsLoading(false)
        setIsFieldsChange(false)
        refresh()
      }
    } else {
      try {
        result = await NftDepositService.create(values)
        message.success('Create successful')
      } catch (error) {
        console.log(error)
        if (typeof error === 'object') {
          const errorMessage = JSON.parse(JSON.stringify(error))
          message.error(errorMessage?.message)
        }
        setIsLoading(false)
      }
      if (result?.data?.success) {
        navigate('/dashboard/nft-deposits')
      }
    }
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }}>
          <Form
            form={form!}
            name="new-network"
            onFinish={handleSubmit}
            layout="horizontal"
            initialValues={{
              ...initialValue,
              expired_at:
                initialValue?.expired_at != null ? moment(initialValue?.expired_at) : null,
              created_at: initialValue?.created_at != null ? moment(initialValue?.created_at) : null
            }}
            onFieldsChange={() => setIsFieldsChange(true)}
          >
            <Card bordered={false}>
              <Form.Item name="status" label="Status" rules={[{ required: true }]}>
                <Select
                  disabled={initialValue?.status === 'completed'}
                  options={[
                    { label: 'New Request', value: 'new' },
                    { label: 'Pending Deposit', value: 'pending' },
                    { label: 'Completed', value: 'completed' },
                    { label: 'Expired', value: 'expired' }
                  ]}
                />
              </Form.Item>

              <Form.Item name="expired_at" label="Expiry Date" rules={[{ required: true }]}>
                <DatePicker showTime disabled={initialValue?.status === 'completed'} />
              </Form.Item>

              <Form.Item name="created_at" label="Requested Date" rules={[{ required: true }]}>
                <DatePicker showTime disabled />
              </Form.Item>

              <Form.Item name="recipient_addr" label="Wallet Address" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item name="user_id" label="Requestor">
                <Select
                  options={[{ label: initialValue?.user?.email, value: initialValue?.user_id }]}
                  disabled
                />
              </Form.Item>
            </Card>
          </Form>
          <Gaps />
        </Col>
      </Row>
    </>
  )
}

export default NftForm
