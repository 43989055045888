import { FC, useState, useEffect } from 'react'
import { Input, Form, Row, Col, DatePicker, Button, Select, message } from 'antd'
import _ from 'lodash'
import moment from 'moment'

const { RangePicker } = DatePicker
const { Option } = Select

interface IDropdown {
  key: string
  value: Record<string, any>[]
  width?: number
  label?: string
  default?: string | undefined
}

interface IFilterColumn {
  key: string
  name: string
  default?: boolean
}

interface ITableFilter {
  dropdowns?: IDropdown[]
  columns: IFilterColumn[]
  initial: any
  setCurrentFilters: (val: Record<string, any>) => void
  hasDate?: boolean
}

export const TableFilter: FC<ITableFilter> = ({
  dropdowns = [
    // {
    //   key: 'status',
    //   value: [
    //     { key: -1, name: 'All Status' },
    //     { key: 0, name: 'Pending' },
    //     { key: 1, name: 'Success' },
    //     { key: 2, name: 'Failed' },
    //     { key: 3, name: 'Rejected' }
    //   ]
    // }
  ],
  columns,
  initial,
  setCurrentFilters = null,
  hasDate = false
}) => {
  const [form] = Form.useForm()
  const [key, setKey] = useState<string | null>(null)

  const filterColumns: IFilterColumn[] = []
  const filterKeys = Object.keys(initial ?? {})
  for (const column of columns) {
    if (filterKeys.includes(column.key)) {
      column.default = true
    }
    filterColumns.push(column)
  }

  useEffect(() => {
    for (const column of columns) {
      if (filterKeys.includes(column.key)) {
        setKey(column.key)
        form.setFieldsValue({ search: initial[column.key] })
      }
    }
  }, [])

  const submit = (v: Record<string, any>) => {
    // console.log(v)
    // console.log(key)
    let filtering: Record<string, any> = {}
    if (key != null) {
      filtering[key] = v.search
    }

    // if (key == null) {
    //   message.error('Please select search scope to proceed for searching')
    //   return
    // }

    if (!_.isEmpty(dropdowns)) {
      for (let dropdown of dropdowns!) {
        // && v[dropdown.key] != -1
        if (v[dropdown.key] != null) {
          filtering[dropdown.key] = v[dropdown.key]
        }
      }
    }

    if (v.date?.length === 2) {
      filtering['from_date'] = v.date[0].toISOString()
      filtering['to_date'] = v.date[1].toISOString()
    }

    if (setCurrentFilters != null) {
      setCurrentFilters(filtering)
      // tableStore.state.filters = filtering
      // tableStore.state.currentPage = 1
    }
  }

  const reset = (v: any) => {
    let filtering: any = {}
    for (let dropdown of dropdowns) {
      // && v[dropdown.key] != -1
      if (v[dropdown.key] != null) {
        filtering[dropdown.key] = v[dropdown.key]
      }
    }

    if (v.date != null) {
      filtering['from_date'] = v.date[0].toISOString()
      filtering['to_date'] = v.date[1].toISOString()
    }

    console.log(filtering)
    if (Object.keys(filtering)?.length > 0 && setCurrentFilters != null) {
      setCurrentFilters(filtering)
      // tableStore.state.filters = filtering
      // tableStore.state.currentPage = 1
    }
  }

  return (
    <Form
      form={form}
      onFinish={submit}
      initialValues={{
        ...initial,
        date:
          initial?.from_date && initial?.to_date
            ? [moment(initial.from_date), moment(initial.to_date)]
            : []
      }}
    >
      <Row gutter={[12, 12]}>
        {!_.isEmpty(dropdowns) &&
          dropdowns!.map((dropdown, index) => {
            return (
              dropdown?.value?.length > 0 && (
                <Col key={index}>
                  <Form.Item name={dropdown.key}>
                    <Select
                      style={{ width: dropdown?.width ?? 125 }}
                      // dropdownMatchSelectWidth={true}
                      placeholder={dropdown.label}
                      onSelect={() => {
                        form.submit()
                      }}
                      defaultValue={dropdown.default}
                    >
                      {dropdown.value.map((d) => {
                        return (
                          <Option value={d.key} key={d.key}>
                            {_.startCase(d.name)}
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              )
            )
          })}

        {hasDate && (
          <Col>
            <Form.Item name="date">
              <RangePicker />
            </Form.Item>
          </Col>
        )}

        {filterColumns?.length > 0 && (
          <Col>
            <Form.Item name="search">
              <Input
                addonBefore={
                  <Select
                    style={{ width: 140 }}
                    onChange={(v) => setKey(v)}
                    placeholder="Search scope"
                    defaultValue={_.find(filterColumns, { default: true })?.key}
                  >
                    {filterColumns.map((column) => {
                      return (
                        <Option value={column.key} key={column.key}>
                          {column.name}
                        </Option>
                      )
                    })}
                  </Select>
                }
                allowClear
              />
            </Form.Item>
          </Col>
        )}
        <Col>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default TableFilter
