import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, Col, Form, Button, Input, Row, message, FormInstance, Select } from 'antd'

import Gaps from '../../components/Gaps'
import NetworkService, { INetwork } from '../../network/services/network'

const NetworkForm: FC<{
  form: FormInstance<INetwork>
  initialValue?: INetwork
  isLoading: boolean
  setIsLoading: (val: boolean) => void
  setIsFieldsChange: (val: boolean) => void
  refresh: () => void
}> = ({ form, initialValue, isLoading = false, setIsLoading, setIsFieldsChange, refresh }) => {
  const navigate = useNavigate()
  const { id } = useParams()

  const handleSubmit = async (values: INetwork) => {
    if (isLoading) return
    setIsLoading(true)

    let result
    if (initialValue?.id) {
      try {
        result = await NetworkService.update(initialValue.id!, values)
        message.success('Update successful')
        setIsLoading(false)
        refresh()
      } catch (error) {
        console.log(error)
        if (typeof error === 'object') {
          const errorMessage = JSON.parse(JSON.stringify(error))
          message.error(errorMessage?.message)
        }
        setIsLoading(false)
      }
    } else {
      try {
        result = await NetworkService.create(values)
        message.success('Create successful')
      } catch (error) {
        console.log(error)
        if (typeof error === 'object') {
          const errorMessage = JSON.parse(JSON.stringify(error))
          message.error(errorMessage?.message)
        }
        setIsLoading(false)
      }
    }

    if (result) {
      setIsLoading(false)
      setIsFieldsChange(false)
      navigate('/dashboard/networks')
    }
  }

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
          <Form
            form={form!}
            name="new-network"
            onFinish={handleSubmit}
            layout="vertical"
            initialValues={{
              ...initialValue
            }}
            onFieldsChange={() => setIsFieldsChange(true)}
          >
            <Card bordered={false}>
              <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                {id === 'new' ? (
                  <Input
                    onBlur={(e) => form.setFieldsValue({ name: e.target.value.toUpperCase() })}
                  />
                ) : (
                  <Input readOnly={true} bordered={false} />
                )}
              </Form.Item>

              <Form.Item name="blocked" label="Blocked" rules={[{ required: true }]}>
                <Select
                  options={[
                    { label: 'True', value: true },
                    { label: 'False', value: false }
                  ]}
                />
              </Form.Item>
            </Card>
          </Form>

          <Gaps />
        </Col>
      </Row>
    </>
  )
}

export default NetworkForm
