import { Card, Empty, PageHeader } from 'antd'
import globalStore, { setActiveTabGlobal } from 'lib/store/global'
import { serialize } from 'network/request'
import UserService from 'network/services/user'
import RewardReceivedPage from 'pages/rewards_received'
import RewardSentPage from 'pages/rewards_sent'
// import TransactionPage from 'pages/transactions'
import UserActivityPage from 'pages/user_activities'
import UserSecurityPage from 'pages/user_securities'
import UserSettingPage from 'pages/user_settings'
import WalletPage from 'pages/wallets'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'
import StakePage from 'pages/stakes'
import StakeTable from 'pages/stakes/StakeTable'

const UserRelated = () => {
  const navigate = useNavigate()
  const { user_id } = useParams()
  const state = useSnapshot(globalStore)
  const [activeTab, setActiveTab] = useState<string>(state.userTab)

  const { data: response, error, mutate } = useSWR(serialize(UserService.get(user_id!)))

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  const tabList = [
    {
      key: 'wallets',
      tab: 'Assets'
    },
    {
      key: 'stakes',
      tab: 'Stakes'
    },
    // {
    //   key: 'transactions',
    //   tab: 'Transactions'
    // },
    {
      key: 'activities',
      tab: 'Activities'
    },
    {
      key: 'securities',
      tab: 'Securities'
    }
    // {
    //   key: 'verifications',
    //   tab: 'Verifications'
    // },
    // {
    //   key: 'settings',
    //   tab: 'Settings'
    // }
    // {
    //   key: 'rewards_received',
    //   tab: 'Rewards Received'
    // },
    // {
    //   key: 'rewards_sent',
    //   tab: 'Rewards Sent'
    // }
  ]

  const contentList: Record<string, React.ReactNode> = {
    wallets: <WalletPage />,
    stakes: <StakeTable userId={user_id} hideFilter={true} />,
    // transactions: <TransactionPage />,
    activities: <UserActivityPage />,
    securities: <UserSecurityPage />,
    // verifications: <VerificationPage />,
    settings: <UserSettingPage />,
    rewards_received: <RewardReceivedPage />,
    rewards_sent: <RewardSentPage />
  }

  const onTabChange = (key: string) => {
    setActiveTab(key)
    setActiveTabGlobal(key)
    globalStore.userTab = key
  }

  return (
    <PageHeader
      title={`View User Details - ${response?.data?.full_name}`}
      // extra={[discardAction, saveAction]}
      onBack={() => navigate('/dashboard/users')}
    >
      <Card
        tabList={tabList}
        defaultActiveTabKey="wallets"
        activeTabKey={activeTab}
        onTabChange={(key) => {
          onTabChange(key)
        }}
      >
        {activeTab && contentList[activeTab]}
      </Card>
    </PageHeader>
  )
}

export default UserRelated
