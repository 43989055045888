import { DateTime } from 'luxon'
import client, { IPaginatedResponse } from '../request'

export interface IStake {
  id: string | number
  token_name: string
  token_type: string
  type: string
  status: string
  payout_day: number
  tenure: number
  completed: boolean
  earn_vxt: boolean
  link_nft: boolean
  profit_rate: number
  incentive_rate: number
  nft_rate: number
  principal_amount: number
  subscription_fee: number
  termination_fee: number
  deposited_at: DateTime
  started_at: DateTime
  next_distribute_at: DateTime
  matured_at: DateTime
  terminated_at: DateTime
  pool_id: string | number
  user_id: string | number
  wallet_id: string | number
  created_at: DateTime
  updated_at: DateTime
  txn_hash: string
  payout_token: string
  nft_id: string | number
  user: {
    email: string
  }
}

export interface IStakeTerminate {
  termination_fee_rate: number
}

const getAll = `/stakes`

const get = (id: string | number) => {
  return `/stakes/${id}`
}

const getByUserId = (userId: string | number) => {
  return `/users/${userId}/stakes`
}

const create = (data: IStake) => {
  return client.post('/stakes', data)
}

const terminate = (id: number | string, data: IStakeTerminate | null = null) => {
  return client.post(`/stakes/${id}/terminate`, data)
}

const update = (id: string | number, data: IStake) => {
  return client.put(`/stakes/${id}`, data)
}

const toRow = (response: IPaginatedResponse<IStake[]>) => {
  if (response?.data?.length > 0) {
    return response.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (response: IPaginatedResponse<IStake[]>) => {
  return {
    total: response?.meta?.total ?? 0
  }
}

const WalletService = {
  getAll,
  get,
  getByUserId,
  create,
  update,
  toRow,
  toPaginate,
  terminate
}

export default WalletService
