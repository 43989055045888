import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Card,
  Col,
  Form,
  Button,
  Input,
  Row,
  message,
  FormInstance,
  Select,
  DatePicker
} from 'antd'

import Gaps from '../../components/Gaps'
import TicketService, { ITicket } from '../../network/services/ticket'
import moment from 'moment'

const TicketForm: FC<{
  form: FormInstance<ITicket>
  initialValue?: ITicket
  isLoading: boolean
  setIsLoading: (val: boolean) => void
  refresh: () => void
  setIsFieldsChange: (val: boolean) => void
}> = ({ form, initialValue, isLoading = false, setIsLoading, refresh, setIsFieldsChange }) => {
  const navigate = useNavigate()
  const { id } = useParams()

  const handleSubmit = async (values: ITicket) => {
    console.log(values)
    if (isLoading) return
    setIsLoading(true)

    let result
    if (initialValue?.id) {
      try {
        result = await TicketService.update(initialValue.id!, values)
        message.success('Update successful')
      } catch (error) {
        console.log(error)
        if (typeof error === 'object') {
          const errorMessage = JSON.parse(JSON.stringify(error))
          message.error(errorMessage?.message)
        }
        setIsLoading(false)
      }
    } else {
      try {
        result = await TicketService.create(values)
        message.success('Create successful')
      } catch (error) {
        console.log(error)
        if (typeof error === 'object') {
          const errorMessage = JSON.parse(JSON.stringify(error))
          message.error(errorMessage?.message)
        }
        setIsLoading(false)
      }
    }

    if (result?.data?.success && result?.data?.data) {
      refresh()
      setIsLoading(false)
      navigate(`/dashboard/tickets/${result?.data?.data?.id ?? 0}`)
    }
  }

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
          <Form
            form={form!}
            name="new-ticket"
            onFinish={handleSubmit}
            layout="vertical"
            {...(id !== 'new' && {
              initialValues: { ...initialValue, created_at: moment(initialValue?.created_at) }
            })}
            onFieldsChange={() => {
              setIsFieldsChange(true)
            }}
          >
            <Card bordered={false}>
              <Form.Item name="subject" label="Subject">
                <Input readOnly />
              </Form.Item>

              <Form.Item name="message" label="Message">
                <Input.TextArea rows={12} readOnly />
              </Form.Item>

              <Form.Item name="user" label="Sender">
                {/* <Input>{initialValue?.user?.email}</Input> */}
                {initialValue?.user?.email}
              </Form.Item>

              <Form.Item name="status" label="Status" rules={[{ required: true }]}>
                <Select
                  options={[
                    { label: 'Pending', value: 'pending' },
                    { label: 'Completed', value: 'completed' }
                  ]}
                />
              </Form.Item>

              <Form.Item name="created_at" label="Submitted Date" rules={[{ required: true }]}>
                <DatePicker />
              </Form.Item>
            </Card>
          </Form>

          <Gaps />
        </Col>
      </Row>
    </>
  )
}

export default TicketForm
