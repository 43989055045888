import { DateTime } from 'luxon'
import client, { IPaginatedResponse } from '../request'

export interface IStatistic {
  deposit_amount: number
  withdraw_amount: number
  user_count: number
  stake_12_amount: number
  stake_24_amount: number
  deposit_network_fee: number
  withdraw_network_fee: number
  withdraw_fee: number
  subscription_fee: number
  termination_fee: number
  termination_amount: number
  maturity_amount: number
}

export interface IPendingStatistic {
  deposits: number
  withdraws: number
  tickets_new: number
  verifications: number
}

const getStatistics = `/statistics`
const getPendingStatistics = `/pending-statistics`

const UserService = {
  getStatistics,
  getPendingStatistics
}

export default UserService
