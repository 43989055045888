import {
  Card,
  Empty,
  Image,
  PageHeader,
  Row,
  Col,
  Form,
  Statistic,
  DatePicker,
  Input,
  message,
  Button,
  Modal
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import Gaps from 'components/Gaps'
import _ from 'lodash'
import FileService from 'network/services/file'
import VerificationService from 'network/services/verification'
import { FC, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useSWR from 'swr'

const InputEdit: FC<{
  field: string
  title?: string
  value?: string
  type?: string
  edit: boolean
}> = ({ field, title, value, type = 'default', edit = false }) => {
  if (edit) {
    return (
      <Form.Item name={field} label={<span style={{ color: 'grey' }}>{title}</span>}>
        {type === 'date' ? <DatePicker format="YYYY/MM/DD" /> : <Input style={{ width: '90%' }} />}
      </Form.Item>
    )
  } else {
    return type === 'default' ? (
      <>
        <Form.Item name={field} hidden={true} />
        <Statistic title={title} value={value} />
      </>
    ) : (
      <>
        <Form.Item name={field} hidden={true} />
        <Statistic
          title={title}
          value={value}
          formatter={(value) => {
            return <span>{value}</span>
          }}
        />
      </>
    )
  }
}

const ApproveModal: FC<{
  visible: boolean
  setVisible: (val: boolean) => void
  refresh: () => void
}> = ({ visible, setVisible, refresh }) => {
  const { id } = useParams()

  return (
    <Modal
      title={`Approve KYC Confirmation`}
      visible={visible}
      onOk={async () => {
        try {
          const { data: response }: any = await VerificationService.approve(id!)
          if (response?.success) {
            message.success('Approve successful')
          } else {
            message.error(response?.message ?? 'Approve failed')
          }
          setVisible(false)
          refresh()
        } catch (error) {
          console.log(error)
          if (typeof error === 'object') {
            const errorMessage = JSON.parse(JSON.stringify(error))
            message.error(errorMessage?.message)
          }
        }
      }}
      onCancel={() => {
        setVisible(false)
      }}
      okText="Approve"
      cancelText="Cancel"
    >
      Are you sure want to approve the KYC? This can't be undone.
    </Modal>
  )
}

const RejectModal: FC<{
  visible: boolean
  setVisible: (val: boolean) => void
  refresh: () => void
}> = ({ visible, setVisible, refresh }) => {
  const { id } = useParams()
  const [form] = useForm()

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{}}
      onFinish={async (v) => {
        try {
          console.log(v)
          const { data: response }: any = await VerificationService.reject(id!, {
            reason: v?.reason ?? ''
          })
          if (response?.success) {
            message.success('Reject successful')
            refresh()
          } else {
            message.error(response?.message ?? 'Reject failed')
          }
          setVisible(false)
        } catch (error) {
          console.log(error)
          if (typeof error === 'object') {
            const errorMessage = JSON.parse(JSON.stringify(error))
            message.error(errorMessage?.message)
          }
        }
      }}
    >
      <Modal
        title={`Reject KYC Confirmation`}
        visible={visible}
        onOk={() => {
          form.submit()
        }}
        onCancel={() => {
          setVisible(false)
        }}
        okText="Reject"
        cancelText="Cancel"
      >
        Are you sure want to reject the KYC? If yes, please provide a valid reason. This can't be
        undone.
        <Gaps />
        <Form.Item name="reason" label="Reject Reason" required={true}>
          <Input.TextArea rows={6} />
        </Form.Item>
      </Modal>
    </Form>
  )
}

const VerificationForm = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { id } = useParams()
  const [edit, setEdit] = useState(false)
  const [approveModalVisible, setApproveModalVisible] = useState(false)
  const [rejectModalVisible, setRejectModalVisible] = useState(false)

  const { data: response, mutate } = useSWR(VerificationService.get(id!))
  const verificationsData = response?.data
  const { data: idFront } = useSWR(
    verificationsData?.id_front ? FileService.getSignedFile(verificationsData.id_front) : null
  )

  const { data: idBack } = useSWR(
    verificationsData?.id_back ? FileService.getSignedFile(verificationsData.id_back) : null
  )

  const { data: personalPic } = useSWR(
    verificationsData?.personal_pic
      ? FileService.getSignedFile(verificationsData.personal_pic)
      : null
  )

  const { data: proofDocument } = useSWR(
    verificationsData?.proof_document
      ? FileService.getSignedFile(verificationsData.proof_document)
      : null
  )

  const refresh = () => {
    if (id != null) {
      if (id === 'new') {
      } else {
        mutate()
      }
    }
  }

  return (
    <PageHeader
      title="All Verification Documents"
      onBack={() => navigate(`/dashboard/verifications`)}
      extra={
        verificationsData?.status === 'pending'
          ? [
              <Button
                type="primary"
                onClick={() => {
                  setApproveModalVisible(true)
                }}
              >
                Approve
              </Button>,
              <Button
                type="primary"
                danger
                onClick={() => {
                  setRejectModalVisible(true)
                }}
              >
                Reject
              </Button>
            ]
          : []
      }
    >
      <ApproveModal
        visible={approveModalVisible}
        setVisible={setApproveModalVisible}
        refresh={refresh}
      />
      <RejectModal
        visible={rejectModalVisible}
        setVisible={setRejectModalVisible}
        refresh={refresh}
      />
      <Card
        title={
          <>
            <Row justify="space-between">
              <Col>KYC Info</Col>
              <Col>{_.capitalize(verificationsData?.status ?? '-')}</Col>
            </Row>
          </>
        }
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            ...verificationsData
          }}
          onFinish={async (v) => {
            console.log(v)
            const result: any = await VerificationService.update(v.id, { ...v })
            if (result?.success) {
              message.success('Save successfully')
              setEdit(false)
            } else {
              message.error('Save failed')
            }
          }}
        >
          <Row gutter={[12, 12]}>
            <Col xs={24} md={12}>
              <InputEdit
                field="email"
                title="Email"
                value={verificationsData?.email ?? '-'}
                edit={edit}
              />
            </Col>
            <Col xs={24} md={12}>
              <InputEdit
                field="full_name"
                title="Full Name"
                value={verificationsData?.full_name ?? '-'}
                edit={edit}
              />
            </Col>
            <Col xs={24} md={6}>
              <InputEdit
                field="country_code"
                title="Country Code"
                value={verificationsData?.country_code ?? '-'}
                edit={edit}
              />
            </Col>
            <Col xs={24} md={6}>
              <InputEdit
                field="phone"
                title="Phone"
                value={verificationsData?.phone ?? '-'}
                edit={edit}
                type="text"
              />
            </Col>
            <Col xs={24} md={24}>
              <InputEdit
                field="address"
                title="Address"
                value={verificationsData?.address ?? '-'}
                edit={edit}
              />
            </Col>
            <Col xs={24} md={6}>
              <InputEdit
                field="country"
                title="Country"
                value={verificationsData?.country ?? '-'}
                edit={edit}
              />
            </Col>
            <Col xs={24} md={6}>
              <InputEdit
                field="city"
                title="City"
                value={verificationsData?.city ?? '-'}
                edit={edit}
              />
            </Col>
            <Col xs={24} md={6}>
              <InputEdit
                field="zip"
                title="Zip"
                value={verificationsData?.zip ?? '-'}
                edit={edit}
                type="text"
              />
            </Col>
          </Row>
        </Form>
      </Card>
      <Gaps />
      <Row>
        <Col span={12}>
          <Card
            title={verificationsData?.type === 'passport' ? 'Passport Front' : 'ID Front'}
            className="kyc-img"
          >
            {idFront ? (
              <Image width={200} src={idFront?.data} style={{ alignSelf: 'center' }} />
            ) : (
              <Empty description="No ID document provided" />
            )}
          </Card>
        </Col>
        <Col span={12}>
          <Card
            title={verificationsData?.type === 'passport' ? 'Passport Back' : 'ID Back'}
            className="kyc-img"
          >
            {idBack ? (
              <Image width={200} src={idBack?.data} />
            ) : (
              <Empty description="No ID document provided" />
            )}
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Personal Picture" className="kyc-img">
            {personalPic ? (
              <Image width={200} src={personalPic?.data} />
            ) : (
              <Empty description="No Personal Picture provided" />
            )}
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Proof Document">
            {proofDocument ? (
              <>
                {`View document: `}
                <a href={`${proofDocument?.data}`} target="_blank">
                  {verificationsData?.proof_document}
                </a>
              </>
            ) : (
              <Empty description="No Proof Document provided" />
            )}
          </Card>
        </Col>
      </Row>
    </PageHeader>
  )
}

export default VerificationForm
