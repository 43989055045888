import { Key, FC, useEffect, useState } from 'react'
import { Table, Button, Empty, Row, Col, Dropdown, Menu, Tag } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../lib/store/reward_sent_table'
import TableFilter from '../../components/TableFilter'
import { serialize } from '../../network/request'
import RewardService, { IReward } from '../../network/services/reward'
// import CSVButton from '../../components/CSVButton'
import { DateTime } from 'luxon'
import { MenuInfo } from 'rc-menu/lib/interface'
import { useParams } from 'react-router-dom'

const RewardSentTable: FC<{ total: number; overridePage?: number }> = ({ total, overridePage }) => {
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState<Record<string, any>>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [visible, setVisible] = useState(false)
  const { user_id } = useParams()

  const { data: response } = useSWR(
    serialize(RewardService.getSent(user_id!), {
      page: overridePage ?? state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters
    })
  )

  const rows = RewardService.toRow(response)

  const rowSelection = {
    selectedRowKeys,
    onChange: (keySelected: Key[]) => {
      console.log(keySelected)
      setSelectedRowKeys(keySelected)
    }
  }

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: true
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      sorter: true
    },
    {
      title: 'To',
      dataIndex: 'user_id',
      key: 'user_id',
      sorter: true,
      render: (value: string | number, record: IReward) => {
        return record.user?.full_name
      }
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (text: string) => {
        return text ? DateTime.fromISO(text).toFormat('dd/MM/yyyy') : ''
      }
    }
  ]

  const filterColumns = [{ key: 'email', name: 'Email' }]
  // filterColumns.forEach((item) => {
  //   if (tableStore.state?.filters && Object.keys(tableStore.state.filters).includes(item.key)) {
  //     item.default_value = tableStore.state.filters[item.key]
  //     item.default = true
  //   }
  // })

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const handleMenuClick = (e: MenuInfo) => {
    if (e.key === 'delete') {
      setVisible(true)
    }
  }

  // const batchDeleteRows = async () => {
  //   try {
  //     for (const id of selectedRowKeys) {
  //       const { data } = await UserService.remove(id)
  //       if (data?.success === true) {
  //         message.success(`Delete user success`)
  //       } else {
  //         message.error(`Delete user ${id} failed`)
  //       }
  //       tableStore.state.refresh = Math.random()
  //     }

  //     setVisible(false)
  //   } catch (e) {
  //     console.log(e)
  //     // message.error({ content: e.message, className: 'message-error' })
  //   }
  // }

  // const DeleteModal = () => {
  //   return (
  //     <Modal
  //       title={`Remove ${selectedRowKeys.length} users`}
  //       visible={visible}
  //       onOk={() => {
  //         batchDeleteRows()
  //       }}
  //       onCancel={() => {
  //         setVisible(false)
  //       }}
  //       okText="Delete"
  //       cancelText="Cancel"
  //     >
  //       This can't be undone
  //     </Modal>
  //   )
  // }

  return (
    <>
      {/* <DeleteModal /> */}

      <Row>
        <Col span={6}>
          {selectedRowKeys?.length > 0 ? (
            <Dropdown
              overlay={
                <Menu onClick={handleMenuClick}>
                  <Menu.Item key="delete" disabled={true}>
                    Delete
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Button type="primary">
                More Actions <CaretDownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <></>
          )}
        </Col>
        <Col span={18}>
          <Row justify="end">
            <TableFilter
              dropdowns={[]}
              columns={filterColumns}
              hasDate={true}
              initial={state.filters}
              setCurrentFilters={setCurrentFilters}
            />

            {/* <CSVButton
              path={serialize(RewardService.getAll, {
                page: 1,
                limit: total,
                sort: state.sort ?? 'created_at:desc',
                ...state.filters
              })}
              filename="assets.csv"
              toRow={RewardService.toRow}
              toCsv={(rows) => {
                return rows.map((row) => {
                  return {
                    name: row.profile?.name,
                    email: row.email,
                    plan: row.subscription?.plan?.name,
                    children: row.children[0]?.name,
                    birthdate:
                      row.children[0]?.birthdate &&
                      DateTime.fromISO(row.children[0].birthdate).toFormat('dd/MM/yyyy'),
                    registered:
                      row.created_at && DateTime.fromISO(row.created_at).toFormat('dd/MM/yyyy')
                  }
                })
              }}
            /> */}
          </Row>
        </Col>
      </Row>

      {rows?.length > 0 ? (
        <Table<IReward>
          scroll={{ x: true }}
          size="small"
          columns={columns}
          dataSource={rows}
          expandable={{
            rowExpandable: (record) => false
          }}
          onChange={(pagination, filters, sorter) => {
            console.log(pagination, filters, sorter)

            if (_.isArray(sorter)) {
              // TODO
            } else {
              tableStore.state.sort =
                sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
                  ? `${sorter.columnKey ?? 'created_at'}:${
                      sorter.order === 'ascend' ? 'asc' : 'desc'
                    }`
                  : null
            }

            tableStore.state.currentPage = pagination.current!
            tableStore.state.pageSize = pagination.pageSize!
          }}
          pagination={{
            total,
            pageSize: state.pageSize,
            current: state.currentPage
          }}
          rowSelection={rowSelection}
        />
      ) : (
        <Empty description={'No record found'} />
      )}
    </>
  )
}

export default RewardSentTable
