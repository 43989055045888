import { DateTime } from 'luxon'
import client, { IPaginatedResponse } from '../request'

export interface IVerification {
  id: string | number
  full_name: string
  country: string
  city: string
  address: string
  zip: string
  email: string
  country_code: string
  phone: string
  id_type: string
  id_front: string
  id_back: string
  personal_pic: string
  proof_document: string
  status: string
  reason: string
  audited_at: DateTime
  user_id: string | number
  auditor_id: string | number
  txn_hash: string
  created_at: DateTime
  updated_at: DateTime
}

const getAll = `/verifications`

const get = (id: string | number) => {
  return `/verifications/${id}`
}

const getByUserId = (userId: string | number) => {
  return `/users/${userId}/verifications`
}

const create = (data: IVerification) => {
  return client.post('/verifications', data)
}

const update = (id: string | number, data: IVerification) => {
  return client.put(`/verifications/${id}`, data)
}

const approve = (id: string | number) => {
  return client.post(`/verifications/${id}/approve`)
}

const reject = (id: string | number, data: any) => {
  return client.post(`/verifications/${id}/reject`, data)
}

const toRow = (response: IPaginatedResponse<IVerification[]>) => {
  if (response?.data?.length > 0) {
    return response.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (response: IPaginatedResponse<IVerification[]>) => {
  return {
    total: response?.meta?.total ?? 0
  }
}

const VerificationService = {
  getAll,
  get,
  getByUserId,
  create,
  update,
  toRow,
  toPaginate,
  approve,
  reject
}

export default VerificationService
