import { DateTime } from 'luxon'
import client, { IPaginatedResponse } from '../request'

export interface IPool {
  id: string | number
  token_name: string
  token_type: string
  type: string
  blocked: boolean
  min_amount: number
  max_amount: number
  payout_day: number
  subscription_fee_type: number
  subscription_fee: number
  termination_fee_type: number
  termination_fee: number
  has_tenure_12: boolean
  has_tenure_24: boolean
  nft_rate_12: number
  nft_rate_24: number
  incentive_rate_12: number
  incentive_rate_24: number
  profit_rate_12: number
  profit_rate_24: number
  quota_limit: number
  allow_terminate: boolean
  allow_subscribe: boolean
  pool_size: number
  max_pool_size: number
  created_at: DateTime
  updated_at: DateTime
}

export interface IPoolOptional extends Partial<IPool> {}

const getAll = `/pools`

const get = (id: string | number) => {
  return `/pools/${id}`
}

const create = (data: IPool) => {
  return client.post('/pools', data)
}

const update = (id: string | number, data: IPoolOptional) => {
  return client.put(`/pools/${id}`, data)
}

const remove = (id: string | number) => {
  return client.delete(`/pools/${id}`)
}

const toRow = (response: IPaginatedResponse<IPool[]>) => {
  if (response?.data?.length > 0) {
    return response.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (response: IPaginatedResponse<IPool[]>) => {
  return {
    total: response?.meta?.total ?? 0
  }
}

const getStakeType = (input: string) => {
  switch (input) {
    case 'fix_earn':
      return 'Locked Staking'
    case 'flex_earn':
      return 'Flexible Earn'
    case 'flex_pool':
      return 'Flexible Pool Staking'
    default:
      return '-'
  }
}
const PoolService = {
  getAll,
  get,
  create,
  update,
  remove,
  toRow,
  toPaginate,
  getStakeType
}

export default PoolService
