import { Key, FC, useEffect, useState } from 'react'
import { Table, Button, Empty, Row, Col, Dropdown, Menu, Modal, message, Divider, Tag } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../lib/store/profit_table'
import TableFilter from '../../components/TableFilter'
import { serialize } from '../../network/request'
import ProfitService, { IProfit } from '../../network/services/profit'
// import CSVButton from '../../components/CSVButton'
import { DateTime } from 'luxon'
import { MenuInfo } from 'rc-menu/lib/interface'

const ProfitTable = () => {
  const navigate = useNavigate()
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState<Record<string, any>>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [checklist, setChecklist] = useState<Record<string, any>[]>([])
  const [visible, setVisible] = useState(false)
  const [confirmVisible, setConfirmVisible] = useState(false)
  const [selectedProfit, setSelectedProfit] = useState<IProfit | null>(null)
  const { ColumnGroup, Column } = Table

  const {
    data: response,
    error,
    mutate
  } = useSWR(
    serialize(ProfitService.getAll, {
      page: state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters
    })
  )
  const rows = ProfitService.toRow(response)

  const filterColumns = [{ key: 'email', name: 'Email' }]

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const batchDeleteRows = async () => {
    try {
      for (const id of selectedRowKeys) {
        const { data } = await ProfitService.remove(id)
        if (data?.success === true) {
          message.success(`Delete profit success`)
        } else {
          message.error(`Delete profit ${id} failed`)
        }
        tableStore.state.refresh = Math.random()
      }

      setVisible(false)
    } catch (e) {
      console.log(e)
      // message.error({ content: e.message, className: 'message-error' })
    }
  }

  const DeleteModal = () => {
    return (
      <Modal
        title={`Remove ${selectedRowKeys.length} profit(s)`}
        visible={visible}
        onOk={() => {
          batchDeleteRows()
        }}
        onCancel={() => {
          setVisible(false)
        }}
        okText="Delete"
        cancelText="Cancel"
      >
        This can't be undone
        {checklist?.length > 0 && (
          <ul key={`delete-profit-checklist`}>
            {checklist.map((item) => {
              return item.content
            })}
          </ul>
        )}
      </Modal>
    )
  }

  return (
    <>
      <DeleteModal />

      <Row>
        <Col span={24}>
          <Row justify="end">
            <TableFilter
              dropdowns={[
                {
                  key: 'published',
                  value: [
                    {
                      key: -1,
                      name: 'Show All'
                    },
                    {
                      key: 1,
                      name: 'Published'
                    },
                    {
                      key: 0,
                      name: 'Unpublished'
                    }
                  ]
                }
              ]}
              columns={filterColumns}
              hasDate={true}
              initial={state.filters}
              setCurrentFilters={setCurrentFilters}
            />

            {/* <CSVButton
              path={serialize(ProfitService.getAll, {
                page: 1,
                limit: total,
                sort: state.sort ?? 'created_at:desc',
                ...state.filters
              })}
              filename="assets.csv"
              toRow={ProfitService.toRow}
              toCsv={(rows) => {
                return rows.map((row) => {
                  return {
                    name: row.profile?.name,
                    email: row.email,
                    plan: row.subscription?.plan?.name,
                    children: row.children[0]?.name,
                    birthdate:
                      row.children[0]?.birthdate &&
                      DateTime.fromISO(row.children[0].birthdate).toFormat('dd/MM/yyyy'),
                    registered:
                      row.created_at && DateTime.fromISO(row.created_at).toFormat('dd/MM/yyyy')
                  }
                })
              }}
            /> */}
          </Row>
        </Col>
      </Row>

      <Modal
        title="Profit distribution confirmation"
        visible={confirmVisible}
        onOk={async () => {
          setConfirmVisible(false)
          if (selectedProfit) {
            const result: any = await ProfitService.distributeInterests({
              profit_id: selectedProfit.id
            })
            console.log(result)
            if (result.data?.success) {
              message.success('Distribution is completed, please view the report')
            } else {
              message.success('Distribution error')
            }
            tableStore.state.refresh = Math.random()
          } else {
            message.error('Profit not found')
          }
        }}
        onCancel={() => {
          setConfirmVisible(false)
        }}
        okText="Yes"
        cancelText="No"
      >
        {`Do you want to distribute ${
          selectedProfit?.published_at != null
            ? DateTime.fromISO(`${selectedProfit?.published_at}`).toFormat('dd/MM/yyyy')
            : '-'
        } profit of ${selectedProfit?.profit_rate}% to the ${
          selectedProfit?.pool?.token_type
        } pool?`}
      </Modal>

      <Table<IProfit>
        scroll={{ x: true }}
        size="small"
        dataSource={rows}
        expandable={{
          rowExpandable: (record) => false
        }}
        onChange={(pagination, filters, sorter) => {
          console.log(pagination, filters, sorter)

          if (_.isArray(sorter)) {
            // TODO
          } else {
            tableStore.state.sort =
              sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null
          }

          tableStore.state.currentPage = pagination.current!
          tableStore.state.pageSize = pagination.pageSize!
        }}
      >
        <ColumnGroup title="Pool">
          <Column title="Token Name" dataIndex={['pool', 'token_name']} key="token_name" />
          <Column title="Token Symbol" dataIndex={['pool', 'token_type']} key="token_type" />
          <Column title="Type" dataIndex={['pool', 'type']} key="type" />
        </ColumnGroup>
        <Column
          title="Profit Value"
          dataIndex="profit_value"
          key="profit_value"
          render={(text: string, record: IProfit) => {
            return `${text} ${record?.token_type}`
          }}
        />
        <Column title="Profit Rate %" dataIndex="profit_rate" key="profit_rate" />
        <Column
          title="Total Subscribe"
          dataIndex="total_subscribe"
          key="total_subscribe"
          render={(text: string, record: IProfit) => {
            return `${text} ${record?.token_type}`
          }}
        />
        <Column
          title="Published"
          dataIndex="published"
          key="publlished"
          render={(text: string) => {
            return <Tag color={text ? 'success' : 'error'}>{text ? 'Yes' : 'No'}</Tag>
          }}
        />
        <Column
          title="Profit Date"
          dataIndex="published_at"
          key="publlished_at"
          render={(text: string) => {
            return text ? DateTime.fromISO(text).toFormat('dd/MM/yyyy') : ''
          }}
        />
        <Column
          title="Created At"
          dataIndex="created_at"
          key="created_at"
          render={(text: string) => {
            return text ? DateTime.fromISO(text).toFormat('dd/MM/yyyy') : ''
          }}
        />
        <Column
          title="Distirbuted At"
          dataIndex="distributed_at"
          key="distributed_at"
          render={(text: string) => {
            return text ? DateTime.fromISO(text).toFormat('dd/MM/yyyy') : 'No distribution'
          }}
        />
        <Column
          title="Action"
          dataIndex="action"
          key="action"
          fixed={'right' as 'right'}
          render={(value: undefined, record: IProfit) => {
            // const action = record.published ? 'Unpublished' : 'Published'
            const distribute = record.distributed ? 'Distributed' : 'Distribute'

            return (
              <>
                {/* <Button
                    type="link"
                    onClick={async (e) => {
                      e.stopPropagation()
                      try {
                        await ProfitService.update(record.id!, {
                          ...record,
                          published: !record.published
                        })

                        message.success(
                          `${action} ${record.token_type} | ${DateTime.fromISO(
                            record.published_at.toString()
                          ).toFormat('dd/MM/yyyy')} successfully`
                        )
                        mutate()
                      } catch (error: any) {
                        console.log(error)
                        message.error(`${error?.message ?? 'Failed to' + action}`)
                      }
                    }}
                  >
                    {action}
                  </Button> */}
                <Link to={`${record.id}`} onClick={(e) => e.stopPropagation()}>
                  View
                </Link>
                <Divider type="vertical" />
                <Link to={`${record.id}/reports`} onClick={(e) => e.stopPropagation()}>
                  Reports
                </Link>
                {!record.simulated && (
                  <>
                    <Divider type="vertical" />
                    <Button
                      type="link"
                      onClick={async (e) => {
                        e.stopPropagation()
                        const result: any = await ProfitService.simulateInterests({
                          profit_id: record.id
                        })
                        console.log(result)
                        if (result.data?.success) {
                          message.success('Simulation is completed, please view the report')
                        } else {
                          message.success('Simulation error')
                        }
                        mutate()
                      }}
                    >
                      Simulate
                    </Button>
                  </>
                )}
                {!record.distributed && record?.simulated && (
                  <>
                    <Divider type="vertical" />
                    <Button
                      type="link"
                      onClick={(e) => {
                        e.stopPropagation()
                        if (record.distributed) {
                          message.error('Profit is already distributed')
                        } else {
                          setSelectedProfit(record)
                          setConfirmVisible(true)
                        }
                      }}
                    >
                      {distribute}
                    </Button>
                  </>
                )}
              </>
            )
          }}
        />
      </Table>
    </>
  )
}

export default ProfitTable
