import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Card,
  Col,
  Form,
  Button,
  Input,
  Row,
  message,
  FormInstance,
  Select,
  InputNumber
} from 'antd'

import Gaps from '../../components/Gaps'
import StakeService, { IStake } from '../../network/services/stake'
import moment from 'moment'

const StakeForm: FC<{
  form: FormInstance<IStake>
  initialValue?: IStake
  isLoading: boolean
  setIsLoading: (val: boolean) => void
  refresh: () => void
}> = ({ form, initialValue, isLoading = false, setIsLoading, refresh }) => {
  const navigate = useNavigate()
  const { id } = useParams()

  const handleSubmit = async (values: IStake) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      try {
        const result = await StakeService.update(initialValue.id!, values)
        message.success('Update successful')
        refresh()
      } catch (error) {
        console.log(error)
        // message.error('Update video error ' + error.message)
      }
    } else {
      let result
      try {
        result = await StakeService.create(values)
        message.success('Create successful')
      } catch (error) {
        console.log(error)
        // message.error('Create video error ' + error.message)
      } finally {
        if (result) {
          console.log(result)
          navigate('/dashboard/users')
        }
      }
    }

    setIsLoading(false)
  }

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
          <Form
            form={form!}
            name="new-stake"
            onFinish={handleSubmit}
            layout="horizontal"
            initialValues={{
              ...initialValue,
              started_at: moment(initialValue?.started_at).format('YYYY-MMM-DD HH:mm:ss'),
              deposited_at: moment(initialValue?.deposited_at).format('YYYY-MMM-DD HH:mm:ss'),
              next_distribute_at: moment(initialValue?.next_distribute_at).format(
                'YYYY-MMM-DD HH:mm:ss'
              ),
              terminated_at: moment(initialValue?.terminated_at).format('YYYY-MMM-DD HH:mm:ss'),
              matured_at: moment(initialValue?.matured_at).format('YYYY-MMM-DD HH:mm:ss'),
              email: initialValue?.user?.email
            }}
          >
            <Card bordered={false}>
              <Form.Item name="type" label="Type">
                <Select
                  disabled
                  options={[
                    { label: 'Locked Earn', value: 'fix_earn' },
                    { label: 'Flexible Earn', value: 'flex_earn' },
                    { label: 'Flexible Pool', value: 'flex_pool' }
                    // { label: 'PBC Pool', value: 'vxt' }
                  ]}
                />
              </Form.Item>
              <Form.Item name="email" label="Email">
                <Input readOnly />
              </Form.Item>
              <Form.Item name="tenure" label="Tenure">
                <Select
                  disabled
                  options={[
                    { label: '12 months', value: 12 },
                    { label: '24 months', value: 24 }
                  ]}
                />
              </Form.Item>

              <Form.Item name="remaining" label="Remaining (months)">
                <InputNumber readOnly />
              </Form.Item>

              <Form.Item
                name="principal_amount"
                label={`Principal Amount (${initialValue?.token_type})`}
              >
                <InputNumber readOnly />
              </Form.Item>

              <Form.Item
                name="total_subscribe"
                label={`Subscribe Amount (${initialValue?.token_type})`}
              >
                <InputNumber readOnly />
              </Form.Item>

              <Form.Item name="total_profit" label={`Total Profit (${initialValue?.token_type})`}>
                <InputNumber readOnly />
              </Form.Item>

              {/* <Form.Item name="total_vxt_profit" label="Total VXT Profit (VXT)">
                <InputNumber readOnly />
              </Form.Item> */}

              <Form.Item name="status" label="Status">
                <Select
                  disabled
                  options={[
                    { label: 'Active', value: 'active' },
                    { label: 'Terminated', value: 'terminated' },
                    { label: 'Matured', value: 'matured' }
                  ]}
                />
              </Form.Item>

              {initialValue?.status === 'terminated' && (
                <>
                  <Form.Item
                    name="termination_fee"
                    label={`Termination Fee (${initialValue?.token_type})`}
                  >
                    <InputNumber readOnly />
                  </Form.Item>
                  <Form.Item name="terminated_at" label="Termination Date">
                    <Input readOnly />
                  </Form.Item>
                </>
              )}

              {initialValue?.status === 'matured' && (
                <Form.Item
                  name="subscription_fee"
                  label={`Subscription Fee (${initialValue?.token_type})`}
                >
                  <InputNumber readOnly />
                </Form.Item>
              )}
              {/* 
              <Form.Item name="earn_vxt" label="Earning in VXT?">
                <Select
                  disabled
                  options={[
                    { label: 'No', value: false },
                    { label: 'Yes', value: true }
                  ]}
                />
              </Form.Item> */}
              {/* <Form.Item name="link_nft" label="Linking NFT?">
                <Select
                  disabled
                  options={[
                    { label: 'No', value: false },
                    { label: 'Yes', value: true }
                  ]}
                />
              </Form.Item> */}

              <Form.Item name="completed" label="Completed?">
                <Select
                  disabled
                  options={[
                    { label: 'No', value: false },
                    { label: 'Yes', value: true }
                  ]}
                />
              </Form.Item>

              <Form.Item name="deposited_at" label="Deposited At">
                <Input readOnly />
              </Form.Item>
              <Form.Item name="started_at" label="Started At">
                <Input readOnly />
              </Form.Item>
              <Form.Item name="next_distribute_at" label="Next Distribute">
                <Input readOnly />
              </Form.Item>
              <Form.Item name="matured_at" label="Matured At">
                <Input readOnly />
              </Form.Item>
            </Card>
          </Form>

          <Gaps />
        </Col>
      </Row>
    </>
  )
}

export default StakeForm
