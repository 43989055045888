import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Empty, Spin, PageHeader, Button } from 'antd'
import useSWR from 'swr'

import { serialize } from '../../network/request'
import ProfitService from '../../network/services/profit'
import ProfitForm from './ProfitForm'

const ProfitDetail = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [fieldsChange, setIsFieldsChange] = useState(false)

  const discardAction = (
    <Button
      key={'discard'}
      type="ghost"
      onClick={() => {
        navigate('/dashboard/finance/profits')
      }}
    >
      Discard
    </Button>
  )

  // use this to preload
  const {
    data: response,
    error,
    mutate
  } = useSWR(id !== 'new' ? serialize(ProfitService.get(id!)) : null)

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response && id !== 'new') {
    return (
      <PageHeader>
        <Spin />
      </PageHeader>
    )
  }

  const record = response?.data

  const saveAction =
    id === 'new' || (id !== 'new' && record?.distributed === false) ? (
      <Button
        key={'save'}
        type="primary"
        onClick={() => {
          form.submit()
        }}
        loading={isLoading}
      >
        {id === 'new' ? 'Create' : 'Update'}
      </Button>
    ) : (
      <></>
    )

  const refresh = () => {
    if (id != null) {
      if (id === 'new') {
      } else {
        mutate()
      }
    }
  }

  return (
    <PageHeader
      title={id === 'new' ? 'New Profit' : 'View Profit'}
      extra={[fieldsChange ? discardAction : null, saveAction]}
      onBack={() => navigate('/dashboard/finance/profits')}
    >
      <ProfitForm
        form={form}
        initialValue={record}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setIsFieldsChange={setIsFieldsChange}
        refresh={refresh}
      />
    </PageHeader>
  )
}

export default ProfitDetail
