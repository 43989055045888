import { Card, PageHeader, Empty, Spin } from 'antd'
import PoolTable from './PoolTable'

const PoolPage = () => {
  return (
    <PageHeader title="All Pools">
      <Card>
        <PoolTable />
      </Card>
    </PageHeader>
  )
}

export default PoolPage
