import { Key, FC, useEffect, useState } from 'react'
import { Table, Button, Empty, Row, Col, Dropdown, Menu, message } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../lib/store/asset_table'
import TableFilter from '../../components/TableFilter'
import { serialize } from '../../network/request'
import AssetService, { IAsset } from '../../network/services/asset'
// import CSVButton from '../../components/CSVButton'
import { DateTime } from 'luxon'
import { MenuInfo } from 'rc-menu/lib/interface'

const AssetTable = () => {
  const navigate = useNavigate()
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState<Record<string, any>>()

  const {
    data: response,
    error,
    mutate
  } = useSWR(
    serialize(AssetService.getAll, {
      page: state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters
    })
  )
  const rows = AssetService.toRow(response)

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true
    },
    {
      title: 'Network Type',
      dataIndex: 'network_type',
      key: 'network_type',
      sorter: true
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      sorter: true
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: true
    },
    // {
    //   title: 'Created At',
    //   dataIndex: 'created_at',
    //   key: 'created_at',
    //   sorter: true,
    //   render: (text: string) => {
    //     return text ? DateTime.fromISO(text).toFormat('dd/MM/yyyy') : ''
    //   }
    // },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right' as 'right',
      render: (value: undefined, record: IAsset) => {
        // const action = record.blocked ? 'Unblocked' : 'Blocked'
        return (
          <>
            <Link to={`${record.id}`}>View</Link>
            {/* <Divider type="vertical" />
            <Button
              type="link"
              onClick={async (e) => {
                e.stopPropagation()
                try {
                  await AssetService.update(record.id!, {
                    blocked: !record.blocked
                  })

                  message.success(
                    `${action} ${record.name} | ${record.network_type} | ${record.symbol} | ${record.type} successfully`
                  )
                  mutate()
                } catch (error) {
                  console.log(error)
                  message.error(`Failed to ${action}`)
                }
              }}
            >
              {action}
            </Button> */}
          </>
        )
      }
    }
  ]

  const filterColumns = [{ key: 'symbol', name: 'Symbol' }]

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const { total } = AssetService.toPaginate(response)

  return (
    <>
      {/* <DeleteModal /> */}

      <Row>
        <Col span={24}>
          <Row justify="end">
            <TableFilter
              // dropdowns={[]}
              columns={filterColumns}
              hasDate={false}
              initial={state.filters}
              setCurrentFilters={setCurrentFilters}
            />

            {/* <CSVButton
              path={serialize(AssetService.getAll, {
                page: 1,
                limit: total,
                sort: state.sort ?? 'created_at:desc',
                ...state.filters
              })}
              filename="assets.csv"
              toRow={AssetService.toRow}
              toCsv={(rows) => {
                return rows.map((row) => {
                  return {
                    name: row.profile?.name,
                    email: row.email,
                    plan: row.subscription?.plan?.name,
                    children: row.children[0]?.name,
                    birthdate:
                      row.children[0]?.birthdate &&
                      DateTime.fromISO(row.children[0].birthdate).toFormat('dd/MM/yyyy'),
                    registered:
                      row.created_at && DateTime.fromISO(row.created_at).toFormat('dd/MM/yyyy')
                  }
                })
              }}
            /> */}
          </Row>
        </Col>
      </Row>

      <Table<IAsset>
        scroll={{ x: true }}
        size="small"
        columns={columns}
        dataSource={rows}
        expandable={{
          rowExpandable: (record) => false
        }}
        onChange={(pagination, filters, sorter) => {
          console.log(pagination, filters, sorter)

          if (_.isArray(sorter)) {
            // TODO
          } else {
            tableStore.state.sort =
              sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null
          }

          tableStore.state.currentPage = pagination.current!
          tableStore.state.pageSize = pagination.pageSize!
        }}
        pagination={{
          total: total,
          pageSize: tableStore.state.pageSize,
          current: tableStore.state.currentPage
        }}
      />
    </>
  )
}

export default AssetTable
