import { DateTime } from 'luxon'
import client, { IPaginatedResponse } from '../request'
import { IUser } from './user'

export interface ITransaction {
  id: string | number
  title: string
  description: string
  txn_type: string
  token_type: string
  network_type: string
  status: string
  confirmed: boolean
  amount: number
  amount_in: number
  amount_out: number
  amount_str: number
  asset_id: string | number
  wallet_id: string | number
  user_id: string | number
  txn_hash: string
  created_at: DateTime
  updated_at: DateTime
  user: IUser
  txn_ref_no?: string
}

const getAll = `/transactions`

const get = (id: string | number) => {
  return `/transactions/${id}`
}

const getByUserId = (userId: string | number) => {
  return `/users/${userId}/transactions`
}

const create = (data: ITransaction) => {
  return client.post('/transactions', data)
}

const update = (id: string | number, data: ITransaction) => {
  return client.put(`/transactions/${id}`, data)
}

const toRow = (response: IPaginatedResponse<ITransaction[]>) => {
  if (response?.data?.length > 0) {
    return response.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (response: IPaginatedResponse<ITransaction[]>) => {
  return {
    total: response?.meta?.total ?? 0
  }
}

const TransactionService = {
  getAll,
  get,
  getByUserId,
  create,
  update,
  toRow,
  toPaginate
}

export default TransactionService
