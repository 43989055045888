import { DateTime } from 'luxon'
import client, { IPaginatedResponse } from '../request'
import { IPool } from './pool'
import { IProfit } from './profit'
import { IStake } from './stake'
import { ITransaction } from './transaction'
import { IUser } from './user'
import { IWallet } from './wallet'

export interface IInterest {
  id: string | number
  token_type: string
  distributed_at: DateTime
  amount: number
  profit_rate: number
  nft_amount: number
  profit_amount: number
  incentive_amount: number
  nft_rate: number
  incentive_rate: number
  stake_rate: number
  transaction_id: string | number
  wallet_id: string | number
  user_id: string | number
  stake_id: string | number
  profit_id: string | number
  created_at: DateTime
  updated_at: DateTime
  pool?: IPool
  wallet?: IWallet
  transaction?: ITransaction
  user?: IUser
  stake?: IStake
  profit?: IProfit
}

export interface IInterestOptional extends Partial<IInterest> {}

const getAll = `/interests`

const get = (id: string | number) => {
  return `/interests/${id}`
}

const getByProfitId = (profitId: string | number) => {
  return `/profits/${profitId}/interests`
}

const getByStakeId = (stakeId: string | number) => {
  return `/stakes/${stakeId}/interests`
}

const create = (data: IInterest) => {
  return client.post('/interests', data)
}

const update = (id: string | number, data: IInterestOptional) => {
  return client.put(`/interests/${id}`, data)
}

const remove = (id: string | number) => {
  return client.delete(`/interests/${id}`)
}

const toRow = (response: IPaginatedResponse<IInterest[]>) => {
  if (response?.data?.length > 0) {
    return response.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (response: IPaginatedResponse<IInterest[]>) => {
  return {
    total: response?.meta?.total ?? 0
  }
}

const InterestService = {
  getAll,
  get,
  getByProfitId,
  getByStakeId,
  create,
  update,
  remove,
  toRow,
  toPaginate
}

export default InterestService
