import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, PageHeader, Button, Empty, Spin } from 'antd'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'

import tableStore from '../../lib/store/withdraw_table'
import Service from '../../network/services/withdraw'
import { serialize } from '../../network/request'
import WithdrawTable from './WithdrawTable'

const WithdrawPage = () => {
  const navigate = useNavigate()
  const primaryAction = (
    <Button
      key="add"
      type="primary"
      onClick={() => {
        navigate('new')
      }}
    >
      Create
    </Button>
  )

  return (
    <PageHeader title="All Withdraws" extra={[]}>
      <Card>
        <WithdrawTable />
      </Card>
    </PageHeader>
  )
}

export default WithdrawPage
