import React from 'react'
import { useParams } from 'react-router-dom'
import { Card, PageHeader, Empty, Spin } from 'antd'
import TransactionTable from './TransactionTable'

const TransactionPage = () => {
  return (
    <PageHeader title={'All Transations'}>
      <Card>
        <TransactionTable />
      </Card>
    </PageHeader>
  )
}

export default TransactionPage
