import { proxy, subscribe } from 'valtio'
import _ from 'lodash'

export interface ITableStore {
  filters: Record<string, any> | null
  sort: string | null
  currentPage: number
  pageSize: number
  prevUrl: string
  refresh: number
}

const initialTableAttr: ITableStore = {
  filters: null,
  sort: null,
  currentPage: 1,
  pageSize: 10,
  prevUrl: '',
  refresh: 0
}

const storageName = 'verification_table'
let storedTableAttr = { ...initialTableAttr }
if (window.localStorage) {
  if (localStorage.getItem(storageName) != null) {
    let result = JSON.parse(localStorage.getItem(storageName)!)
    if (!_.isEmpty(result)) {
      console.log(result)
      storedTableAttr = result
    }
  }
}

const tableAttr = proxy({
  state: storedTableAttr,
  reset() {
    tableAttr.state = { ...initialTableAttr }
    localStorage.setItem(storageName, JSON.stringify(initialTableAttr))
  }
})

subscribe(tableAttr, () => {
  console.log('state.arr has changed to', tableAttr.state)
  localStorage.setItem(storageName, JSON.stringify(tableAttr.state))
})

export default tableAttr
