import { Card, PageHeader } from 'antd'
import Gaps from 'components/Gaps'
import PendingStatisticsPage from './dashboard/pending-statistics'
import Statistics from './dashboard/statistics'

const Dashboard = () => {
  return (
    <PageHeader title="Dashboard">
      <Card>Hello admin, Welcome back</Card>
      <Gaps />
      <Statistics />
      <Gaps />
      <PendingStatisticsPage />
    </PageHeader>
  )
}

export default Dashboard
