import client, { IPostResponse } from '../request'
import { IUser } from './user'

export interface IRegister {
  email?: string
  username?: string
  password: string
  auth_code: string
}

export interface ILogin {
  email?: string
  username?: string
  password: string
}

export interface ILoginResponse {
  jwt: {
    expires_at: string
    token: string
    type: string
  }
  user: IUser
}

export interface IEmail {
  email: string
}

export interface IChangePassword {
  password: string
  new_password: string
}

export interface IVerifyAuth {
  email: string
  code: string
}

export interface IForgotPassword {
  email: string
  password: string
  auth_code: string
}

const register = (data: IRegister) => {
  return client.post('/auth/register', data)
}

const login = (data: ILogin) => {
  return client.post<IPostResponse<ILoginResponse>>('/auth/login', data)
}

const logout = () => {
  return client.post('/auth/logout')
}

// user change password
const changePassword = (data: IChangePassword) => {
  return client.post(`/auth/password/change`, data)
}

const resetPassword = (data: IEmail) => {
  return client.post(`/auth/password/reset`, data)
}

const checkEmail = (data: IEmail) => {
  return client.post('/users/available', data)
}

const sendAuth = (data: IEmail) => {
  return client.post('/auth/code', data)
}

const verifyAuth = (data: IVerifyAuth) => {
  return client.post('/auth/code/verify', data)
}

const forgotPassword = (data: IForgotPassword) => {
  return client.post('/auth/forgot-password', data)
}

const sendEmailConfirmation = (data: IEmail) => {
  return client.post(`/auth/resend`, data)
}

const AuthService = {
  login,
  logout,
  changePassword,
  register,
  resetPassword,
  forgotPassword,
  checkEmail,
  sendAuth,
  verifyAuth,
  sendEmailConfirmation
}

export default AuthService
