import { DateTime } from 'luxon'
import client, { IPaginatedResponse } from '../request'
import { IUser } from './user'

export interface ITicket {
  id: string | number
  type: number
  user_id: string | number
  created_at: DateTime
  updated_at: DateTime
  published_at: DateTime
  user: IUser
}

const getAll = `/tickets`

const get = (id: string | number) => {
  return `/tickets/${id}`
}

const create = (data: ITicket) => {
  return client.post('/tickets', data)
}

const update = (id: string | number, data: ITicket) => {
  return client.put(`/tickets/${id}`, data)
}

const toRow = (response: IPaginatedResponse<ITicket[]>) => {
  if (response?.data?.length > 0) {
    return response.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (response: IPaginatedResponse<ITicket[]>) => {
  return {
    total: response?.meta?.total ?? 0
  }
}

const Ticketservice = {
  getAll,
  get,
  create,
  update,
  toRow,
  toPaginate
}

export default Ticketservice
