import React from 'react'
import { useParams } from 'react-router-dom'
import { Card, PageHeader, Empty, Spin } from 'antd'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'

import tableStore from '../../lib/store/reward_received_table'
import RewardReceivedService from '../../network/services/reward'
import { serialize } from '../../network/request'
import RewardReceivedTable from './RewardReceivedTable'

const RewardReceivedPage = () => {
  return (
    <PageHeader title="All Rewards Received">
      <Card>
        <RewardReceivedTableWrapper />
      </Card>
    </PageHeader>
  )
}

const RewardReceivedTableWrapper = () => {
  const { state } = useSnapshot(tableStore)
  const { user_id } = useParams()

  // use this to preload
  const { data: response, error } = useSWR(
    serialize(RewardReceivedService.getReceived(user_id!), {
      page: 1,
      limit: state.pageSize,
      ...state.filters
    })
  )

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return <Spin />
  }

  const { total } = RewardReceivedService.toPaginate(response)

  return (
    <>
      <RewardReceivedTable total={total} />
      {/* preload next page */}
      {state.currentPage * state.pageSize < total && (
        <div style={{ display: 'none' }}>
          <RewardReceivedTable total={total} overridePage={state.currentPage + 1} />
        </div>
      )}
    </>
  )
}

export default RewardReceivedPage
