import { DateTime } from 'luxon'
import client, { IPaginatedResponse } from '../request'
import { IUser } from './user'

export interface IReward {
  id: string | number
  type: string
  amount: number
  user_id: string | number
  from_user_id: string | number | IUser
  created_at: DateTime
  updated_at: DateTime
  user?: IUser
  from_user?: IUser
}

const getAll = `/rewards`

const get = (id: string | number) => {
  return `/rewards/${id}`
}

const getByUserId = (userId: string | number) => {
  return `/users${userId}/rewards`
}

const getReceived = (id: string | number) => {
  return `/rewards-received/${id}`
}

const getSent = (id: string | number) => {
  return `/rewards-sent/${id}`
}

const create = (data: IReward) => {
  return client.post('/rewards', data)
}

const update = (id: string | number, data: IReward) => {
  return client.put(`/rewards/${id}`, data)
}

const toRow = (response: IPaginatedResponse<IReward[]>) => {
  if (response?.data?.length > 0) {
    return response.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (response: IPaginatedResponse<IReward[]>) => {
  return {
    total: response?.meta?.total ?? 0
  }
}

const RewardService = {
  getAll,
  get,
  getByUserId,
  getReceived,
  getSent,
  create,
  update,
  toRow,
  toPaginate
}

export default RewardService
