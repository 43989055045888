import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, PageHeader, Button, Empty, Spin } from 'antd'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'

import tableStore from '../../lib/store/announcement_table'
import AnnouncementService from '../../network/services/announcement'
import { serialize } from '../../network/request'
import AnnouncementTable from './AnnouncementTable'

const AnnouncementPage = () => {
  const navigate = useNavigate()
  const primaryAction = (
    <Button
      key="add"
      type="primary"
      onClick={() => {
        navigate('/dashboard/announcements/new')
      }}
    >
      Create Announcement
    </Button>
  )

  return (
    <PageHeader title="Announcements" extra={[primaryAction]}>
      <Card>
        <AnnouncementTable />
      </Card>
    </PageHeader>
  )
}

export default AnnouncementPage
