import { Key, FC, useEffect, useState } from 'react'
import { Table, Button, Empty, Row, Col, Dropdown, Menu, Tag, Divider } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../lib/store/stake_table'
import TableFilter from '../../components/TableFilter'
import { serialize } from '../../network/request'
import StakeService, { IStake } from '../../network/services/stake'
// import CSVButton from '../../components/CSVButton'
import { DateTime } from 'luxon'
import { MenuInfo } from 'rc-menu/lib/interface'
import PoolService from 'network/services/pool'

const StakeTable: FC<{ userId?: number | string; hideFilter?: boolean }> = ({
  userId = null,
  hideFilter = false
}) => {
  const navigate = useNavigate()
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState<Record<string, any>>()

  const { data: response } = useSWR(
    serialize(StakeService.getAll, {
      page: state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters,
      user_id: userId
    })
  )

  const rows = StakeService.toRow(response)

  const columns = [
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user_id',
      sorter: true,
      render: (value: any, row: IStake) => {
        return `${value?.email}`
      }
    },
    {
      title: 'Pool',
      dataIndex: 'token_type',
      key: 'token_type',
      sorter: true,
      render: (value: string, row: IStake) => {
        return `${row.token_name} (${value})`
      }
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      render: (value: string, row: IStake) => {
        return PoolService.getStakeType(value)
      }
    },
    {
      title: 'Tenure',
      dataIndex: 'tenure',
      key: 'tenure',
      sorter: true
    },
    {
      title: 'Remaining',
      dataIndex: 'remaining',
      key: 'remaining',
      sorter: false
    },
    {
      title: 'Subscribe Amount',
      dataIndex: 'total_subscribe',
      key: 'total_subscribe',
      sorter: true,
      render: (value: string, row: IStake) => {
        return `${value} ${row.token_type}`
      }
    },
    {
      title: 'Base Profit',
      dataIndex: 'total_profit',
      key: 'total_profit',
      sorter: true,
      render: (value: string, row: IStake) => {
        return `${value} ${row.payout_token}`
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true
    },
    {
      title: 'Completed?',
      dataIndex: 'completed',
      key: 'completed',
      sorter: true,
      render: (value: boolean) => {
        return <Tag color={value ? 'success' : 'error'}>{value ? 'True' : 'False'}</Tag>
      }
    },
    // {
    //   title: 'Linking Nft? ',
    //   dataIndex: 'link_nft',
    //   key: 'link_nft',
    //   sorter: true,
    //   render: (value: boolean) => {
    //     return <Tag color={value ? 'success' : 'error'}>{value ? 'True' : 'False'}</Tag>
    //   }
    // },
    {
      title: 'Deposited At',
      dataIndex: 'deposited_at',
      key: 'deposited_at',
      sorter: true,
      render: (text: string) => {
        return text ? DateTime.fromISO(text).toFormat('dd/MM/yyyy') : ''
      }
    },
    {
      title: 'Started At',
      dataIndex: 'started_at',
      key: 'started_at',
      sorter: true,
      render: (text: string) => {
        return text ? DateTime.fromISO(text).toFormat('dd/MM/yyyy') : ''
      }
    },
    {
      title: 'Next Distribute',
      dataIndex: 'next_distribute_at',
      key: 'next_distribute_at',
      sorter: true,
      render: (text: string) => {
        return text ? DateTime.fromISO(text).toFormat('dd/MM/yyyy') : ''
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right' as 'right',
      render: (value: undefined, record: IStake) => {
        return (
          <>
            <Link to={`/dashboard/stakes/${record.id}`}>View</Link>
            <Divider type="vertical" />
            <Link to={`/dashboard/stakes/${record.id}/transactions`}>Transactions</Link>
            <Divider type="vertical" />
            <Link to={`/dashboard/stakes/${record.id}/interests`}>Interests</Link>
          </>
        )
      }
    }
  ]

  const filterColumns = [{ key: 'email', name: 'Email' }]

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  //active,matured,terminated
  const filterStatus = {
    label: 'Status',
    key: 'status',
    value: [
      { key: '', name: 'All Status' },
      { key: 'active', name: 'Active' },
      { key: 'matured', name: 'Matured' },
      { key: 'terminated', name: 'Terminated' }
    ],
    default: ''
  }

  const { total } = StakeService.toPaginate(response)

  return (
    <>
      {/* <DeleteModal /> */}

      <Row>
        <Col span={24}>
          <Row justify="end">
            {!hideFilter && (
              <TableFilter
                dropdowns={[filterStatus]}
                columns={filterColumns}
                hasDate={true}
                initial={state.filters}
                setCurrentFilters={setCurrentFilters}
              />
            )}

            {/* <CSVButton
              path={serialize(StakeService.getAll, {
                page: 1,
                limit: total,
                sort: state.sort ?? 'created_at:desc',
                ...state.filters
              })}
              filename="assets.csv"
              toRow={StakeService.toRow}
              toCsv={(rows) => {
                return rows.map((row) => {
                  return {
                    name: row.profile?.name,
                    email: row.email,
                    plan: row.subscription?.plan?.name,
                    children: row.children[0]?.name,
                    birthdate:
                      row.children[0]?.birthdate &&
                      DateTime.fromISO(row.children[0].birthdate).toFormat('dd/MM/yyyy'),
                    registered:
                      row.created_at && DateTime.fromISO(row.created_at).toFormat('dd/MM/yyyy')
                  }
                })
              }}
            /> */}
          </Row>
        </Col>
      </Row>

      <Table<IStake>
        scroll={{ x: true }}
        size="small"
        columns={columns}
        dataSource={rows}
        expandable={{
          rowExpandable: (record) => false
        }}
        onChange={(pagination, filters, sorter) => {
          console.log(pagination, filters, sorter)

          if (_.isArray(sorter)) {
            // TODO
          } else {
            tableStore.state.sort =
              sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null
          }
          tableStore.state.currentPage = pagination.current!
          tableStore.state.pageSize = pagination.pageSize!
        }}
        pagination={{
          total: total,
          pageSize: tableStore.state.pageSize,
          current: tableStore.state.currentPage
        }}
      />
    </>
  )
}

export default StakeTable
