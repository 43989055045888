import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, Col, Form, Button, Input, Row, message, FormInstance, Select } from 'antd'

import Gaps from '../../components/Gaps'
import UserService, { IAdminChangePassword, IUser } from '../../network/services/user'

const UserForm: FC<{
  form: FormInstance<IUser>
  initialValue?: IUser
  isLoading: boolean
  setIsLoading: (val: boolean) => void
  setIsFieldsChange: (val: boolean) => void
  refresh: () => void
}> = ({ form, initialValue, isLoading = false, setIsLoading, setIsFieldsChange, refresh }) => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [passwordForm] = Form.useForm()

  const submitPassword = async (values: IAdminChangePassword) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const result = await UserService.changePassword({ password: values.password, user: id! })
      if (result.data.success) {
        message.success('Password changed successfully')
        passwordForm.resetFields()
      }
    } catch (error) {
      console.log(error)
      // message.error('Password change error ' + error.message)
    }

    setIsLoading(false)
  }

  const handleSubmit = async (values: IUser) => {
    if (isLoading) return
    setIsLoading(true)
    let result: any
    if (initialValue?.id) {
      try {
        result = await UserService.update(initialValue.id!, values)
        message.success('Update successful')
        setIsLoading(false)
        refresh()
      } catch (error) {
        console.log(error)
        // message.error('Update video error ' + error.message)
        setIsLoading(false)
      }
    } else {
      try {
        result = await UserService.create(values)
        message.success('Create successful')
      } catch (error) {
        console.log(error)
        if (typeof error === 'object') {
          const errorMessage = JSON.parse(JSON.stringify(error))
          message.error(errorMessage?.message)
        }
        setIsLoading(false)
      }
    }
    if (result?.data?.success) {
      setIsLoading(false)
      setIsFieldsChange(false)
      refresh()
    }
  }

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
          <Form
            form={form!}
            name="new-user"
            onFinish={handleSubmit}
            layout="horizontal"
            initialValues={{
              ...initialValue
            }}
            onFieldsChange={() => setIsFieldsChange(true)}
          >
            <Card bordered={false}>
              <Form.Item name="full_name" label="Name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item name="username" label="Username" rules={[{ required: true }]} hidden>
                <Input />
              </Form.Item>

              <Form.Item name="email" label="Email" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Row>
                <Col span={6}>
                  <Form.Item name="country_code" label="Country Code">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <Form.Item name="phone" label="Phone">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item name="referral_code" label="Referral Code">
                <Input readOnly bordered={false} />
              </Form.Item>

              <Form.Item name="phone_verified" label="Phone verified" hidden>
                <Select
                  options={[
                    { label: 'True', value: true },
                    { label: 'False', value: false }
                  ]}
                />
              </Form.Item>

              <Form.Item name="provider" label="Provider" hidden>
                <Input />
              </Form.Item>

              <Form.Item name="confirmed" label="Email Verified?" rules={[{ required: true }]}>
                <Select
                  options={[
                    { label: 'True', value: true },
                    { label: 'False', value: false }
                  ]}
                  disabled
                />
              </Form.Item>

              <Form.Item name="verified" label="KYC verified?" rules={[{ required: true }]}>
                <Select
                  options={[
                    { label: 'True', value: true },
                    { label: 'False', value: false }
                  ]}
                  disabled
                />
              </Form.Item>

              <Form.Item
                name="blocked"
                label="Blocked user from login?"
                rules={[{ required: true }]}
              >
                <Select
                  options={[
                    { label: 'True', value: true },
                    { label: 'False', value: false }
                  ]}
                />
              </Form.Item>
            </Card>
          </Form>

          <Gaps />
        </Col>

        {initialValue?.id != null && (
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
            <Form
              form={passwordForm}
              name="change-password"
              onFinish={submitPassword}
              layout="vertical"
            >
              <Card
                bordered={false}
                title="Change Password"
                extra={
                  <Button
                    onClick={() => {
                      passwordForm.submit()
                    }}
                  >
                    Change
                  </Button>
                }
              >
                <Form.Item
                  name="password"
                  label="New Password"
                  rules={[{ required: true, message: 'Please insert the new password' }]}
                >
                  <Input />
                </Form.Item>
              </Card>
            </Form>
          </Col>
        )}
      </Row>
    </>
  )
}

export default UserForm
