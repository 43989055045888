import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Card,
  Col,
  Form,
  Input,
  Row,
  message,
  FormInstance,
  Select,
  DatePicker,
  Image,
  InputNumber
} from 'antd'
import Gaps from '../../components/Gaps'
import WithdrawService, { IWithdraw } from '../../network/services/withdraw'
import moment from 'moment'

const WithdrawForm: FC<{
  form: FormInstance<IWithdraw>
  initialValue?: IWithdraw
  isLoading: boolean
  setIsLoading: (val: boolean) => void
  setIsFieldsChange: (val: boolean) => void
  refresh: () => void
}> = ({ form, initialValue, isLoading = false, setIsLoading, setIsFieldsChange, refresh }) => {
  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    if (initialValue != null) {
      form.resetFields()
    }
  }, [initialValue])

  const handleSubmit = async (values: IWithdraw) => {
    if (isLoading) return
    setIsLoading(true)

    let result: any
    if (initialValue?.id) {
      try {
        result = await WithdrawService.update(initialValue.id!, values)
        message.success('Update successful')
        refresh()
      } catch (error) {
        console.log(error)
        if (typeof error === 'object') {
          const errorMessage = JSON.parse(JSON.stringify(error))
          message.error(errorMessage?.message)
        }
        setIsLoading(false)
      }
      if (result?.data?.success) {
        setIsLoading(false)
        setIsFieldsChange(false)
        refresh()
      }
    } else {
      try {
        result = await WithdrawService.create(values)
        message.success('Create successful')
      } catch (error) {
        console.log(error)
        if (typeof error === 'object') {
          const errorMessage = JSON.parse(JSON.stringify(error))
          message.error(errorMessage?.message)
        }
        setIsLoading(false)
      }
      if (result?.data?.success) {
        navigate('/dashboard/withdraws')
      }
    }
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }}>
          <Form
            form={form!}
            name="new-network"
            onFinish={handleSubmit}
            layout="horizontal"
            initialValues={{
              ...initialValue,
              expired_at:
                initialValue?.expired_at != null ? moment(initialValue?.expired_at) : null,
              created_at: initialValue?.created_at != null ? moment(initialValue?.created_at) : null
            }}
            onFieldsChange={() => setIsFieldsChange(true)}
          >
            <Card bordered={false}>
              <Form.Item name="status" label="Status" rules={[{ required: true }]}>
                <Select
                  disabled
                  options={[
                    { label: 'Pending', value: 'pending' },
                    { label: 'Completed', value: 'completed' },
                    { label: 'Rejected', value: 'rejected' }
                  ]}
                />
              </Form.Item>

              <Form.Item name="created_at" label="Requested Date" rules={[{ required: true }]}>
                <DatePicker showTime disabled />
              </Form.Item>

              <Form.Item name="recipient_addr" label="Wallet Address" rules={[{ required: true }]}>
                <Input readOnly bordered={false} />
              </Form.Item>

              <Form.Item
                name="amount_requested"
                label={`Amount Requested (${initialValue?.token_type})`}
                rules={[{ required: true }]}
              >
                <InputNumber readOnly bordered={false} />
              </Form.Item>

              <Form.Item
                name="network_fee"
                label={`Withdawal Fee (${initialValue?.token_type})`}
                rules={[{ required: true }]}
              >
                <InputNumber readOnly bordered={false} />
              </Form.Item>

              <Form.Item name="txn_number" label="Txn Ref. No." rules={[{ required: true }]}>
                <Input readOnly bordered={false} />
              </Form.Item>

              <Form.Item name="user_id" label="Requestor">
                <Select
                  options={[{ label: initialValue?.user?.email, value: initialValue?.user_id }]}
                  disabled
                />
              </Form.Item>
            </Card>
          </Form>
          <Gaps />
        </Col>
      </Row>
    </>
  )
}

export default WithdrawForm
