import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, PageHeader, Button, Empty, Spin } from 'antd'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'

import tableStore from '../../lib/store/wallet_table'
import WalletService from '../../network/services/wallet'
import { serialize } from '../../network/request'
import WalletTable from './WalletTable'

const WalletPage = () => {
  const navigate = useNavigate()
  // const primaryAction = (
  //   <Button
  //     key="add"
  //     type="primary"
  //     onClick={() => {
  //       navigate('wallets/new')
  //     }}
  //   >
  //     Create Wallet
  //   </Button>
  // )

  return (
    <PageHeader title="User Assets">
      <Card>
        <WalletTable />
      </Card>
    </PageHeader>
  )
}

export default WalletPage
