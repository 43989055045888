import { DateTime } from 'luxon'
import client, { IPaginatedResponse } from '../request'

export interface ICurrency {
  id: string | number
  name: string
  code: string
  blocked: boolean
  exchange_rate: number
  decimal: number
  created_at: DateTime
  updated_at: DateTime
}

export interface ICurrencyOptional extends Partial<ICurrency> {}

const getAll = `/currencies`

const get = (id: string | number) => {
  return `/currencies/${id}`
}

const create = (data: ICurrency) => {
  return client.post('/currencies', data)
}

const update = (id: string | number, data: ICurrencyOptional) => {
  return client.put(`/currencies/${id}`, data)
}

const toRow = (response: IPaginatedResponse<ICurrency[]>) => {
  if (response?.data?.length > 0) {
    return response.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (response: IPaginatedResponse<ICurrency[]>) => {
  return {
    total: response?.meta?.total ?? 0
  }
}

const CurrencyService = {
  getAll,
  get,
  create,
  update,
  toRow,
  toPaginate
}

export default CurrencyService
