import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, PageHeader, Button, Empty, Spin } from 'antd'
import ProfitTable from './ProfitTable'

const ProfitPage = () => {
  const navigate = useNavigate()
  const primaryAction = (
    <Button
      key="add"
      type="primary"
      onClick={() => {
        navigate('new')
      }}
    >
      Create Profit
    </Button>
  )

  return (
    <PageHeader title="All Profits" extra={[primaryAction]}>
      <Card>
        <ProfitTable />
      </Card>
    </PageHeader>
  )
}

export default ProfitPage
