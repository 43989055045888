import { Key, FC, useEffect, useState } from 'react'
import { Table, Button, Empty, Row, Col, Dropdown, Menu, Tag, message } from 'antd'
import { CaretDownOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../lib/store/network_table'
import TableFilter from '../../components/TableFilter'
import { serialize } from '../../network/request'
import NetworkService, { INetwork } from '../../network/services/network'
// import CSVButton from '../../components/CSVButton'
import { DateTime } from 'luxon'
import { MenuInfo } from 'rc-menu/lib/interface'

const NetworkTable: FC<{ total: number; overridePage?: number }> = ({ total, overridePage }) => {
  const navigate = useNavigate()
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState<Record<string, any>>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [visible, setVisible] = useState(false)

  const {
    data: response,
    error,
    mutate
  } = useSWR(
    serialize(NetworkService.getAll, {
      page: overridePage ?? state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters
    })
  )
  const rows = NetworkService.toRow(response)

  const rowSelection = {
    selectedRowKeys,
    onChange: (keySelected: Key[]) => {
      console.log(keySelected)
      setSelectedRowKeys(keySelected)
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true
    },
    // {
    //   title: 'Created At',
    //   dataIndex: 'created_at',
    //   key: 'created_at',
    //   sorter: true,
    //   render: (text: string) => {
    //     return text ? DateTime.fromISO(text).toFormat('dd/MM/yyyy') : ''
    //   }
    // },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (value: undefined, record: INetwork) => {
        const action = record.blocked ? 'Unblocked' : 'Blocked'

        return (
          <Button
            type="link"
            onClick={async (e) => {
              e.stopPropagation()
              try {
                await NetworkService.update(record.id!, {
                  blocked: !record.blocked
                })

                message.success(`${action} ${record.name} successfully`)
                mutate()
              } catch (error) {
                console.log(error)
                message.error(`Failed to ${action}`)
              }
            }}
          >
            {action}
          </Button>
        )
      }
    }
  ]

  const filterColumns = [{ key: 'email', name: 'Email' }]
  // filterColumns.forEach((item) => {
  //   if (tableStore.state?.filters && Object.keys(tableStore.state.filters).includes(item.key)) {
  //     item.default_value = tableStore.state.filters[item.key]
  //     item.default = true
  //   }
  // })

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const handleMenuClick = (e: MenuInfo) => {
    if (e.key === 'delete') {
      setVisible(true)
    }
  }

  // const batchDeleteRows = async () => {
  //   try {
  //     for (const id of selectedRowKeys) {
  //       const { data } = await UserService.remove(id)
  //       if (data?.success === true) {
  //         message.success(`Delete user success`)
  //       } else {
  //         message.error(`Delete user ${id} failed`)
  //       }
  //       tableStore.state.refresh = Math.random()
  //     }

  //     setVisible(false)
  //   } catch (e) {
  //     console.log(e)
  //     // message.error({ content: e.message, className: 'message-error' })
  //   }
  // }

  // const DeleteModal = () => {
  //   return (
  //     <Modal
  //       title={`Remove ${selectedRowKeys.length} users`}
  //       visible={visible}
  //       onOk={() => {
  //         batchDeleteRows()
  //       }}
  //       onCancel={() => {
  //         setVisible(false)
  //       }}
  //       okText="Delete"
  //       cancelText="Cancel"
  //     >
  //       This can't be undone
  //     </Modal>
  //   )
  // }

  return (
    <>
      {/* <DeleteModal /> */}

      <Row>
        <Col span={6}>
          {selectedRowKeys?.length > 0 ? (
            <Dropdown
              overlay={
                <Menu onClick={handleMenuClick}>
                  <Menu.Item key="delete" disabled={true}>
                    Delete
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Button type="primary">
                More Actions <CaretDownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <></>
          )}
        </Col>
        <Col span={18}>
          <Row justify="end">
            <TableFilter
              // dropdowns={[]}
              columns={filterColumns}
              hasDate={true}
              initial={state.filters}
              setCurrentFilters={setCurrentFilters}
            />

            {/* <CSVButton
              path={serialize(NetworkService.getAll, {
                page: 1,
                limit: total,
                sort: state.sort ?? 'created_at:desc',
                ...state.filters
              })}
              filename="assets.csv"
              toRow={NetworkService.toRow}
              toCsv={(rows) => {
                return rows.map((row) => {
                  return {
                    name: row.profile?.name,
                    email: row.email,
                    plan: row.subscription?.plan?.name,
                    children: row.children[0]?.name,
                    birthdate:
                      row.children[0]?.birthdate &&
                      DateTime.fromISO(row.children[0].birthdate).toFormat('dd/MM/yyyy'),
                    registered:
                      row.created_at && DateTime.fromISO(row.created_at).toFormat('dd/MM/yyyy')
                  }
                })
              }}
            /> */}
          </Row>
        </Col>
      </Row>

      {rows?.length > 0 ? (
        <Table<INetwork>
          scroll={{ x: true }}
          size="small"
          columns={columns}
          dataSource={rows}
          expandable={{
            rowExpandable: (record) => false
          }}
          onChange={(pagination, filters, sorter) => {
            console.log(pagination, filters, sorter)

            if (_.isArray(sorter)) {
              // TODO
            } else {
              tableStore.state.sort =
                sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
                  ? `${sorter.columnKey ?? 'created_at'}:${
                      sorter.order === 'ascend' ? 'asc' : 'desc'
                    }`
                  : null
            }

            tableStore.state.currentPage = pagination.current!
            tableStore.state.pageSize = pagination.pageSize!
          }}
          pagination={{
            total,
            pageSize: state.pageSize,
            current: state.currentPage
          }}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: (event) => {
          //       if (selectedRowKeys?.length > 0) {
          //         if (selectedRowKeys.includes(record.id)) {
          //           const filterKeys = _.filter([...selectedRowKeys], function (o) {
          //             return o !== record.id
          //           })
          //           setSelectedRowKeys([...filterKeys])
          //         } else {
          //           setSelectedRowKeys([...selectedRowKeys, record.id])
          //         }
          //       } else {
          //         navigate(`${record.id}`)
          //       }
          //     } // click row
          //   }
          // }}
          rowSelection={rowSelection}
        />
      ) : (
        <Empty description={'No record found'} />
      )}
    </>
  )
}

export default NetworkTable
