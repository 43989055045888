import { FC, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Empty, Spin, PageHeader, Button, message, Modal, Input, InputNumber } from 'antd'
import useSWR from 'swr'

import { serialize } from '../../network/request'
import WithdrawService, { IWithdraw } from '../../network/services/withdraw'
import WithdrawForm from './WithdrawForm'
import Gaps from 'components/Gaps'
import { useForm } from 'antd/lib/form/Form'

const Detail = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [fieldsChange, setIsFieldsChange] = useState(false)
  const [approveModalVisible, setApproveModalVisible] = useState(false)
  const [rejectModalVisible, setRejectModalVisible] = useState(false)

  const saveAction = (
    <Button
      key={'save'}
      type="primary"
      onClick={() => {
        form.submit()
      }}
      loading={isLoading}
    >
      {id === 'new' ? 'Create' : 'Update'}
    </Button>
  )
  const discardAction = (
    <Button
      key={'discard'}
      type="ghost"
      onClick={() => {
        form.resetFields()
        setIsFieldsChange(true)
      }}
    >
      Discard
    </Button>
  )

  // use this to preload
  const {
    data: response,
    error,
    mutate
  } = useSWR(id !== 'new' ? serialize(WithdrawService.get(id!)) : null)

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response && id !== 'new') {
    return (
      <PageHeader>
        <Spin />
      </PageHeader>
    )
  }

  const record = response?.data

  const refresh = () => {
    console.log('refresh', id)
    if (id != null) {
      if (id === 'new') {
      } else {
        mutate()
      }
    }
  }

  const ApproveModal: FC<{
    visible: boolean
    setVisible: (val: boolean) => void
    refresh: () => void
  }> = ({ visible, setVisible, refresh }) => {
    const { id } = useParams()
    const [form] = useForm()

    return (
      <Form
        form={form}
        layout="vertical"
        initialValues={{}}
        onFinish={async (v) => {
          try {
            const { data: response }: any = await WithdrawService.approve(id!, { ...v })
            if (response?.success) {
              message.success('Approve successful')
            } else {
              message.error(response?.message ?? 'Approve failed')
            }
            refresh()
            setVisible(false)
          } catch (error) {
            console.log(error)
            if (typeof error === 'object') {
              const errorMessage = JSON.parse(JSON.stringify(error))
              message.error(errorMessage?.message)
            }
          }
        }}
      >
        <Modal
          title={`Approve Withdraw Confirmation`}
          visible={visible}
          onOk={async () => {
            form.submit()
          }}
          onCancel={() => {
            setVisible(false)
          }}
          okText="Approve"
          cancelText="Cancel"
        >
          Are you sure want to approve the withdraw? This can't be undone.
          <Gaps />
          <Form.Item name="txn_number" label="Txn. Ref. Number" required={true}>
            <Input />
          </Form.Item>
        </Modal>
      </Form>
    )
  }

  const RejectModal: FC<{
    visible: boolean
    setVisible: (val: boolean) => void
    refresh: () => void
  }> = ({ visible, setVisible, refresh }) => {
    const { id } = useParams()
    const [form] = useForm()

    return (
      <Form
        form={form}
        layout="vertical"
        initialValues={{}}
        onFinish={async (v) => {
          try {
            console.log(v)
            const { data: response }: any = await WithdrawService.reject(id!, {
              reason: v?.reason ?? ''
            })
            if (response?.success) {
              message.success('Reject successful')
            } else {
              message.error(response?.message ?? 'Reject failed')
            }
            refresh()
            setVisible(false)
          } catch (error) {
            console.log(error)
            if (typeof error === 'object') {
              const errorMessage = JSON.parse(JSON.stringify(error))
              message.error(errorMessage?.message)
            }
          }
        }}
      >
        <Modal
          title={`Reject Withdraw Confirmation`}
          visible={visible}
          onOk={() => {
            form.submit()
          }}
          onCancel={() => {
            setVisible(false)
          }}
          okText="Reject"
          cancelText="Cancel"
        >
          Are you sure want to reject the KYC? If yes, please provide a valid reason. This can't be
          undone.
          <Gaps />
          <Form.Item name="reason" label="Reject Reason" required={true}>
            <Input.TextArea rows={6} />
          </Form.Item>
        </Modal>
      </Form>
    )
  }

  return (
    <PageHeader
      title={id === 'new' ? 'New  Withdraw' : 'View  Withdraw'}
      // extra={[fieldsChange ? discardAction : null, saveAction]}
      extra={
        record?.status === 'pending'
          ? [
              <Button
                type="primary"
                onClick={() => {
                  setApproveModalVisible(true)
                }}
              >
                Approve
              </Button>,
              <Button
                type="primary"
                danger
                onClick={() => {
                  setRejectModalVisible(true)
                }}
              >
                Reject
              </Button>
            ]
          : []
      }
      onBack={() => navigate('/dashboard/withdraws')}
    >
      <ApproveModal
        visible={approveModalVisible}
        setVisible={setApproveModalVisible}
        refresh={refresh}
      />
      <RejectModal
        visible={rejectModalVisible}
        setVisible={setRejectModalVisible}
        refresh={refresh}
      />
      <WithdrawForm
        form={form}
        initialValue={record}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setIsFieldsChange={setIsFieldsChange}
        refresh={refresh}
      />
    </PageHeader>
  )
}

export default Detail
