import { DateTime } from 'luxon'
import client, { IPaginatedResponse } from '../request'

export interface IUserActivity {
  id: string | number
  type: string
  location: string
  ip_address: string
  device: string
  provider: string
  user_id: string | number
  created_at: DateTime
  updated_at: DateTime
}

const getAll = `/user-activities`

const get = (id: string | number) => {
  return `/user-activities/${id}`
}

const getByUserId = (userId: string | number) => {
  return `/users/${userId}/user-activities`
}

const create = (data: IUserActivity) => {
  return client.post('/user-activities', data)
}

const update = (id: string | number, data: IUserActivity) => {
  return client.put(`/user-activities/${id}`, data)
}

const toRow = (response: IPaginatedResponse<IUserActivity[]>) => {
  if (response?.data?.length > 0) {
    return response.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (response: IPaginatedResponse<IUserActivity[]>) => {
  return {
    total: response?.meta?.total ?? 0
  }
}

const UserActivityService = {
  getAll,
  get,
  getByUserId,
  create,
  update,
  toRow,
  toPaginate
}

export default UserActivityService
