import { FC } from 'react'
import { proxy } from 'valtio'

const globalStore = proxy({
  collapsed: false,
  userTab: 'wallets',
  openKeys: []
})

export function getActiveTabGlobal() {
  try {
    const activeTab = localStorage.getItem('active_tab')
    if (activeTab != null) {
      globalStore.userTab = activeTab
    }
  } catch (error) {
    clearActiveTabGlobal()
    return null
  }
}

export function setActiveTabGlobal(activeTab: string) {
  if (activeTab !== null) {
    localStorage.setItem('active_tab', activeTab)
    globalStore.userTab = activeTab
  } else {
  }
}

export function clearActiveTabGlobal() {
  localStorage.removeItem('active_tab')
}

// Open keys
export function getOpenKeys() {
  try {
    const openKeys = localStorage.getItem('open_keys')
    if (openKeys != null) {
      globalStore.openKeys = JSON.parse(openKeys)
    }
  } catch (error) {
    clearOpenKeys()
    return []
  }
}

export function setOpenKeys(openKeys: any) {
  if (openKeys !== null) {
    localStorage.setItem('open_keys', JSON.stringify(openKeys))
    globalStore.openKeys = openKeys
  } else {
  }
}

export function clearOpenKeys() {
  localStorage.removeItem('open_keys')
}

export default globalStore
