import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Card,
  Col,
  Form,
  Button,
  Input,
  Row,
  message,
  FormInstance,
  Select,
  InputNumber
} from 'antd'

import Gaps from '../../components/Gaps'
import PoolService, { IPool } from '../../network/services/pool'

const PoolForm: FC<{
  form: FormInstance<IPool>
  initialValue?: IPool
  isLoading: boolean
  setIsLoading: (val: boolean) => void
  setIsFieldsChange: (val: boolean) => void
  refresh: () => void
}> = ({ form, initialValue, isLoading = false, setIsLoading, setIsFieldsChange, refresh }) => {
  const navigate = useNavigate()
  const { id } = useParams()

  const handleSubmit = async (values: IPool) => {
    if (isLoading) return
    setIsLoading(true)
    console.log(initialValue)
    let result
    if (initialValue?.id) {
      try {
        result = await PoolService.update(initialValue.id!, values)
        message.success('Update successful')
        refresh()
      } catch (error) {
        console.log(error)
        if (typeof error === 'object') {
          const errorMessage = JSON.parse(JSON.stringify(error))
          message.error(errorMessage?.message)
        }
        setIsLoading(false)
      }
    } else {
      try {
        result = await PoolService.create(values)
        message.success('Create successful')
      } catch (error) {
        console.log(error)
        if (typeof error === 'object') {
          const errorMessage = JSON.parse(JSON.stringify(error))
          message.error(errorMessage?.message)
        }
        setIsLoading(false)
      }
    }

    if (result) {
      setIsLoading(false)
      setIsFieldsChange(false)
      // navigate(`/dashboard/pools/${id}`)
      refresh()
    }
  }

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <>
      <Row gutter={[16, 0]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
          <Form
            form={form!}
            name="new-pool"
            onFinish={handleSubmit}
            layout="horizontal"
            initialValues={{
              ...initialValue
            }}
            onFieldsChange={() => {
              setIsFieldsChange(true)
            }}
          >
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item name="token_name" label="Token Name" rules={[{ required: true }]}>
                    <Input
                      readOnly={id === 'new' ? false : true}
                      bordered={id === 'new' ? true : false}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="token_type" label="Token Symbol" rules={[{ required: true }]}>
                    {id === 'new' ? (
                      <Select
                        options={[
                          { label: 'ETH', value: 'ETH' },
                          { label: 'USDT', value: 'USDT' }
                        ]}
                      />
                    ) : (
                      <Input readOnly={true} bordered={false} />
                    )}
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item name="type" label="Type" rules={[{ required: true }]}>
                    <Select
                      options={[
                        { label: 'Locked Staking', value: 'fix_earn' },
                        { label: 'Flexible Earn', value: 'flex_earn' },
                        { label: 'Flexible Pool Staking', value: 'flex_pool' }
                      ]}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="blocked" label="Hidden?" rules={[{ required: true }]}>
                    <Select
                      options={[
                        { label: 'Yes', value: true },
                        { label: 'No', value: false }
                      ]}
                    />
                  </Form.Item>
                </Col>
                {/* <Col span={12}>
                  <Form.Item
                    name="allow_terminate"
                    label="Allow Terminate"
                    rules={[{ required: true }]}
                  >
                    <Select
                      disabled
                      options={[
                        { label: 'True', value: true },
                        { label: 'False', value: false }
                      ]}
                    />
                  </Form.Item>
                </Col> */}
                <Col span={12}>
                  <Form.Item
                    name="allow_subscribe"
                    label="Allow to Stake?"
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={[
                        { label: 'Yes', value: true },
                        { label: 'No', value: false }
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Gaps />

            <Card>
              <Row gutter={[16, 0]}>
                <Col span={8}>
                  <Form.Item
                    name="min_amount"
                    label={`Min Staking amount (${initialValue?.token_type})`}
                    rules={[{ required: true }]}
                  >
                    <InputNumber min={0} />
                  </Form.Item>
                </Col>
                {initialValue?.type === 'fix_earn' && (
                  <Col span={8}>
                    <Form.Item
                      name="max_amount"
                      label={`Max Staking amount (${initialValue?.token_type})`}
                      rules={[{ required: true }]}
                    >
                      <InputNumber min={0} />
                    </Form.Item>
                  </Col>
                )}
                <Col span={8}>
                  <Form.Item
                    name="payout_day"
                    label="Payout Day"
                    rules={[{ required: true }]}
                    hidden
                  >
                    <InputNumber min={1} max={31} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="pool_size"
                    label={`Current Pool Size (${initialValue?.token_type})`}
                    rules={[{ required: true }]}
                  >
                    <Input readOnly bordered={false} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="tenure" label={`Tenure`} rules={[{ required: true }]}>
                    <Input readOnly bordered={false} suffix={'days'} />
                  </Form.Item>
                </Col>
                {initialValue?.type === 'launch' && (
                  <Col span={8}>
                    <Form.Item
                      name="max_pool_size"
                      label={`Max Pool Size (${initialValue?.token_type})`}
                      rules={[{ required: true }]}
                    >
                      <InputNumber min={0} />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Card>
            {/* <Gaps /> */}
            <Card hidden>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item
                    name="subscription_fee_type"
                    label="Subscription Fee Type"
                    rules={[{ required: true }]}
                    hidden
                  >
                    <Select
                      options={[
                        { label: 'Value', value: 0 },
                        { label: 'Percentage', value: 1 }
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="subscription_fee"
                    label="Subscription Fee"
                    rules={[{ required: true }]}
                    hidden
                  >
                    <InputNumber min={0} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="termination_fee_type"
                    label="Termination Fee Type"
                    rules={[{ required: true }]}
                    hidden
                  >
                    <Select
                      options={[
                        { label: 'Value', value: 0 },
                        { label: 'Percentage', value: 1 }
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="termination_fee"
                    label="Termination Fee"
                    rules={[{ required: true }]}
                    hidden
                  >
                    <InputNumber min={0} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            {/* <Gaps /> */}
            {/* 
            <Card>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item
                    name="has_tenure_12"
                    label="Has Tenure 12"
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={[
                        { label: 'True', value: true },
                        { label: 'False', value: false }
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="has_tenure_24"
                    label="Has Tenure 24"
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={[
                        { label: 'True', value: true },
                        { label: 'False', value: false }
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card> */}

            <Gaps />
          </Form>
          <Gaps />
        </Col>
      </Row>
    </>
  )
}

export default PoolForm
