import { FC, ReactNode, Suspense } from 'react'
import { Space, Spin } from 'antd'
import { Routes, BrowserRouter, Route, Navigate, To } from 'react-router-dom'
import { useSnapshot } from 'valtio'

import './App.less'
import authStore, { checkAuthorization } from './lib/store/auth'
import Dashboard from './pages/Dashboard'
import SignIn from './pages/auth/SignIn'
import AuthenticatedLayout from 'layout/AuthenticatedLayout'
import UserPage from 'pages/users'
import UserDetail from 'pages/users/UserDetail'
import AssetPage from 'pages/assets'
import AssetDetail from 'pages/assets/AssetDetail'
import NetworkPage from 'pages/networks'
import NetworkDetail from 'pages/networks/NetworkDetail'
import PoolPage from 'pages/pools'
import PoolDetail from 'pages/pools/PoolDetail'
import UserRelated from 'pages/users/UserRelated'
import StakeDetail from 'pages/stakes/StakeDetail'
import CurrencyPage from 'pages/currencies'
import CurrencyDetail from 'pages/currencies/CurrencyDetail'
import NftPage from 'pages/nfts'
import NftDetail from 'pages/nfts/NftDetail'
import TransactionPage from 'pages/transactions'
import ProfitPage from 'pages/profits'
import ProfitDetail from 'pages/profits/ProfitDetail'
// import ReportPage from 'pages/reports'
import VerificationForm from 'pages/verifications/VerificationForm'
import StakePage from 'pages/stakes'
import StakeInterestPage from 'pages/stake_interests'
import VerificationPage from 'pages/verifications'
import NftDepositPage from 'pages/nft_deposits'
import NftDepositDetail from 'pages/nft_deposits/NftDepositDetail'
import NftWithdrawPage from 'pages/nft_withdraws'
import NftWithdrawDetail from 'pages/nft_withdraws/NftWithdrawDetail'
import WithdrawPage from 'pages/withdraws'
import WithdrawDetail from 'pages/withdraws/WithdrawDetail'
import AnnouncementPage from 'pages/announcements'
import AnnouncementDetail from 'pages/announcements/AnnouncementDetail'
import TicketPage from 'pages/tickets'
import TicketDetail from 'pages/tickets/TicketDetail'
import StakeTransactionPage from 'pages/stakes/transactions'
const PrivateRoute: FC<{ children?: ReactNode; redirectTo: To }> = ({ children, redirectTo }) => {
  const { token: isLoggedIn } = useSnapshot(authStore)

  return isLoggedIn ? <>{children}</> : <Navigate to={redirectTo} />
}

function App() {
  return (
    <Suspense
      fallback={
        <Space align="center" style={{ width: '100vw', height: '100vh', justifyContent: 'center' }}>
          <Spin />
        </Space>
      }
    >
      <BrowserRouter>
        <Routes>
          <Route
            path="/dashboard"
            element={
              <PrivateRoute redirectTo="/">
                <AuthenticatedLayout />
              </PrivateRoute>
            }
          >
            <Route path="" element={<Dashboard />} />
            <Route path="users" element={<UserPage />} />
            <Route path="users/:id" element={<UserDetail />} />
            <Route path="users/:user_id/info" element={<UserRelated />} />
            {/* <Route path="users/:user_id/info/wallets/:id" element={<WalletDetail />} /> */}
            {/* <Route
              path="users/:user_id/info/wallet-transactions/:wallet_id"
              element={<WalletTransactionPage />}
            /> */}
            {/* <Route path="users/:user_id/info/stakes/:id" element={<StakeDetail />} /> */}
            <Route path="currencies" element={<CurrencyPage />} />
            <Route path="currencies/:id" element={<CurrencyDetail />} />
            <Route path="assets" element={<AssetPage />} />
            <Route path="assets/:id" element={<AssetDetail />} />
            <Route path="networks" element={<NetworkPage />} />
            <Route path="networks/:id" element={<NetworkDetail />} />
            <Route path="pools" element={<PoolPage />} />
            <Route path="pools/:id" element={<PoolDetail />} />

            {/* NFTS */}
            <Route path="nfts" element={<NftPage />} />
            <Route path="nfts/:id" element={<NftDetail />} />
            {/* <Route path="nft-deposits" element={<NftDepositPage />} /> */}
            <Route path="nft-deposits/:id" element={<NftDepositDetail />} />
            <Route path="nft-withdraws" element={<NftWithdrawPage />} />
            <Route path="nft-withdraws/:id" element={<NftWithdrawDetail />} />

            <Route path="stakes">
              <Route path="" element={<StakePage />} />
              <Route path=":id" element={<StakeDetail />} />
              <Route path=":stake_id/interests" element={<StakeInterestPage />} />
              <Route path=":stake_id/transactions" element={<StakeTransactionPage />} />
            </Route>

            {/* Finance */}
            {/* <Route path="finance">
              <Route path="wallets" element={<SystemWalletPage />} />
              <Route
                path="system-wallet-transactions/:wallet_id"
                element={<TransactionPage />}
              />
              <Route path="profits" element={<ProfitPage />} />
              <Route path="profits/:id" element={<ProfitDetail />} />
              <Route path="profits/:profit_id/reports" element={<ReportPage />} />
            </Route> */}

            {/* Verifications */}
            <Route path="verifications" element={<VerificationPage />} />
            <Route path="verifications/:id" element={<VerificationForm />} />

            {/* Transactions */}
            <Route path="transactions" element={<TransactionPage />} />

            {/* Profit */}
            <Route path="profits" element={<ProfitPage />} />
            <Route path="profits/:id" element={<ProfitDetail />} />
            {/* <Route path="profits/:profit_id/reports" element={<ReportPage />} /> */}

            {/* Withdraws */}
            <Route path="withdraws" element={<WithdrawPage />} />
            <Route path="withdraws/:id" element={<WithdrawDetail />} />

            {/* announcements */}
            <Route path="announcements" element={<AnnouncementPage />} />
            <Route path="announcements/:id" element={<AnnouncementDetail />} />

            {/* tickets */}
            <Route path="tickets" element={<TicketPage />} />
            <Route path="tickets/:id" element={<TicketDetail />} />
          </Route>

          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/" element={<SignIn />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}

new Promise(() => {
  checkAuthorization()
}).catch((error) => console.error(error))

export default App
