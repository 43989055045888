import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Card,
  Col,
  Form,
  Row,
  message,
  FormInstance,
  Select,
  InputNumber,
  DatePicker,
  Upload,
  Button,
  Input
} from 'antd'

import Gaps from '../../components/Gaps'
import ProfitService, { IProfit } from '../../network/services/profit'
import useSWR from 'swr'
import PoolService from 'network/services/pool'
import { DefaultOptionType } from 'antd/lib/select'
import moment from 'moment'
import FileService from 'network/services/file'
import { RcFile, UploadFile } from 'antd/es/upload/interface'
import { UploadOutlined } from '@ant-design/icons'
import { BigNumber } from 'bignumber.js'
import { useUpdateEffect } from 'react-use'
import { DateTime } from 'luxon'
import _ from 'lodash'

const ProfitForm: FC<{
  form: FormInstance<IProfit>
  initialValue?: IProfit
  isLoading: boolean
  setIsLoading: (val: boolean) => void
  setIsFieldsChange: (val: boolean) => void
  refresh: () => void
}> = ({ form, initialValue, isLoading = false, setIsLoading, setIsFieldsChange, refresh }) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [poolList, setPoolList] = useState<Record<string, any>[]>([])

  const { data: poolData } = useSWR(PoolService.getAll)
  const [isUpdated, setIsUpdated] = useState(false)
  const [fileList, setFileList] = useState<any>([])
  const [profitValue, setProfitValue] = useState<number>(0)
  const [publishDate, setPublishDate] = useState<any>(null)

  const handleSubmit = async (values: IProfit) => {
    console.log(values)
    if (isLoading) return
    setIsLoading(true)

    if (fileList.length > 0) {
      // console.log(fileList)
      const file = fileList[0].url
      console.log(file)
      values.report_url = file
    }

    let result
    if (initialValue?.id) {
      try {
        result = await ProfitService.update(initialValue.id!, values)
        message.success('Update successful')
        setIsLoading(false)
        refresh()
      } catch (error) {
        console.log(error)
        if (typeof error === 'object') {
          const errorMessage = JSON.parse(JSON.stringify(error))
          message.error(errorMessage?.message)
        }
        setIsLoading(false)
      }
    } else {
      try {
        result = await ProfitService.create(values)
        message.success('Create successful')
      } catch (error) {
        console.log(error)
        if (typeof error === 'object') {
          const errorMessage = JSON.parse(JSON.stringify(error))
          message.error(errorMessage?.message)
        }
        setIsLoading(false)
      }
    }

    if (result) {
      setIsLoading(false)
      setIsFieldsChange(false)
      navigate('/dashboard/finance/profits')
    }
  }

  const handleRemove = async (file: UploadFile<any>) => {
    const index = fileList.indexOf(file)
    const newFileList = fileList.slice()
    newFileList.splice(index, 1)
    setFileList(newFileList)
    setIsUpdated(true)
  }

  const handleUpload = async (file: RcFile) => {
    console.log(file.type)
    const isExcel =
      file.type === 'application/vnd.ms-excel' ||
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    if (!isExcel) {
      message.error('You can only upload Excel file!')
      return false
    }
    const isLt20M = file.size / 1024 / 1024 < 20
    if (!isLt20M) {
      message.error('The file must smaller than 20MB!')
      return false
    }
    try {
      const upload = await FileService.upload(file)
      console.log(upload)
      if (upload.success == true) {
        setFileList([
          {
            ...file,
            url: upload.data
          }
        ])
        setIsUpdated(true)
      }
    } catch (error: any) {
      message.error(`Upload failed : ${error?.data?.statusText ?? error?.message ?? 'unknown'}`)
    }
  }

  const generatePoolList = () => {
    const tmpList: DefaultOptionType[] = []

    if (poolData) {
      for (let i = 0; i < poolData.data.length; i++) {
        tmpList.push({
          label:
            // poolData?.data[i]?.token_name +
            // ' - ' +
            poolData?.data[i]?.token_type + ' - ' + poolData?.data[i]?.type,
          value: poolData?.data[i]?.id,
          type: poolData?.data[i]?.type
        })
      }
      setPoolList(tmpList)
    }
  }

  useEffect(() => {
    form.resetFields()
    console.log(initialValue)
    let isUpdate = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (id !== 'new' && initialValue?.report_url) {
      setFileList([
        {
          uid: '1',
          name: 'default',
          status: 'done',
          url: initialValue?.report_url
        }
      ])
      isUpdate = true
    }
    if (id !== 'new' && initialValue?.published_at) {
      setPublishDate(initialValue.published_at)
      isUpdate = true
    }
    if (isUpdate) {
      setIsUpdated(true)
    }
    if (poolData !== null) {
      generatePoolList()
    }
  }, [initialValue, poolData, id])

  const calculateProfitRate = async () => {
    console.log('calculateProfitRate', moment(publishDate).format('yyyy-MM-DD'))
    const response: any = await ProfitService.getEstimatedRate({
      profit_value: profitValue,
      target_month: moment(publishDate).format('yyyy-MM-DD'),
      pool_id: form.getFieldValue('pool_id')
    })
    const estimate = response.data.data
    console.log(estimate)
    form.setFieldsValue({
      ...estimate,
      profit_rate: new BigNumber(estimate?.profit_rate ?? 0).decimalPlaces(2).toNumber()
    })
  }

  const calculateVxtPoolProfitValue = async () => {
    console.log('calculateProfitRate', moment(publishDate).format('yyyy-MM-DD'))
    const profitValueResponse = await ProfitService.getVxtPoolProfitValue({
      target_month: moment(publishDate).format('yyyy-MM-DD'),
      pool_id: form.getFieldValue('pool_id')
    })
    const profitValueVxt = profitValueResponse.data?.data ?? 0
    const response: any = await ProfitService.getEstimatedRate({
      profit_value: profitValueVxt,
      target_month: moment(publishDate).format('yyyy-MM-DD'),
      pool_id: form.getFieldValue('pool_id')
    })
    const estimate = response.data.data

    form.setFieldsValue({
      ...estimate,
      profit_value: new BigNumber(profitValueVxt ?? 0).decimalPlaces(2).toNumber()
    })
  }

  return (
    <>
      <Form
        form={form!}
        name="new-profit"
        onFinish={handleSubmit}
        layout="horizontal"
        {...(id !== 'new' && {
          initialValues: { ...initialValue, published_at: moment(initialValue?.published_at) }
        })}
        onFieldsChange={(value: any) => {
          console.log(value)
          if (value?.length > 0) {
            const currentPoolId = form.getFieldValue('pool_id')
            const currentPool = _.find(poolList, { value: currentPoolId })
            if (value[0]?.name?.length > 0) {
              if (value[0]?.name[0] === 'profit_value') {
                setProfitValue(value[0]?.value)
                calculateProfitRate()
              }
              if (value[0]?.name[0] === 'published_at') {
                setPublishDate(value[0]?.value)
                if (currentPool?.type === 'vxt') {
                  calculateVxtPoolProfitValue()
                } else {
                  calculateProfitRate()
                }
              }
              if (value[0]?.name[0] === 'pool_id') {
                if (currentPool?.type === 'vxt') {
                  calculateVxtPoolProfitValue()
                } else {
                  calculateProfitRate()
                }
              }
            }
          }
          setIsFieldsChange(true)
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
            <Card bordered={false}>
              <Form.Item name="pool_id" label="Pool" rules={[{ required: true }]}>
                <Select options={poolList} disabled={initialValue?.distributed} />
              </Form.Item>

              <Form.Item name="profit_value" label="Profit Value" rules={[{ required: true }]}>
                <InputNumber min={0} step={1} disabled={initialValue?.distributed} />
              </Form.Item>

              {/* 
              <Form.Item name="multiplier" label="Multiplier">
                <InputNumber min={0} step={1} />
              </Form.Item> */}

              <Form.Item
                name="exchange_rate"
                label={`VXT Exchange Rate to ${initialValue?.token_type}`}
                rules={[{ required: true }]}
              >
                <InputNumber min={0} step={0.1} disabled={initialValue?.distributed} />
              </Form.Item>

              <Form.Item name="published" label="Published" rules={[{ required: true }]}>
                <Select
                  options={[
                    { label: 'True', value: true },
                    { label: 'False', value: false }
                  ]}
                  disabled={initialValue?.distributed}
                />
              </Form.Item>

              <Form.Item name="published_at" label="Profit Date" rules={[{ required: true }]}>
                <DatePicker disabled={initialValue?.distributed} />
              </Form.Item>

              <Form.Item
                name="report_url"
                label="Report Url"
                hidden
                // {...(id === 'new' && {
                //   valuePropName: 'fileList',
                //   getValueFromEvent: (e) => {
                //     console.log(e)
                //     if (Array.isArray(e)) {
                //       return e
                //     }
                //     return e && e.fileList
                //   }
                // })}

                // valuePropName="fileList"
                // getValueFromEvent={(e) => {
                //   console.log(e)
                //   if (Array.isArray(e)) {
                //     return e
                //   }
                //   return e && e.fileList
                // }}
              >
                <Upload
                  listType="picture"
                  multiple={false}
                  {...(initialValue?.report_url && {
                    defaultFileList: [
                      {
                        uid: '1',
                        name: 'default',
                        status: 'done',
                        url: initialValue?.report_url
                      }
                    ]
                  })}
                  {...(isUpdated && { fileList: fileList })}
                  beforeUpload={(file) => {
                    handleUpload(file)
                    return false
                  }}
                  onRemove={handleRemove}
                  showUploadList={{ showPreviewIcon: false }}
                >
                  {fileList.length > 0 ? null : (
                    <Button icon={<UploadOutlined />}>Upload File</Button>
                  )}
                </Upload>
              </Form.Item>
            </Card>
            <Gaps />
            <Card title="Pool Rates of tenure 12 month">
              <Form.Item label="Stake Rate" name="stake_rate_12">
                <Input placeholder="Enter Profit Rate" readOnly suffix="%" />
              </Form.Item>

              <Form.Item label="Nft Rate" name="nft_rate_12">
                <Input placeholder="Total" readOnly suffix="%" />
              </Form.Item>

              <Form.Item label="Pool Incentive Rate" name="incentive_rate_12">
                <Input placeholder="Total" readOnly suffix="%" />
              </Form.Item>

              {/* <Form.Item label="PBC Pool Rate" name="incentive_rate_12">
                <Input placeholder="Total" readOnly suffix="%" />
              </Form.Item> */}

              <Form.Item label="Reserve Rate" name="reserve_rate_12">
                <Input placeholder="Total" readOnly suffix="%" />
              </Form.Item>

              <Form.Item label="Team Rate" name="team_rate_12">
                <Input placeholder="Total" readOnly suffix="%" />
              </Form.Item>

              <Form.Item label="Burn Rate" name="burn_rate_12">
                <Input placeholder="Total" readOnly suffix="%" />
              </Form.Item>
            </Card>
            <Gaps />
            <Card title="Pool Rates of tenure 24 month">
              <Form.Item label="Stake Rate" name="stake_rate_24">
                <Input placeholder="Enter Profit Rate" readOnly suffix="%" />
              </Form.Item>

              <Form.Item label="Nft Rate" name="nft_rate_24">
                <Input placeholder="Total" readOnly suffix="%" />
              </Form.Item>

              <Form.Item label="Pool Incentive Rate" name="incentive_rate_24">
                <Input placeholder="Total" readOnly suffix="%" />
              </Form.Item>
              {/* 
              <Form.Item label="PBC Pool Rate" name="incentive_rate_24">
                <Input placeholder="Total" readOnly suffix="%" />
              </Form.Item> */}

              <Form.Item label="Reserve Rate" name="reserve_rate_24">
                <Input placeholder="Total" readOnly suffix="%" />
              </Form.Item>

              <Form.Item label="Team Rate" name="team_rate_24">
                <Input placeholder="Total" readOnly suffix="%" />
              </Form.Item>

              <Form.Item label="Burn Rate" name="burn_rate_24">
                <Input placeholder="Total" readOnly suffix="%" />
              </Form.Item>
            </Card>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
            <Card>
              <Form.Item label="Computed Profit Rate" name="profit_rate">
                <Input placeholder="Enter Profit Rate" readOnly suffix="%" />
              </Form.Item>

              <Form.Item label="Computed Total Subscribe" name="total_subscribe">
                <Input placeholder="Total Subscribe" readOnly suffix={initialValue?.token_type} />
              </Form.Item>

              <Form.Item label="Computed Total Subscribe 12 mo" name="total_subscribe_12">
                <Input placeholder="Total Subscribe" readOnly suffix={initialValue?.token_type} />
              </Form.Item>

              <Form.Item label="Computed Total Subscribe 24 mo" name="total_subscribe_24">
                <Input placeholder="Total Subscribe" readOnly suffix={initialValue?.token_type} />
              </Form.Item>

              <Form.Item
                label="Computed Total Subscribe linking with NFT"
                name="total_nft_subscribe"
              >
                <Input placeholder="Total Subscribe" readOnly suffix={initialValue?.token_type} />
              </Form.Item>

              <Form.Item
                label="Computed Total Subscribe linking with NFT 12 mo"
                name="total_nft_subscribe_12"
              >
                <Input placeholder="Total Subscribe" readOnly suffix={initialValue?.token_type} />
              </Form.Item>

              <Form.Item
                label="Computed Total Subscribe linking with NFT 24 mo"
                name="total_nft_subscribe_24"
              >
                <Input placeholder="Total Subscribe" readOnly suffix={initialValue?.token_type} />
              </Form.Item>

              <Form.Item label="Computed Total Subscribe earning in PBC" name="total_nft_subscribe">
                <Input placeholder="Total Subscribe" readOnly suffix={initialValue?.token_type} />
              </Form.Item>

              <Form.Item
                label="Computed Total Subscribe earning in PBC 12 mo"
                name="total_incentive_subscribe_12"
              >
                <Input placeholder="Total Subscribe" readOnly suffix={initialValue?.token_type} />
              </Form.Item>

              <Form.Item
                label="Computed Total Subscribe earning in PBC 24 mo"
                name="total_incentive_subscribe_24"
              >
                <Input placeholder="Total Subscribe" readOnly suffix={initialValue?.token_type} />
              </Form.Item>
            </Card>
            <Gaps />
            <Card>
              <Form.Item label="Computed Total Distribute" name="total_profit">
                <Input placeholder="Total distribute" readOnly suffix={initialValue?.token_type} />
              </Form.Item>

              <Form.Item label="Computed Total Distribute 12 mo" name="total_profit_12">
                <Input placeholder="Total distribute" readOnly suffix={initialValue?.token_type} />
              </Form.Item>

              <Form.Item label="Computed Total Distribute 24 mo" name="total_profit_24">
                <Input placeholder="Total distribute" readOnly suffix={initialValue?.token_type} />
              </Form.Item>

              <Form.Item label="Computed Total Distribute for linking Nft" name="total_nft">
                <Input placeholder="Total distribute" readOnly suffix={initialValue?.token_type} />
              </Form.Item>

              <Form.Item
                label="Computed Total Distribute for linking Nft 12 mo"
                name="total_nft_12"
              >
                <Input placeholder="Total distribute" readOnly suffix={initialValue?.token_type} />
              </Form.Item>

              <Form.Item label="Computed Total Profit for linking Nft 24 mo" name="total_nft_24">
                <Input placeholder="Total distribute" readOnly suffix={initialValue?.token_type} />
              </Form.Item>

              <Form.Item
                label="Computed Total Distribute for earning in VXT"
                name="total_incentive"
              >
                <Input placeholder="Total distribute" readOnly suffix={initialValue?.token_type} />
              </Form.Item>

              <Form.Item
                label="Computed Total Distribute for earning in VXT 12 mo"
                name="total_incentive_12"
              >
                <Input placeholder="Total distribute" readOnly suffix={initialValue?.token_type} />
              </Form.Item>

              <Form.Item
                label="Computed Total Profit for earning in VXT 24 mo"
                name="total_incentive_24"
              >
                <Input placeholder="Total distribute" readOnly suffix={initialValue?.token_type} />
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default ProfitForm
