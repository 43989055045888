import { proxy } from 'valtio'
import { getActiveTabGlobal, getOpenKeys } from './global'

interface ILoginResponse {
  token?: string
  profile?: {}
}

const authStore = proxy<ILoginResponse>({
  token: undefined,
  profile: undefined
})

export const checkAuthorization = () => {
  const token = getToken()
  const profile = getProfile()

  if (token != null) {
    // TODO: refresh token on entry
    login({ token, profile })
    getActiveTabGlobal()
    getOpenKeys()
    return true
  } else {
    console.log('not authenticated')
    return false
  }
}

export const login = ({ token, profile }: ILoginResponse) => {
  if (token) {
    authStore.token = token
    authStore.profile = profile

    localStorage.setItem('token', token)
    localStorage.setItem('profile', JSON.stringify(profile))
  } else {
    // login error
  }
}

export const logout = () => {
  clearToken()
  clearProfile()

  authStore.token = undefined
  authStore.profile = undefined
}

export function getToken() {
  try {
    const token = localStorage.getItem('token')
    return token
  } catch (err) {
    clearToken()
    return null
  }
}

export function getProfile() {
  try {
    const profile = localStorage.getItem('profile')
    if (profile) {
      return JSON.parse(profile)
    }
  } catch (err) {
    clearProfile()
    return null
  }
}

export function clearToken() {
  localStorage.removeItem('token')
}

export function clearProfile() {
  localStorage.removeItem('profile')
}

export default authStore
