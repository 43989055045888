import { DateTime } from 'luxon'
import client, { IPaginatedResponse } from '../request'

export interface IUserSecurity {
  id: string | number
  type: string
  location: string
  ip_address: string
  device: string
  provider: string
  user_id: string | number
  created_at: DateTime
  updated_at: DateTime
}

const getAll = `/user-securities`

const get = (id: string | number) => {
  return `/user-securities/${id}`
}

const getByUserId = (userId: string | number) => {
  return `/users/${userId}/user-securities`
}

const create = (data: IUserSecurity) => {
  return client.post('/user-securities', data)
}

const update = (id: string | number, data: IUserSecurity) => {
  return client.put(`/user-securities/${id}`, data)
}

const toRow = (response: IPaginatedResponse<IUserSecurity[]>) => {
  if (response?.data?.length > 0) {
    return response.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (response: IPaginatedResponse<IUserSecurity[]>) => {
  return {
    total: response?.meta?.total ?? 0
  }
}

const UserSecurityService = {
  getAll,
  get,
  getByUserId,
  create,
  update,
  toRow,
  toPaginate
}

export default UserSecurityService
