import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, PageHeader, Empty, Spin } from 'antd'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'

import tableStore from '../../lib/store/reward_received_table'
import InterestService from '../../network/services/interest'
import { serialize } from '../../network/request'
import StakeInterestTable from './StakeInterestTable'

const StakeInterestPage = () => {
  const navigate = useNavigate()

  return (
    <PageHeader title={`Interest of Stake`} onBack={() => navigate(`/dashboard/stakes`)}>
      <Card>
        <StakeInterestTableWrapper />
      </Card>
    </PageHeader>
  )
}

const StakeInterestTableWrapper = () => {
  const { state } = useSnapshot(tableStore)
  const { stake_id } = useParams()

  // use this to preload
  const { data: response, error } = useSWR(
    serialize(InterestService.getByStakeId(stake_id!), {
      page: 1,
      limit: state.pageSize,
      ...state.filters
    })
  )

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return <Spin />
  }

  const { total } = InterestService.toPaginate(response)

  return (
    <>
      <StakeInterestTable total={total} />
      {/* preload next page */}
      {state.currentPage * state.pageSize < total && (
        <div style={{ display: 'none' }}>
          <StakeInterestTable total={total} overridePage={state.currentPage + 1} />
        </div>
      )}
    </>
  )
}

export default StakeInterestPage
