import { useState, FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Form,
  Empty,
  Spin,
  PageHeader,
  Button,
  Dropdown,
  Space,
  Menu,
  Modal,
  message,
  Row,
  Col,
  Input,
  InputNumber
} from 'antd'
import useSWR from 'swr'

import { serialize } from '../../network/request'
import StakeService, { IStake, IStakeTerminate } from '../../network/services/stake'
import StakeForm from './StakeForm'
import { CaretDownOutlined } from '@ant-design/icons'
import { useForm } from 'antd/lib/form/Form'

const TerminationModal: FC<{
  record: IStake
  confirmVisible: boolean
  setConfirmVisible: (val: boolean) => void
}> = ({ confirmVisible, setConfirmVisible, record }) => {
  const [form] = useForm()

  const handleSubmit = async (v: IStakeTerminate) => {
    if (record) {
      const result: any = await StakeService.terminate(record.id, {
        termination_fee_rate: v?.termination_fee_rate ?? 0
      })
      console.log(result)
      if (result.data?.success) {
        message.success('Terminate successful')
      } else {
        message.success('Terminate error')
      }
    } else {
      message.error('Staking not found')
    }
    setConfirmVisible(false)
  }

  return (
    <Form onFinish={handleSubmit} autoComplete="off" form={form}>
      <Modal
        title="Termination confirmation"
        visible={confirmVisible}
        onOk={async () => {
          if (record?.status === 'terminated') {
            message.error('Staking had already terminated')
            return
          }
          form.submit()
        }}
        onCancel={() => {
          setConfirmVisible(false)
        }}
        okText="Yes"
        cancelText="No"
      >
        {`Are you sure want to terminate this staking?`}
        <Form.Item
          name="termination_fee_rate"
          label="Terminate Fee (%)"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>
      </Modal>
    </Form>
  )
}

const StakeDetail = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [confirmVisible, setConfirmVisible] = useState(false)

  const saveAction = (
    <Button
      key={'save'}
      type="primary"
      onClick={() => {
        form.submit()
      }}
      loading={isLoading}
    >
      {id === 'new' ? 'Create' : 'Update'}
    </Button>
  )
  const discardAction = (
    <Button
      key={'discard'}
      type="ghost"
      onClick={() => {
        navigate('/dashboard/users')
      }}
    >
      Discard
    </Button>
  )

  // use this to preload
  const {
    data: response,
    error,
    mutate
  } = useSWR(id !== 'new' ? serialize(StakeService.get(id!)) : null)

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response && id !== 'new') {
    return (
      <PageHeader>
        <Spin />
      </PageHeader>
    )
  }

  const record = response?.data

  const refresh = () => {
    if (id != null) {
      if (id === 'new') {
      } else {
        mutate()
      }
    }
  }

  return (
    <>
      <TerminationModal
        confirmVisible={confirmVisible}
        setConfirmVisible={setConfirmVisible}
        record={record}
      />
      <PageHeader
        title={id === 'new' ? 'New stake' : 'View Stake Details'}
        // extra={[discardAction, saveAction]}
        extra={
          [
            // <Button
            //   type="primary"
            //   danger
            //   onClick={() => {
            //     setConfirmVisible(true)
            //   }}
            // >
            //   <Space>Terminate</Space>
            // </Button>
          ]
        }
        onBack={() => navigate(`/dashboard/stakes`)}
      >
        <StakeForm
          form={form}
          initialValue={record}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          refresh={refresh}
        />
      </PageHeader>
    </>
  )
}

export default StakeDetail
