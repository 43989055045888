import { Card, Col, DatePicker, PageHeader, Row, Select, Statistic } from 'antd'
import Gaps from 'components/Gaps'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { RangeValue } from 'rc-picker/lib/interface'
import CurrencyService, { ICurrency } from '../../network/services/currency'
import StatisticService, { IStatistic } from '../../network/services/statistic'
import useSWR from 'swr'
import { serialize } from '../../network/request'

const StatisticsCard: FC<{
  fromDate?: string
  toDate?: string
  symbol?: string
}> = ({ fromDate, toDate, symbol }) => {
  const {
    data: response,
    error,
    mutate
  } = useSWR(
    serialize(StatisticService.getStatistics, {
      from_date: fromDate,
      to_date: toDate,
      symbol: symbol
    })
  )
  const record: IStatistic = response?.data

  const bodyStyle: React.CSSProperties = { padding: '14px' }

  return (
    <Row gutter={[16, 16]}>
      <Col md={12} xl={6}>
        <Card bodyStyle={bodyStyle}>
          <Statistic title="Deposits" value={record?.deposit_amount} suffix={symbol} />
        </Card>
      </Col>
      <Col md={12} xl={6}>
        <Card bodyStyle={bodyStyle}>
          <Statistic title="Withdrawn" value={record?.withdraw_amount} suffix={symbol} />
        </Card>
      </Col>
      <Col md={12} xl={6}>
        <Card bodyStyle={bodyStyle}>
          <Statistic title="Staking" value={record?.stake_12_amount} suffix={symbol} />
        </Card>
      </Col>
      {/* <Col md={12} xl={6}>
        <Card bodyStyle={bodyStyle}>
          <Statistic title="Withdrawal Gas" value={record?.withdraw_network_fee} suffix={symbol} />
        </Card>
      </Col> */}
      <Col md={12} xl={6}>
        <Card bodyStyle={bodyStyle}>
          <Statistic title="Withdrawal Fee" value={record?.withdraw_fee} suffix={symbol} />
        </Card>
      </Col>
      <Col md={12} xl={6}>
        <Card bodyStyle={bodyStyle}>
          <Statistic
            title="Termination Amount"
            value={record?.termination_amount}
            suffix={symbol}
          />
        </Card>
      </Col>
      <Col md={12} xl={6}>
        <Card bodyStyle={bodyStyle}>
          <Statistic title="Maturity Amount" value={record?.maturity_amount} suffix={symbol} />
        </Card>
      </Col>
      <Col md={12} xl={6}>
        <Card bodyStyle={bodyStyle}>
          <Statistic title="Subscription Fee" value={record?.subscription_fee} suffix={symbol} />
        </Card>
      </Col>
      <Col md={12} xl={6}>
        <Card bodyStyle={bodyStyle}>
          <Statistic title="New Users" value={record?.user_count} />
        </Card>
      </Col>
    </Row>
  )
}

const StatisticsPage: FC<{
  currencies: ICurrency[]
}> = ({ currencies }) => {
  const [symbol, setSymbol] = useState('ETH')
  const [durationRange, setDurationRange] = useState([
    moment().startOf('day'),
    moment().endOf('day')
  ])

  // useEffect(() => {
  //   console.log('test: ', durationRange[0].format(), durationRange[1].format())
  // }, [durationRange])

  const onDateChange = (dates: RangeValue<moment.Moment>, dateStrings: [string, string]) => {
    if (dates != null && dates.length === 2) {
      setDurationRange([moment(dates[0]), moment(dates[1])])
    }
    console.log('From: ', dateStrings[0], ', to: ', dateStrings[1])
  }

  const primaryAction = (
    <>
      <DatePicker.RangePicker
        defaultValue={[moment().startOf('day'), moment().endOf('day')]}
        ranges={{
          Today: [moment(), moment()],
          'Last 7 days': [moment().subtract(7, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment()],
          'Last 30 days': [moment().subtract(30, 'days'), moment()],
          'Last Month': [
            moment().subtract(1, 'months').startOf('month'),
            moment().subtract(1, 'months').endOf('month')
          ],
          'Last 6 Months': [moment().subtract(6, 'months').startOf('month'), moment()],
          'Last 365 days': [moment().subtract(365, 'days'), moment()]
        }}
        onChange={onDateChange}
      />
      <span style={{ marginRight: 12, marginLeft: 12 }}>Symbol: </span>
      <Select value={symbol} onChange={(value) => setSymbol(value)}>
        {currencies.map((item) => {
          return (
            <Select.Option value={item.code} key={item.code}>
              {item.code}
            </Select.Option>
          )
        })}
      </Select>
    </>
  )

  return (
    <Card extra={primaryAction} title={'Statistics'}>
      <StatisticsCard
        symbol={symbol}
        fromDate={durationRange[0].format('YYYY-MM-DD')}
        toDate={durationRange[1].format('YYYY-MM-DD')}
      />
    </Card>
  )
}

const Statistics = () => {
  const { data: response, error, mutate } = useSWR(serialize(CurrencyService.getAll, {}))
  const rows = CurrencyService.toRow(response)
  return <StatisticsPage currencies={rows} />
}
export default Statistics
