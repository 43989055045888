import { Key, FC, useEffect, useState } from 'react'
import { Table, Button, Empty, Row, Col, Dropdown, Menu, Tag } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { Link, useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../lib/store/verification_table'
import TableFilter from '../../components/TableFilter'
import { serialize } from '../../network/request'
import VerificationService, { IVerification } from '../../network/services/verification'
// import CSVButton from '../../components/CSVButton'
import { DateTime } from 'luxon'
import { MenuInfo } from 'rc-menu/lib/interface'

const VerificationTable = () => {
  const navigate = useNavigate()
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState<Record<string, any>>()

  const { data: response } = useSWR(
    serialize(VerificationService.getAll, {
      page: state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters
    })
  )

  const rows = VerificationService.toRow(response)

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true
    },
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      key: 'full_name',
      sorter: true
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      sorter: true
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      sorter: true
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (text: string) => {
        return text ? DateTime.fromISO(text).toFormat('dd/MM/yyyy') : ''
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right' as 'right',
      render: (text: string, record: IVerification) => {
        return <Link to={`${record.id}`}>View</Link>
      }
    }
  ]

  const filterColumns = [{ key: 'email', name: 'Email' }]

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const filterStatus = {
    label: 'Status',
    key: 'status',
    value: [
      { key: '', name: 'All Status' },
      { key: 'pending', name: 'Pending' },
      { key: 'verified', name: 'Verified' },
      { key: 'rejected', name: 'Rejected' }
    ],
    default: ''
  }

  const { total } = VerificationService.toPaginate(response)

  return (
    <>
      {/* <DeleteModal /> */}

      <Row>
        <Col span={24}>
          <Row justify="end">
            <TableFilter
              dropdowns={[filterStatus]}
              columns={filterColumns}
              hasDate={true}
              initial={state.filters}
              setCurrentFilters={setCurrentFilters}
            />

            {/* <CSVButton
              path={serialize(VerificationService.getAll, {
                page: 1,
                limit: total,
                sort: state.sort ?? 'created_at:desc',
                ...state.filters
              })}
              filename="assets.csv"
              toRow={VerificationService.toRow}
              toCsv={(rows) => {
                return rows.map((row) => {
                  return {
                    name: row.profile?.name,
                    email: row.email,
                    plan: row.subscription?.plan?.name,
                    children: row.children[0]?.name,
                    birthdate:
                      row.children[0]?.birthdate &&
                      DateTime.fromISO(row.children[0].birthdate).toFormat('dd/MM/yyyy'),
                    registered:
                      row.created_at && DateTime.fromISO(row.created_at).toFormat('dd/MM/yyyy')
                  }
                })
              }}
            /> */}
          </Row>
        </Col>
      </Row>

      <Table<IVerification>
        scroll={{ x: true }}
        size="small"
        columns={columns}
        dataSource={rows}
        expandable={{
          rowExpandable: (record) => false
        }}
        onChange={(pagination, filters, sorter) => {
          if (_.isArray(sorter)) {
          } else {
            tableStore.state.sort =
              sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null
          }

          tableStore.state.currentPage = pagination.current!
          tableStore.state.pageSize = pagination.pageSize!
        }}
        pagination={{
          total: total,
          pageSize: tableStore.state.pageSize,
          current: tableStore.state.currentPage
        }}
      />
    </>
  )
}

export default VerificationTable
