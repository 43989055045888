import React from 'react'
import { Card, PageHeader, Empty, Spin } from 'antd'
import StakeTransactionTable from './StakeTransactionTable'
import { useNavigate, useParams } from 'react-router-dom'

const StakeTransactionPage = () => {
  const navigate = useNavigate()

  return (
    <PageHeader title={'All Transations'} onBack={() => navigate('/dashboard/stakes')}>
      <Card>
        <StakeTransactionTable />
      </Card>
    </PageHeader>
  )
}

export default StakeTransactionPage
