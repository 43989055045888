import { DateTime } from 'luxon'
import client, { IPaginatedResponse } from '../request'

export interface IUser {
  id: string | number
  email: string
  username?: string
  full_name?: string
  country_code?: string
  phone: string
  referral_code?: string
  provider?: string
  confirmed: boolean
  blocked: boolean
  verified: boolean //KYC verified
  phone_verified: boolean
  last_login_at: DateTime
  created_at: DateTime
  updated_at: DateTime
  admin?: IAdmin
}

interface IAdmin {
  id: string | number
  user_id: string | number
  type: number
  created_at: DateTime
  updated_at: DateTime
}

export interface IAdminChangePassword {
  password: string
  user: string
}

const getAll = `/users`

const get = (id: string | number) => {
  return `/users/${id}`
}

const create = (data: IUser) => {
  return client.post('/users', data)
}

const update = (id: string | number, data: IUser) => {
  return client.put(`/users/${id}`, data)
}

// admin change password
const changePassword = (data: IAdminChangePassword) => {
  return client.post(`/users/${data.user}/change`, { password: data.password })
}

const toRow = (response: IPaginatedResponse<IUser[]>) => {
  if (response?.data?.length > 0) {
    return response.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (response: IPaginatedResponse<IUser[]>) => {
  return {
    total: response?.meta?.total ?? 0
  }
}

const UserService = {
  getAll,
  get,
  create,
  update,
  changePassword,
  toRow,
  toPaginate
}

export default UserService
