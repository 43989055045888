import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Card,
  Col,
  Form,
  Button,
  Input,
  Row,
  message,
  FormInstance,
  Select,
  DatePicker
} from 'antd'

import Gaps from '../../components/Gaps'
import AnnouncementService, { IAnnouncement } from '../../network/services/announcement'
import moment from 'moment'

const AnnouncementForm: FC<{
  form: FormInstance<IAnnouncement>
  initialValue?: IAnnouncement
  isLoading: boolean
  setIsLoading: (val: boolean) => void
  refresh: () => void
  setIsFieldsChange: (val: boolean) => void
}> = ({ form, initialValue, isLoading = false, setIsLoading, refresh, setIsFieldsChange }) => {
  const navigate = useNavigate()
  const { id } = useParams()

  const handleSubmit = async (values: IAnnouncement) => {
    console.log(values)
    if (isLoading) return
    setIsLoading(true)

    let result
    if (initialValue?.id) {
      try {
        result = await AnnouncementService.update(initialValue.id!, values)
        message.success('Update successful')
      } catch (error) {
        console.log(error)
        if (typeof error === 'object') {
          const errorMessage = JSON.parse(JSON.stringify(error))
          message.error(errorMessage?.message)
        }
        setIsLoading(false)
      }
    } else {
      try {
        result = await AnnouncementService.create(values)
        message.success('Create successful')
      } catch (error) {
        console.log(error)
        if (typeof error === 'object') {
          const errorMessage = JSON.parse(JSON.stringify(error))
          message.error(errorMessage?.message)
        }
        setIsLoading(false)
      }
    }

    console.log(result?.data?.success, result?.data?.data)
    if (result?.data?.success && result?.data?.data) {
      refresh()
      setIsLoading(false)
      navigate(`/dashboard/announcements/${result?.data?.data?.id ?? 0}`)
    }
  }

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
          <Form
            form={form!}
            name="new-announcement"
            onFinish={handleSubmit}
            layout="vertical"
            {...(id !== 'new' && {
              initialValues: { ...initialValue, published_at: moment(initialValue?.published_at) }
            })}
            onFieldsChange={() => {
              setIsFieldsChange(true)
            }}
          >
            <Card bordered={false}>
              <Form.Item name="title" label="Title">
                <Input />
              </Form.Item>

              <Form.Item name="content" label="Content">
                <Input.TextArea rows={12} />
              </Form.Item>

              <Form.Item name="published" label="Published" rules={[{ required: true }]}>
                <Select
                  options={[
                    { label: 'True', value: true },
                    { label: 'False', value: false }
                  ]}
                />
              </Form.Item>

              <Form.Item name="published_at" label="Publish Date" rules={[{ required: true }]}>
                <DatePicker />
              </Form.Item>
            </Card>
          </Form>

          <Gaps />
        </Col>
      </Row>
    </>
  )
}

export default AnnouncementForm
