import React, { FC, ReactNode } from 'react'
import { Layout, Row, Space, Typography, Menu } from 'antd'
import { useSnapshot } from 'valtio'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  BgColorsOutlined
} from '@ant-design/icons'
import { Link, Outlet } from 'react-router-dom'

import AuthService from '../network/services/auth'

import { options, finances, nfts } from '../lib/sidebar'
import globalStore, { setActiveTabGlobal, setOpenKeys } from '../lib/store/global'
import { logout } from '../lib/store/auth'

const { Header, Footer, Sider, Content } = Layout

const AuthenticatedLayout: FC<{ children?: ReactNode }> = ({ children }) => {
  const { collapsed, userTab, openKeys } = useSnapshot(globalStore)

  const toggle = () => {
    globalStore.collapsed = !collapsed
  }

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <Space
          className="sider__space"
          direction="vertical"
          style={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}
        >
          <div style={{ minHeight: 60 }} className="flex-center">
            <Link to="/dashboard">
              {collapsed ? (
                <Typography.Title level={4} style={{ margin: 0 }}>
                  PBC
                </Typography.Title>
              ) : (
                <Typography.Title level={4} style={{ margin: 0 }}>
                  PBC
                </Typography.Title>
              )}
            </Link>
          </div>

          <Menu
            mode="inline"
            // openKeys={isCollapsed ? [] : openKeys}
            selectedKeys={[userTab]}
            openKeys={Array.from(openKeys)}
            onClick={(e) => {
              setActiveTabGlobal(e.key)
            }}
            onOpenChange={(openKeys) => {
              setOpenKeys(openKeys)
            }}
          >
            {options.map((option) => (
              <Menu.Item key={option.key}>
                <Link to={`${option.key}`}>
                  {option.icon}
                  <span>{option.label}</span>
                </Link>
              </Menu.Item>
            ))}
            {/* <Menu.SubMenu key="finance" title="Finance" icon={<BookOutlined />}>
              {finances.map((option) => (
                <Menu.Item key={option.key}>
                  <Link to={`${option.key}`}>
                    {option.icon}
                    <span>{option.label}</span>
                  </Link>
                </Menu.Item>
              ))}
            </Menu.SubMenu> */}
            <Menu.SubMenu key="nfts" title="NFTs" icon={<BgColorsOutlined />}>
              {nfts.map((option) => (
                <Menu.Item key={option.key}>
                  <Link to={`${option.key}`}>
                    {option.icon}
                    <span>{option.label}</span>
                  </Link>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          </Menu>
        </Space>
      </Sider>
      <Layout>
        <Header style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          {collapsed ? (
            <MenuUnfoldOutlined onClick={toggle} />
          ) : (
            <MenuFoldOutlined onClick={toggle} />
          )}

          <LogoutOutlined
            onClick={async () => {
              const { data } = await AuthService.logout()
              if (data.success) {
                logout()
              }
            }}
          />
        </Header>
        <Content>
          <Outlet />
        </Content>
        <Footer>
          <Row>
            <Typography.Text style={{ margin: 'auto' }}>
              copyright @ {new Date().getFullYear()}
            </Typography.Text>
          </Row>
        </Footer>
      </Layout>
    </Layout>
  )
}

export default AuthenticatedLayout
