import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Empty, Spin, PageHeader, Button } from 'antd'
import useSWR from 'swr'

import { serialize } from '../../network/request'
import AnnouncementService from '../../network/services/announcement'
import AnnouncementForm from './AnnouncementForm'

const AnnouncementDetail = () => {
  const navigate = useNavigate()
  const { user_id, id } = useParams()

  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [fieldsChange, setIsFieldsChange] = useState(false)

  const saveAction = (
    <Button
      key={'save'}
      type="primary"
      onClick={() => {
        form.submit()
      }}
      loading={isLoading}
    >
      {id === 'new' ? 'Create' : 'Update'}
    </Button>
  )

  const discardAction = (
    <Button
      key={'discard'}
      type="ghost"
      onClick={() => {
        form.resetFields()
        setIsFieldsChange(false)
      }}
    >
      Discard Changes
    </Button>
  )

  // use this to preload
  const {
    data: response,
    error,
    mutate
  } = useSWR(id !== 'new' ? serialize(AnnouncementService.get(id!), { user_id: user_id }) : null)

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response && id !== 'new') {
    return (
      <PageHeader>
        <Spin />
      </PageHeader>
    )
  }

  const record = response?.data

  const refresh = () => {
    if (id != null) {
      if (id === 'new') {
      } else {
        mutate()
      }
    }
  }

  return (
    <PageHeader
      title={id === 'new' ? 'New Announcement' : 'View Announcement'}
      extra={[fieldsChange ? discardAction : null, saveAction]}
      onBack={() => navigate(`/dashboard/announcements`)}
    >
      <AnnouncementForm
        form={form}
        initialValue={record}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        refresh={refresh}
        setIsFieldsChange={setIsFieldsChange}
      />
    </PageHeader>
  )
}

export default AnnouncementDetail
