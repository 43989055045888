import { DateTime } from 'luxon'
import client, { IPaginatedResponse } from '../request'
import { IUser } from './user'
import { IStake } from './stake'

export interface IWithdraw {
  id: string | number
  token_type: string
  status: string
  sender_addr: string
  recipient_addr: string
  contract_address: string
  ref_no: string
  network_fee: number
  user_id: string | number
  transaction_id: string | number
  reason: string
  audited_at: DateTime
  confirmed_at: DateTime
  updated_at: DateTime
  expired_at: DateTime
  created_at: DateTime
  user?: IUser
  stake?: IStake
}

export interface IWithdrawOptional extends Partial<IWithdraw> {}

const getAll = `/withdraws`

const get = (id: string | number) => {
  return `/withdraws/${id}`
}

const create = (data: IWithdraw) => {
  return client.post('/withdraws', data)
}

const update = (id: string | number, data: IWithdrawOptional) => {
  return client.put(`/withdraws/${id}`, data)
}

const remove = (id: string | number) => {
  return client.delete(`/withdraws/${id}`)
}

const toRow = (response: IPaginatedResponse<IWithdraw[]>) => {
  if (response?.data?.length > 0) {
    return response.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (response: IPaginatedResponse<IWithdraw[]>) => {
  return {
    total: response?.meta?.total ?? 0
  }
}

const approve = (id: string | number, data: any) => {
  return client.post(`/withdraws/${id}/approve`, data)
}

const reject = (id: string | number, data: any) => {
  return client.post(`/withdraws/${id}/reject`, data)
}

const WithdrawService = {
  getAll,
  get,
  create,
  update,
  remove,
  toRow,
  toPaginate,
  approve,
  reject
}

export default WithdrawService
