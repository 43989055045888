import { Key, FC, useEffect, useState } from 'react'
import { Table, Button, Empty, Row, Col, Dropdown, Menu, Image, Modal, message } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../lib/store/nft_table'
import TableFilter from '../../components/TableFilter'
import { serialize } from '../../network/request'
import NftService, { INft } from '../../network/services/nft'
// import CSVButton from '../../components/CSVButton'
import { DateTime } from 'luxon'
import { MenuInfo } from 'rc-menu/lib/interface'

const NftTable = () => {
  const navigate = useNavigate()
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState<Record<string, any>>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [checklist, setChecklist] = useState<Record<string, any>[]>([])
  const [visible, setVisible] = useState(false)

  const {
    data: response,
    error,
    mutate
  } = useSWR(
    serialize(NftService.getAll, {
      page: state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters
    })
  )
  const rows = NftService.toRow(response)
  console.log(rows)
  const columns = [
    {
      title: 'Image',
      dataIndex: 'image_url',
      key: 'image_url',
      sorter: true,
      render: (imageUrl: string) => {
        if (imageUrl) {
          return (
            <Image
              src={imageUrl}
              preview={false}
              width={100}
              onClick={(e) => e.stopPropagation()}
            />
          )
        }
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: true
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: true
    },
    // {
    //   title: 'Contact Address',
    //   dataIndex: 'contact_address',
    //   key: 'contact_address',
    //   sorter: true
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true
    },
    {
      title: 'Owner',
      dataIndex: 'user_id',
      key: 'user_id',
      sorter: true,
      render: (value: any, row: INft) => {
        return `${row?.user?.email}`
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (value: undefined, record: INft) => {
        // const action = record.blocked ? 'Unblocked' : 'Blocked'

        return (
          <Link
            to={`${record.id}`}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            View
          </Link>
          // <Button
          //   type="link"
          //   onClick={async (e) => {
          //     e.stopPropagation()
          //     try {
          //       await NftService.update(record.id!, {
          //         blocked: !record.blocked
          //       })

          //       message.success(`${action} ${record.name} | ${record.type} successfully`)
          //       mutate()
          //     } catch (error) {
          //       console.log(error)
          //       message.error(`Failed to ${action}`)
          //     }
          //   }}
          // >
          //   {action}
          // </Button>
        )
      }
    }
  ]

  const filterColumns = [{ key: 'email', name: 'Email' }]

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const { total } = NftService.toPaginate(response)

  return (
    <>
      <Row>
        {/* <Col span={6}></Col> */}
        <Col span={24}>
          <Row justify="end">
            <TableFilter
              // dropdowns={[]}
              columns={filterColumns}
              hasDate={true}
              initial={state.filters}
              setCurrentFilters={setCurrentFilters}
            />

            {/* <CSVButton
              path={serialize(NftService.getAll, {
                page: 1,
                limit: total,
                sort: state.sort ?? 'created_at:desc',
                ...state.filters
              })}
              filename="assets.csv"
              toRow={NftService.toRow}
              toCsv={(rows) => {
                return rows.map((row) => {
                  return {
                    name: row.profile?.name,
                    email: row.email,
                    plan: row.subscription?.plan?.name,
                    children: row.children[0]?.name,
                    birthdate:
                      row.children[0]?.birthdate &&
                      DateTime.fromISO(row.children[0].birthdate).toFormat('dd/MM/yyyy'),
                    registered:
                      row.created_at && DateTime.fromISO(row.created_at).toFormat('dd/MM/yyyy')
                  }
                })
              }}
            /> */}
          </Row>
        </Col>
      </Row>

      <Table<INft>
        scroll={{ x: true }}
        size="small"
        columns={columns}
        dataSource={rows}
        expandable={{
          rowExpandable: (record) => false
        }}
        onChange={(pagination, filters, sorter) => {
          console.log(pagination, filters, sorter)

          if (_.isArray(sorter)) {
            // TODO
          } else {
            tableStore.state.sort =
              sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null
          }
          tableStore.state.currentPage = pagination.current!
          tableStore.state.pageSize = pagination.pageSize!
        }}
        pagination={{
          total: total,
          pageSize: tableStore.state.pageSize,
          current: tableStore.state.currentPage
        }}
      />
    </>
  )
}

export default NftTable
