import { DateTime } from 'luxon'
import client, { IPaginatedResponse } from '../request'

export interface IAnnouncement {
  id: string | number
  type: number
  token_type: string
  decimal: number
  blocked: boolean
  user_id: string | number
  created_at: DateTime
  updated_at: DateTime
  published_at: DateTime
}

const getAll = `/announcements`

const get = (id: string | number) => {
  return `/announcements/${id}`
}

const create = (data: IAnnouncement) => {
  return client.post('/announcements', data)
}

const update = (id: string | number, data: IAnnouncement) => {
  return client.put(`/announcements/${id}`, data)
}

const toRow = (response: IPaginatedResponse<IAnnouncement[]>) => {
  if (response?.data?.length > 0) {
    return response.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (response: IPaginatedResponse<IAnnouncement[]>) => {
  return {
    total: response?.meta?.total ?? 0
  }
}

const AnnouncementService = {
  getAll,
  get,
  create,
  update,
  toRow,
  toPaginate
}

export default AnnouncementService
