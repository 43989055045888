import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, PageHeader, Button, Empty, Spin } from 'antd'
import UserTable from './UserTable'

const UserPage = () => {
  const navigate = useNavigate()
  const primaryAction = (
    <Button
      key="add"
      type="primary"
      onClick={() => {
        navigate('new')
      }}
    >
      Create User
    </Button>
  )

  return (
    <PageHeader title="All Users" extra={[]}>
      <Card>
        <UserTable />
      </Card>
    </PageHeader>
  )
}

export default UserPage
