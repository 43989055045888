import { Key, FC, useEffect, useState } from 'react'
import { Table, Button, Empty, Row, Col, Dropdown, Menu, Image, Modal, message } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../lib/store/nft_table'
import TableFilter from '../../components/TableFilter'
import { serialize } from '../../network/request'
import WithdrawService, { IWithdraw } from '../../network/services/withdraw'
import { DateTime } from 'luxon'
// import CSVButton from '../../components/CSVButton'

const WithdrawTable = () => {
  const navigate = useNavigate()
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState<Record<string, any>>()

  const {
    data: response,
    error,
    mutate
  } = useSWR(
    serialize(WithdrawService.getAll, {
      page: state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters
    })
  )
  const rows = WithdrawService.toRow(response)

  const columns = [
    {
      title: 'Request ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true
    },
    {
      title: 'Requestor',
      dataIndex: 'user',
      key: 'email',
      sorter: false,
      render: (user: any) => {
        return user?.email ?? '-'
      }
    },
    {
      title: 'Amount Requested',
      dataIndex: 'amount_requested',
      key: 'amount_requested',
      sorter: true,
      render: (text: any, record: IWithdraw) => {
        return `${text} ${record?.token_type}`
      }
    },
    {
      title: 'Withdrawal Fee',
      dataIndex: 'network_fee',
      key: 'network_fee',
      sorter: true,
      render: (text: any, record: IWithdraw) => {
        return `${text} ${record?.token_type}`
      }
    },
    {
      title: 'Wallet Address',
      dataIndex: 'recipient_addr',
      key: 'recipient_addr',
      sorter: true,
      render: (text: string) => {
        return text ?? '-'
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true
    },
    {
      title: 'Requested At',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (text: string) => {
        return text ? DateTime.fromISO(text).toFormat('dd/MM/yyyy HH:mm') : '-'
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (value: undefined, record: IWithdraw) => {
        // const action = record.blocked ? 'Unblocked' : 'Blocked'

        return (
          <Link
            to={`${record.id}`}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            View
          </Link>
          // <Button
          //   type="link"
          //   onClick={async (e) => {
          //     e.stopPropagation()
          //     try {
          //       await NftService.update(record.id!, {
          //         blocked: !record.blocked
          //       })

          //       message.success(`${action} ${record.name} | ${record.type} successfully`)
          //       mutate()
          //     } catch (error) {
          //       console.log(error)
          //       message.error(`Failed to ${action}`)
          //     }
          //   }}
          // >
          //   {action}
          // </Button>
        )
      }
    }
  ]

  const filterColumns = [{ key: 'email', name: 'Email' }]

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  //active,matured,terminated
  const filterStatus = {
    label: 'Status',
    key: 'status',
    value: [
      { key: '', name: 'All Status' },
      { key: 'pending', name: 'Pending' },
      { key: 'completed', name: 'Completed' },
      { key: 'rejected', name: 'Rejected' }
    ],
    default: ''
  }

  return (
    <>
      <Row>
        {/* <Col span={6}></Col> */}
        <Col span={24}>
          <Row justify="end">
            <TableFilter
              dropdowns={[filterStatus]}
              columns={filterColumns}
              hasDate={true}
              initial={state.filters}
              setCurrentFilters={setCurrentFilters}
            />

            {/* <CSVButton
              path={serialize(NftService.getAll, {
                page: 1,
                limit: total,
                sort: state.sort ?? 'created_at:desc',
                ...state.filters
              })}
              filename="assets.csv"
              toRow={NftService.toRow}
              toCsv={(rows) => {
                return rows.map((row) => {
                  return {
                    name: row.profile?.name,
                    email: row.email,
                    plan: row.subscription?.plan?.name,
                    children: row.children[0]?.name,
                    birthdate:
                      row.children[0]?.birthdate &&
                      DateTime.fromISO(row.children[0].birthdate).toFormat('dd/MM/yyyy'),
                    registered:
                      row.created_at && DateTime.fromISO(row.created_at).toFormat('dd/MM/yyyy')
                  }
                })
              }}
            /> */}
          </Row>
        </Col>
      </Row>

      <Table<IWithdraw>
        scroll={{ x: true }}
        size="small"
        columns={columns}
        dataSource={rows}
        expandable={{
          rowExpandable: (record) => false
        }}
        onChange={(pagination, filters, sorter) => {
          console.log(pagination, filters, sorter)

          if (_.isArray(sorter)) {
            // TODO
          } else {
            tableStore.state.sort =
              sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null
          }
          tableStore.state.currentPage = pagination.current!
          tableStore.state.pageSize = pagination.pageSize!
        }}
      />
    </>
  )
}

export default WithdrawTable
