import { Card, PageHeader, Empty, Spin } from 'antd'
import VerificationTable from './VerificationTable'

const VerificationPage = () => {
  return (
    <PageHeader title="All Verifications">
      <Card>
        <VerificationTable />
      </Card>
    </PageHeader>
  )
}

export default VerificationPage
