import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import qs from 'qs'

export interface IPaginatedResponse<T> {
  data: T
  meta: {
    total: number
  }
}

export interface IPostResponse<T> {
  code: string
  message: string
  success: boolean
  data: T
}

const requestHandler = (request: AxiosRequestConfig) => {
  const token = localStorage.getItem('token') || undefined

  if (token != null && typeof token !== 'undefined') {
    if (request.headers) {
      request.headers.Authorization = `Bearer ${token}`
    }
  }

  return request
}

const errorHandler = (error: AxiosError) => {
  console.log(error?.response)

  if (error?.response?.status === 401) {
    // unauthorized
    localStorage.clear()
    window.location.reload()
  }

  if (error?.response?.status === 422) {
    // unprocessable entity
    console.log(error.response)

    return Promise.reject({
      message:
        error?.response?.data?.errors?.map((e: any) => `${e.field} - ${e.message}`).join('\n') ??
        'Please contact customer support'
    })
  }

  // Handle errors
  return Promise.reject({
    message:
      error.response?.data?.errorCode ??
      error.response?.data?.message ??
      error.response?.data?.error ??
      error.response?.data?.code ??
      'Please try again later'
  })
}

const successHandler = (response: AxiosResponse) => {
  return response
}

export const fetcher = async (...args: any) => {
  const url = args[0]

  return client.get(url).then((res) => res.data)
}

export const serialize = (url: string, params?: {} | []) => {
  if (typeof params === 'object' || Array.isArray(params)) {
    const matches = url.match(/^(.+?)(\?(.*))?$/)
    const urlWithoutQueryString = (matches && matches[1]) || url
    const queryStringDataFromUrl = matches && matches[3] ? qs.parse(matches[3]) : {}
    const queryString = qs.stringify(
      { ...queryStringDataFromUrl, ...params },
      { arrayFormat: 'indices' }
    )
    if (queryString) {
      return `${urlWithoutQueryString}?${queryString}`
    }
    return url
  }

  return url
}

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
})
client.interceptors.request.use((request) => requestHandler(request))
client.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
)

export default client
