import client from '../request'

const create = (data: Blob, config: Record<string, any>) => {
  const formData = new FormData()
  formData.append('file', data)
  if (config?.type) {
    formData.append('type', config.type)
  }

  return client.post('/upload', formData)
}

const createFile = (data: Blob) => {
  const formData = new FormData()
  formData.append('file', data)
  formData.append('type', 'image')
  return client.post('/upload-file', formData)
}

export const upload = async (file: File) => {
  var formData = new FormData()
  formData.set('file', file)

  try {
    const response = await client.post('/uploads', formData)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const getSignedFile = (filename: string) => {
  return `/files/${filename}`
}

const FileService = {
  create,
  createFile,
  upload,
  getSignedFile
}

export default FileService
