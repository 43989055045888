import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, PageHeader, Button, Empty, Spin } from 'antd'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'

import tableStore from '../../lib/store/nft_table'
import NftService from '../../network/services/nft'
import { serialize } from '../../network/request'
import NftWithdrawTable from './NftWithdrawTable'

const NftWithdrawPage = () => {
  const navigate = useNavigate()
  const primaryAction = (
    <Button
      key="add"
      type="primary"
      onClick={() => {
        navigate('new')
      }}
    >
      Create Nft
    </Button>
  )

  return (
    <PageHeader title="All Nft Withdraws" extra={[]}>
      <Card>
        <NftWithdrawTable />
      </Card>
    </PageHeader>
  )
}

export default NftWithdrawPage
