import { Key, FC, useEffect, useState } from 'react'
import { Table, Button, Empty, Row, Col, Dropdown, Menu, Tag } from 'antd'
import type { TableColumnsType } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { Link, useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../lib/store/wallet_table'
import TableFilter from '../../components/TableFilter'
import { serialize } from '../../network/request'
import WalletService, { IWallet, IWalletWalletAddress } from '../../network/services/wallet'
// import CSVButton from '../../components/CSVButton'
import { DateTime } from 'luxon'
import { MenuInfo } from 'rc-menu/lib/interface'

const WalletTable = () => {
  const navigate = useNavigate()
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState<Record<string, any>>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [visible, setVisible] = useState(false)
  const { user_id } = useParams()

  const { data: response } = useSWR(
    serialize(WalletService.getAll, {
      page: state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters,
      id: user_id
    })
  )
  const rows = WalletService.toRow(response)

  const columns = [
    // {
    //   title: 'Type',
    //   dataIndex: 'type',
    //   key: 'type',
    //   sorter: true,
    //   render: (type: number) => {
    //     if (type === 0) {
    //       return <Tag color="lime">Regular</Tag>
    //     } else if (type === 1) {
    //       return <Tag color="blue">Cold</Tag>
    //     } else if (type === 2) {
    //       return <Tag color="gold">Hot</Tag>
    //     }
    //   }
    // },
    {
      title: 'Token Symbol',
      dataIndex: 'token_type',
      key: 'token_type',
      sorter: true
    },
    {
      title: 'Wallet Balance',
      dataIndex: 'balance',
      key: 'balance',
      sorter: true
    },
    // {
    //   title: 'Decimal',
    //   dataIndex: 'decimal',
    //   key: 'decimal',
    //   sorter: true
    // },
    // {
    //   title: 'Created At',
    //   dataIndex: 'created_at',
    //   key: 'created_at',
    //   sorter: true,
    //   render: (text: string) => {
    //     return text ? DateTime.fromISO(text).toFormat('dd/MM/yyyy') : ''
    //   }
    // },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (value: undefined, record: IWallet) => {
        return (
          <Link
            to={`/dashboard/transactions?wallet_id=${record.id}`}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            Transactions
          </Link>
        )
      }
    }
  ]

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const handleMenuClick = (e: MenuInfo) => {
    if (e.key === 'delete') {
      setVisible(true)
    }
  }

  const expandedRowRender = (record: IWallet, index: number, indent: number, expanded: boolean) => {
    const columns: TableColumnsType<IWalletWalletAddress> = [
      { title: 'Network', dataIndex: 'network_type', key: 'child_network_type' },
      { title: 'Wallet Address', dataIndex: 'address', key: 'child_address' }
    ]
    // console.log(record, index, indent, expanded)
    // const data = rows.map((item) => item.wallet_addresses)
    return <Table columns={columns} dataSource={rows[index]?.wallet_addresses} pagination={false} />
  }

  return (
    <>
      {/* <DeleteModal /> */}

      <Row>
        <Col span={6}>
          {selectedRowKeys?.length > 0 ? (
            <Dropdown
              overlay={
                <Menu onClick={handleMenuClick}>
                  <Menu.Item key="delete" disabled={true}>
                    Delete
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Button type="primary">
                More Actions <CaretDownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <></>
          )}
        </Col>
        <Col span={18}>
          <Row justify="end">
            {/* <TableFilter
              // dropdowns={[]}
              columns={filterColumns}
              hasDate={true}
              initial={state.filters}
              setCurrentFilters={setCurrentFilters}
            /> */}

            {/* <CSVButton
              path={serialize(WalletService.getAll, {
                page: 1,
                limit: total,
                sort: state.sort ?? 'created_at:desc',
                ...state.filters
              })}
              filename="assets.csv"
              toRow={WalletService.toRow}
              toCsv={(rows) => {
                return rows.map((row) => {
                  return {
                    name: row.profile?.name,
                    email: row.email,
                    plan: row.subscription?.plan?.name,
                    children: row.children[0]?.name,
                    birthdate:
                      row.children[0]?.birthdate &&
                      DateTime.fromISO(row.children[0].birthdate).toFormat('dd/MM/yyyy'),
                    registered:
                      row.created_at && DateTime.fromISO(row.created_at).toFormat('dd/MM/yyyy')
                  }
                })
              }}
            /> */}
          </Row>
        </Col>
      </Row>

      <Table<IWallet>
        scroll={{ x: true }}
        size="small"
        columns={columns}
        dataSource={rows}
        expandable={{
          expandedRowRender
        }}
        onChange={(pagination, filters, sorter) => {
          console.log(pagination, filters, sorter)

          if (_.isArray(sorter)) {
            // TODO
          } else {
            tableStore.state.sort =
              sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null
          }

          tableStore.state.currentPage = pagination.current!
          tableStore.state.pageSize = pagination.pageSize!
        }}
      />
    </>
  )
}

export default WalletTable
