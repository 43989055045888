import { DateTime } from 'luxon'
import client, { IPaginatedResponse } from '../request'
import { IUser } from './user'
import { IStake } from './stake'

export interface INft {
  id: string | number
  name: string
  description: string
  type: string
  token_type: string
  contact_address: string
  blocked: boolean
  image_url: string
  status: string
  user_id: string | number
  stake_id: string | number
  staked_at: DateTime
  created_at: DateTime
  updated_at: DateTime
  expired_at: DateTime
  user?: IUser
  stake?: IStake
  price: number
}

export interface INftBid {
  id: string | number
  created_at: DateTime
  amount: number
  status: string
  token_type: string
  user_id: string | number
}

export interface INftOptional extends Partial<INft> {}

const getAll = `/nfts`

const get = (id: string | number) => {
  return `/nfts/${id}`
}

const create = (data: INft) => {
  return client.post('/nfts', data)
}

const update = (id: string | number, data: INftOptional) => {
  return client.put(`/nfts/${id}`, data)
}

const remove = (id: string | number) => {
  return client.delete(`/nfts/${id}`)
}

const toRow = (response: IPaginatedResponse<INft[]>) => {
  if (response?.data?.length > 0) {
    return response.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (response: IPaginatedResponse<INft[]>) => {
  return {
    total: response?.meta?.total ?? 0
  }
}

const NftService = {
  getAll,
  get,
  create,
  update,
  remove,
  toRow,
  toPaginate
}

export default NftService
